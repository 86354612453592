export default {
    "en-US": {
        x_upload_status_success: "Successfully",
        x_upload_status_fail: "Failed",
        x_upload_status_uploading: "Uploading",
        x_upload_status_paused: "Paused",
        x_upload_status_waiting: "Waiting",
        x_upload_read_file_error: "File error",
        x_upload_md5_checking: "MD5 checking",
        x_upload_file_merging: "Merging",
        x_upload_file_transcoding: "Transcoding",
        x_common_error: "Error",
        x_upload_choose_file: "Choose file",
        x_upload_file_list: "File list",
        x_upload_file_upload: "File upload",
        x_common_empty: "Empty",
        x_scene: "Scene",
        x_create_new_scene: "New scene",
        x_publish: "publish",
        x_successful_publication: "Published",
        x_publishing_failure: "Failed",
        x_publishing: "Processing",
        x_project_mgn: "Project management",
        x_data_mgn: "Data management",
        x_ai_mgn: "AI management",
        x_ai_model_mgn: "AI models",
        x_asset_mgn: "Asset management",
        x_account_mgn: "Account management",
        x_shared: "Shared",
        x_shared_man: "Shared man",
        x_all_resource: "All resources",
        x_add_new_company: "Add new company",
        x_date_and_size: "Date / size",
        x_expire_date: "Expire date",
        x_visits_number: "Visits Number",
        x_link_expired: "Link expired",
        x_unshare: "Unshare",
        x_unzipping: "Unzipping",
        x_unzipped: "Unzipped",
        x_login_send_again: "Send again",
        x_select_or_input: "Select or input",
        x_hub_hint: "Please submit company logo, company name and shortname to us, we will contact you as soon as possible.",
        x_enter_project: "Project",
        x_enter_dashboard: "Dashboard",
        x_enter_Project_name: "Enter Project Name",
        x_enter_workspace_name: "Enter Workspace Name",
        x_data_loading: "Data loading...",
        x_my_overview: "My Overview",
        x_all_member: "All Members",
        x_overview_project: "Project",
        x_overview_resource_storage: "Resource Storage",
        x_overview_workspace: "Workspace",
        x_overview_user: "User",
        x_overview_resourve: "Resource",
        x_overview_vehicle_management: "Vehicle",
        x_management: "Management",
        x_version: "Version",
        x_upload_new_version: "Upload New Version",
        x_overview_today: "Daily",
        x_overview_weekly: "Weekly",
        x_overview_monthly: "Monthly",
        x_overview_sixty_days: "60 days",
        x_overview_project_top_ten: "Project visit (Top 10)",
        x_overview_workspace_top_ten: "Workspace visit (Top 10)",
        x_overview_total_visit: "Total visit",
        x_viewer: "Viewer",
        x_general: "General",
        x_mode: "Mode",
        x_of: " ",
        x_or: " or ",
        x_and_bing: " and ",
        x_add_to: "Add to ",
        x_and: "and",
        x_remove: "Remove",
        x_hour: "hour",
        // weather
        x_temperature: "Temperature",
        x_humidity: "Humidity",
        x_uvindex: "Uv Index",

        x_project: "Project",
        x_workspace: "Workspace",
        x_assets: "Assets",
        x_sensors: "Sensors",
        x_dms_admin: "DMS Admin",
        x_streaming_video: "Live video",
        x_batch_update: "Batch update",
        x_pms: "Photo management",
        x_accuracy: "accuracy",
        x_confidence: "Confidence",
        x_pms_download: "Download",
        x_pms_compare: "Compare",
        x_pms_create_new: "Create New Album",
        x_layer: "Layers",
        x_baes_layer: "Baes Layer",
        x_terrain: "Terrain",
        x_2d_layer: "2D Layers",
        x_3d_layer: "3D Layers",
        x_3d_viewer: "3D Viewer",
        x_pointcloud_viewer: "Point Cloud Viewer",
        site_title: "GeoTwin Platform",
        x_username: "Username",
        x_username_or_email: "Email Address",
        x_password: "Password",
        x_confirm_password: "Confirm password",
        x_default: "Default",
        x_comfirm_replica: "Are you sure to copy {0} {1}",
        x_captcha_text: "Captcha Text",
        x_login: "Login",
        x_using_google_login: "Using Google login",
        x_forget_pwd: "Forget password?",
        x_comfirm: "Confirm",
        x_username_empty_err: "Username can not be empty",
        x_password_empty_err: "Password can not be empty",
        x_comfirm_password_empty_err: "Comfirm password can not be empty",
        x_password_err: "The two passwords are inconsistent",
        x_user_management: "User Management",
        x_change_password: "Change Password",
        x_current_password: "Current Password",
        x_set_password: "Set Password",
        x_remove_password: "Remove Password",
        x_new_password: "New Password",
        x_new_password_dup: "Confirm New Password",
        x_logout: "Logout",
        x_search: "Search",
        x_reset: "Reset",
        x_site_mission_corsys: "Coordinate System",
        x_default_coord_sys: "Default coordinate system",
        x_coord_sys_placeholder: "Coordinate system name or EPSG code",
        x_coord_sys_default_wgs84: "Default is WGS 84 (4326)",
        x_measurement: "Measurement",
        x_ms_height: "Height",
        x_ms_coordinate: "Coordinate",
        x_ms_volume: "Volume",
        x_ms_area_projected: "Projected Area",
        x_ms_angle: "Angle",

        x_information: "Information",
        x_style: "Style",
        x_style_lowercase: "style",
        x_attribute: "Attribute",
        x_2d_border: "Outline",
        x_2d_cap: "Cap",
        x_2d_dash: "Dash",
        x_2d_width: "Width",
        x_2d_font: "Font",
        x_2d_size: "Size",
        x_3d_heading: "Heading",

        x_3d_tidal_analysis: "Tidal Analysis",
        x_draw_analysis_range: "Draw analysis range",
        x_flood_height: "Flood height",
        x_3d_line_of_sight: "Line of Sight",
        x_3d_visual_analysis: "Viewshed",
        x_3d_site_formation: "Site Formation",
        x_3d_cut_section: "Cut Section",
        x_3d_skyline_analysis: "Skyline",
        x_3d_shadow_analysis: "Shadow Analysis",
        x_3d_skyview: "Skyview",
        x_3d_skyview_graph: "Skyview Graph",
        x_3d_skyview_factor: "Skyview Factor",
        x_range: "Range",
        x_pointcloud_is_3dtiles: "Publish for 3D viewer",
        x_pointcloud_is_potree: "Publish for point cloud viewer",
        x_at_least_one_chose: "At least one of the following should be chosen",

        x_pwd_very_weak: "Very weak",
        x_pwd_weak: "Weak",
        x_pwd_moderate: "Moderate",
        x_pwd_strong: "Strong",
        x_pwd_very_strong: "Very Strong",
        x_pwd_weak_no_submit:
            "Password is too weak. Please provide a stronger password",

        x_3d_fly_through: "Fly Through",
        x_fly_path_add: "Add Fly Through",
        x_back_to_fly_path: "Back to Fly Through Management",

        x_3d_edit_arrow: "3D Arrow",
        x_3d_edit_3d_polyline: "3D Polyline",
        x_3d_edit_3d_box: "Prism",
        x_3d_edit_cylinder: "Cylinder",
        x_3d_edit_rectangle: "Cuboid",
        x_3d_edit_annotation: "Annotation",
        x_3d_edit_redline: "Redline Comment",
        x_3d_edit_file: "FILE",
        x_3d_model: "Models",
        x_3d_edit_pipe: "Pipeline",
        x_3d_edit_site: "Flatten Site",
        x_edit_upload_file: "File Upload",
        x_3d_edit_rectangle_2d: "Rectangle",
        x_3d_edit_ellipse_2d: "Ellipse",
        x_close: "Close",
        x_signup: "Sign up",
        x_signup_name: "Name",
        x_signup_email: "Email",

        x_create: "Create",
        x_submit: "Submit",
        x_empty_err: "This field can not be empty",
        x_illegal_err: "This field can not input illegal character",
        x_edit: "Edit",
        x_edit_api: "Edit API",
        x_delete: "Delete",
        x_share: "Share",
        x_share_success: "Share success",
        x_please_create_a_sharing_link_first: "Please create a sharing link first",
        x_member_sharing: "Member sharing",
        x_please_select_a_member: "Please select a member",
        x_role: "Role",
        x_teams: "Teams",
        x_register_success: "Sign up successfully, Please login!",
        x_email_invlidate: "Incorrect email format",
        x_mobile_invlidate: "Incorrect mobile phone number format",
        x_internal_server_err: "Server Error!",
        x_annotations: "Annotations",
        x_save: "Save",
        x_nav_2D_view: "2D View",
        x_nav_position: "Position",
        x_nav_fly_to: "Fly to",
        x_nav_lock_position: "Lock Position",
        x_nav_zoom_to_bound: "Zoom to Bound",
        x_nav_view_mode: "Viewing Mode",
        x_nav_default: "Mouse Control",
        x_nav_first_person: "Keyboard Control",
        x_site_management: "Project Management",
        x_nav_mission: "Mission",
        x_sample: "Sample",
        x_sample_trajectory: "Click to download a sample trajectory file",

        x_saving: "Saving...",
        x_processing: "Processing...",
        x_ok: "OK",
        x_cancel: "Cancel",
        x_success: "Success",
        x_site_title: "Title",
        x_site_guest_accessible: "Guest Accessible",
        x_site_missions: "Missions",
        x_add_mission: "Add mission",
        x_split_view: "Reference View",
        x_split_mode: "Split View",
        x_curtain_mode: "Curtain View",

        x_clear_operation: "Clear",
        x_lang: "Language",
        x_basemap: "Base map",
        x_3d_baselayer: "3D Base Layer",
        x_render_quality: "Render quality",
        x_render_effect: "Effects",
        x_render_quality_low: "Low",
        x_render_quality_normal: "Normal",
        x_render_quality_high: "High",
        x_sun_shadow: "Sun and Shadow",
        x_blooming: "Blooming",
        x_depth_of_field: "Depth of Field",
        x_3d_terrain_flattening: "Terrain Flattening",
        x_3d_clip_plane: "Clipping Plane",
        x_3d_clip_box: "Clipping Box",
        // x_3d_clip_plane_horizontal: 'Horizontal Clipping',
        // x_3d_clip_plane_vertical: 'Verticle Clipping',
        x_2d_view: "2D/3D view mode",
        x_true_north: "True North",
        x_shadow_height: "Height Above",
        x_upload_title: "Model Upload",
        x_upload_drag_note: "Click or drag to upload",
        x_upload_note: "Click or drag to upload zipped obj model",
        x_bookmark: "Bookmark",
        x_bookmark_no_bookmark: "No bookmark",

        x_bookmark_name: "Bookmark Name",
        x_data_upload: "Data Upload",
        x_select_upload_folder: "Select Upload Folder",
        x_select_data_folder: "Select Data Folder",
        x_folder_no_type_data:
            "Select folder does not contain selected type of data",
        x_cal_pub_size: "Calculating size required",
        x_pub_size_req: "Size required",
        x_pub_size_total: "Available resource size",
        x_remain_pub_size: "Remaining resource size",
        x_size_resource: "Size",

        x_upload: "Upload",
        x_upload_lng: "Longitude",
        x_upload_lat: "Latitude",
        x_upload_height: "Altitude",
        x_upload_lng_X: "Longitude",
        x_upload_lat_Y: "Latitude",
        x_model_editing: "Model Editing",
        x_move: "Move",
        x_rotate: "Rotate",
        x_resource_search: "Resource Search",

        x_addlayer: "Add New Layer",
        x_delete_layer: "Delete Layer(s)",
        x_navigation: "Navigation",
        x_analytics: "Analytics",
        x_full_screen: "Full Screen",
        x_fly_home: "Fly to home",
        x_set_project_position: "Save Project Initial Position",
        x_screen_cap: "Screen Capture",
        x_screen_rec: "Record Scene",
        x_rec_stop: "Stop",
        x_nodata: "No Data",
        x_none: "None",
        x_normal: "normal",
        x_loading: "Loading...",

        x_pms_title: "Title",
        x_pms_tags: "Tags",
        x_pms_copylink: "Copy resource link",
        x_pms_start_date: "Start date",
        x_pms_end_date: "End date",
        x_pms_define_region: "Define Filter Region",
        x_pms_filter: "Filter",
        x_pms_search_region: "Filter Region",
        x_pms_select_range: "Select range",
        x_pms_selected: "Selected",
        x_pms_selectall: "Select All",
        x_pms_unselectall: "Unselect All",
        x_pms_is_trajectory: "Generate Trajectory",
        x_pms_layers: "Associative layers",
        x_pms_layers_select: "Please select an associated layer",
        x_pms_view_map: "Map View",
        x_pms_view_grid: "Grid View",
        x_pms_view_type: "View Mode",
        // x_pointcloud_is_potree: 'Publish to Pointcloud Viewer',
        x_pano_is_face_blur: "privacy blurring",
        x_eo_format: "EO file format",
        x_spatial_offset: "Spatial Offset",

        x_eo_loading: "Reading image EO",
        x_img_info_table: "Image metadata",
        x_upload_eo_csv: "Upload EO CSV",
        x_download_eo: "Download EO CSV",
        x_publish_resource: "Publish Resource",
        x_eo_ph_name: "Photo Name",
        x_easting: "Easting",
        x_northing: "Northing",
        x_omega: "Omega",
        x_phi: "Phi",
        x_kappa: "Kappa",
        x_eo_img_total_count: "images in the directory",
        x_eo_img_positioned: "images are positioned",
        x_x: "X",
        x_y: "Y",
        x_z: "Z",
        x_r: "R",

        x_nav_enter_google_street: "Enter Google StreetView",
        x_nav_google_transparency: "StreetView Transparency",
        x_nav_back_3d: "Back to 3D View",
        x_nav_streetview_not_found:
            "Street View data not found for this location",

        x_ms_volume_base_type: "Base type",
        x_ms_volume_base_low: "Align with lowest vertex",
        x_ms_volume_base_high: "Align with highest vertex",
        x_ms_volume_base_ave: "Align with average plane",
        x_ms_volume_base_triang: "Triangulation",
        x_ms_volume_base_man: "Manual input",
        x_ms_volume_quick_measure: "Quick measure",

        x_ms_volume_base_level: "Base level (m)",
        x_ms_volume_calculate: "Calculate Volume",
        x_ms_volume_bl: "Base level",
        x_ms_sampling_interval: "Sampling interval",
        x_ms_error_prop: "Error estimation",
        x_ms_volume_cv: "Cut Volume",
        x_ms_volume_fv: "Fill Volume",
        x_ms_volume_tv: "Total Volume",

        x_3d_terrain_excavation: "Terrain Excavation",
        x_flood_level: "Level (m)",
        x_flood_level_placeholder: "Level of flood (m)",
        x_flood_define_flood_region: "Define flood region",
        x_shadow_start_time: "Start Time",
        x_shadow_end_time: "End Time",
        x_shadow_animate: "Animate",
        x_shadow_calculate_ratio: "Calculate sunlight ratio",
        x_sun_exposure: "Sunlight Exposure",

        x_stop_as_current_pos: "Update as current view",
        x_fly_speed: "Fly speed",
        x_duration: "Duration",
        x_fly_save_video: "Save as video after animation",
        x_fly_video_name: "Video name",
        x_fly_add_stop: "Add Stop",
        x_fly_animate: "Animate & Play",

        x_drawing_area: "Area",
        x_drawing_footprint: "Footprint(m2)",
        x_drawing_color: "Color",
        x_action: "Action",

        x_sure_del_sketch: "Are you sure to delete?",
        x_edit_title: "Edit title",
        x_sketch_add: "Add sketch design",
        x_quit_sketch_design: "Quit sketch design",
        x_export_sketch_design: "Export design",
        x_floor_count: "Floor Count",
        x_floor_height: "Floor Height(m)",
        x_gfa_efficiency: "Efficiency",
        x_gfa: "GFA",

        x_project_folder_selection: "Folder Selection",
        x_project_folder_selection1: "Select Files",
        x_project_new_folder: "New Folder",
        x_project_folder_name: "Folder Name",
        x_project_upload_step1: "Step 1: Select Remote Upload Folder",
        x_project_upload_step2: "Step 2: Select Local File",
        x_category: "Category",
        x_project_publish_resource_name: "Resource Name",
        x_project_restricted_status: "Modify HUB permissions",
        x_static_data: "Static Data",
        x_dynamic_data: "Dynamic Data",
        x_refresh_time: "Refresh Time(min)",
        x_capture_date: "Capture Date",
        x_share_resources: "Share Resources",
        x_restrict_access: "Restrict Access",
        x_3d_coordinate_system: "3D Coordinate",
        x_rotation_angle: "Rotation angle",
        x_coordinate3d_input_tips: "The input must be a double number, please re-enter it",
        x_restrict_access_tips1: "Domain access is not restricted",
        x_restrict_access_tips2: "Restrict access to the hub domain",
        x_project_publish_layerurl: "Resource URL",
        x_project_publish_ogc_layername: "Layer to include in the service",
        x_ogc_tile_matrix_set: "Tile matrix set",
        x_enable_supermap_query: "Enable query through SuperMap data service",
        x_supermap_query_url: "Query data service URL",
        x_supermap_datasource: "Datasource",
        x_dataset: "Dataset",
        x_data_value: "Data Value",
        x_url_inaccessible: "Service url not accessible",
        x_url_format_invalid: "Service response not recognized",
        x_upload_4d_script: "Upload timeline script",
        x_read_4d_complete: "Upload timeline script complete",
        x_sm_dataset_default_merge:
            "No need to provide if the service is from merged dataset",
        x_upload_supermap_workspace: "Upload SuperMap workspace file",

        x_project_msg_uploading: "Uploading...",
        x_project_msg_add_layer_success: "Add layer success",
        x_login_msg_login_incorrect: "Username or password are not correct",
        x_index_msg_add_bookmark: "Add bookmark successful.",
        x_index_msg_delete_bookmark: "Delete bookmark success",
        x_index_msg_bookmark_share:
            "Bookmark share URL copied to the clipboard.",

        x_index_msg_zip_file: "Zipping files",
        x_index_msg_zip_photo: "Zipping your photos",
        x_index_msg_to_enter_google:
            "Please pick a point on the ground to enter the Google Street View.",
        x_index_msg_save_init_position: "Saving project initial position",
        x_index_msg_save_init_position_success:
            "Save project initial position success.",
        x_index_msg_generate_video: "Generating video...",
        x_index_msg_model_share: "Model share URL copied to the clipboard.",
        x_index_msg_model_upload: "Model Uploading",
        x_index_msg_model_process: "Processing Model",
        x_index_msg_model_success: "Model loading success",
        x_terrain_transparency: "Terrain transparency",

        x_upload_md5_cal: "Reading file...",
        x_upload_md5_fail: "Read file failed",
        x_upload_sm_no_scene: "No 3D scene in the workspace",

        x_volume_management: "Volume Management",
        x_vm_title: "Stockpile Name",
        x_vm_base_height: "Base Height (m)",
        x_vm_create_new: "Create Volume Measurement",
        x_vm_to_draw_region:
            "Left click to define the site region on 3D scene. Right click to finish",
        x_vm_no_result: "No volume calculation result. Please calculate first.",
        x_vm_stockpile: "Stockpile",
        x_vm_date: "Last Calculate Date",
        x_vm_left_viewer: "Left Viewer",
        x_vm_right_viewer: "Right Viewer",
        x_vm_report_title: "Volume Measurement Report",
        x_vm_usage:
            "Draw the measurement region in the 3D viewer or upload a csv file of base surface",

        x_add_record_service_api_url: "Reading Service API URL",

        x_vm_fly_to: "Fly to",
        x_vm_visible: "Toggle Visibility",
        x_vm_calculate: "Calculate",
        x_vm_report: "Show Report",

        x_vm_site_mode: "Source file type",
        x_vm_region_ring: "Polygon region",
        x_vm_spot_tin: "Spot heights",

        x_pipe_edit_title: "Edit 3D Pipe",
        x_pipe_edit_x: "x(m)",
        x_pipe_edit_y: "y(m)",
        x_pipe_edit_z: "z(m)",
        x_pipe_edit_width: "width(m)",

        x_console: "System Management",
        x_dept_name: "Group Name",
        x_add: "Add ",
        x_update: "Update",
        x_revise: "Revise",
        x_feature: "Feature",

        x_poi: "POIs",
        x_poi_name: "POI Name",
        x_poi_add: "Add POI",

        x_nav_navigate: "Navigate",
        x_nav_view: "View",
        x_nav_streetview: "Street View",
        x_nav_routing: "Routing",
        x_nav_selection: "Selection",
        x_nav_select: "Identify",
        x_nav_locate: "Locate",
        x_nav_search_poi: "POI Search",
        x_nav_search_buffer: "Buffer Query",
        x_nav_search_bound: "Bound Query",
        x_nav_generate_plan: "Generate Plan View",
        x_nav_helper: "Helper",
        x_nav_save_as_profile: "Save as Home",
        x_nav_tool: "Tools",
        x_nav_bim: "BIM",
        x_nav_measure: "Measure",
        x_nav_analysis: "Analysis",
        x_nav_drawings: "Drawings",
        x_layer_panel: "Layer Panel",
        x_bookmark_panel: "Bookmark Panel",
        x_volume_panel: "Volume Panel",
        x_model_panel: "Model Panel",
        x_drawing_panel: "Drawing Panel",
        x_pms_panel: "PMS",
        x_nav_setting: "Setting",
        x_nav_screen: "Screen",
        x_compress_screen: "Compress",
        x_nav_transparency: "Transparency",
        x_timeline_play_4d: "Play",
        x_timeline_pause_4d: "Pause",
        x_timeline_loading: "Loading timeline graph",
        x_timeline_no: "No timeline found for current project layers",
        x_pt_styling: "Point cloud styling",
        x_pt_point_size: "Point size",
        x_pt_class_filter: "Filter by classification",

        x_query_buffer_define_size: "Define Buffer Radius",

        x_media_info: "Media Information",
        x_properties: "Properties",
        x_layer_setting: "Layer Setting",
        x_zoom_to: "Zoom To",
        x_nav_review: "Review",
        x_nav_output: "Output",
        x_nav_effects: "Effects",
        x_nav_animation: "Animation",
        x_add_in_module: "Addon Modules",
        x_nav_2d_drawings: "2D Drawing",
        x_nav_3d_drawings: "3D Drawing",
        x_nav_3d_particle: "Particle Effect",
        x_nav_3d_particle_fire: "Fire",

        x_nav_terrain_analysis: "Terrain Analysis",
        x_nav_line_of_site: "Visual Analysis",
        x_nav_sectional_analysis: "Sectional Analysis",
        x_nav_shadow_analysis: "Shadow",

        x_batch_delete: "Batch Delete",
        x_selected_resource: "selected resource",
        x_signup_repwd: "Re Enter Password",
        x_signup_repwd_error: "ReEnter Password is not same as password",
        x_signup_phone_error: "Phone format error",
        x_status: "Status",
        x_invoke_number: "Invoke Number",
        x_user_disable: "Disabled",
        x_user_normal: "Normal",
        x_create_date: "Create Date",
        x_role_name: "Role Name",
        x_remark: "Remark",
        x_role_access: "Access",
        x_layer_name: "Layer Name",
        x_layer_url: "Layer URL",
        x_layer_type: "Layer Type",
        x_layer_category: "Layer Category",
        x_service_publish: "Service Publish",
        x_data_publish: "Data Publish",
        x_datasource_publish: "Data Source Publish",
        x_publish_datasource_tip: "Excel, Esri shapefile, GeoJSON......",
        x_clone_layer_publish: "Clone Layer",
        x_title: "Title",
        x_name: "Name",
        x_date: "Date",
        x_owner: "Owner",
        x_desc: "Description",
        x_type: "Type",
        x_parent_dept: "Parent",
        x_comfirm_deletion: "Are you sure to delete {0} {1}",
        x_user: "User",
        x_existing_layer: "Existing Layer",

        x_init_gis_publish: "Create GIS Feature Resource",
        x_init_gis_tip: "2/3D Point/Line String/Polygon",
        x_geometry_type: "Geometry Type",
        x_shp_point_2d: "Point",
        x_shp_linestring_2d: "Line String",
        x_shp_polygono_2d: "Polygon",
        x_shp_circle_2d: "Circle",
        x_shp_point_3d: "3D Point",
        x_shp_linestring_3d: "3D Line String",
        x_shp_polygon_3d: "3D Polygon",

        x_init_iot_publish: "Create IoT Device Resource",
        x_init_ai_photogram: "AI Photogrammetry",

        x_image_loading: "Loading image",
        x_viewer_loading: "Loading 3D Viewer",

        x_share_bkmk_copied: "Bookmark share URL copied to the clipboard",
        x_home_success: "Home set successfully",
        x_kml_invalid: "Please upload a valid kml file",
        x_csv_invalid: "Please upload a valid csv file",
        x_no_delete_root_folder: "Root folder cannot be deleted",

        x_viewport: "Viewport",
        x_viewport_category: "Viewport Category",
        x_viewport_mgmt: "Viewport management",
        x_viewport_goto: "Go to viewport",
        x_viewport_detailed_info: "Viewport information",
        x_viewport_info: "Info",
        x_alert: "Alert",
        x_alert_message: "Receive a warning message",
        x_alert_info: "Alert Info",
        x_viewport_scheduled: "Scheduled",
        x_viewport_completed: "Completed",
        x_viewport_category_add: "Add Viewport Category",
        x_viewport_add: "Add Viewport",
        x_viewport_category_name: "Viewport Category Name",
        x_viewport_state: "State",
        x_viewport_play_ctrl: "Play Control",
        x_viewport_comment_pane: "Comments",

        x_generate_share_link: "Generating share link",
        x_back: "Back",
        x_share_link: "Share Link",
        x_viewport_type: "Viewport Type",
        x_viewport_animated: "Record",
        x_viewport_recording:
            "The viewport is being recorded. Press 'Stop' button on the right to finish",
        x_copy_to_clipboard: "Copy to clipboard",

        x_home_management: "Management",
        x_home_query: "Query",
        x_temporary_layer: "Temporary Layer",
        x_upload_obj: "Upload OBJ",
        x_upload_kml: "Upload KML",
        x_upload_geojson: "Upload Geojson",
        x_upload_jpg: "Upload JPG",
        x_upload_gltf: "Upload GLTF",
        x_upload_csv_point: "Upload CSV-Point",
        x_upload_csv_path: "Upload CSV-Path",
        x_upload_bim_osgb: "Upload BIM (osgb)",
        x_upload_batch: "Upload Batch",
        x_measurement_point: "Point",
        x_ms_distance: "Distance",
        x_ms_perimeter: "Perimeter",
        x_free_polyline: "Length",
        x_dvh: "DVH",
        x_vertical_ground: "Height difference",
        x_distance_xyz: "Distance XY&Z",
        x_perp_reference_line: "Perpendicular distance to reference line",
        x_arc_3_digit: "Arcs by 3 digits",
        x_measurement_area: "Area",
        x_free_area: "Free Area",
        x_vert_rect_diagonal: "Vertical Rectangular Area Diagonal",
        x_vert_rect_3pts: "Vertical Rectangular 3pts",
        x_vert_rect_intersect: "Vertical Rectangular with intersection",
        x_horiz_rect: "Horizontal rectangular area",
        x_horiz_sqrt_area_ground: "Horizontal squared area from ground",
        x_free_area_ground: "Projected area",
        x_horiz_circle_ctr_radius: "Horizontal Circle by center and radius",
        x_horiz_circle_3d: "Horizontal Circle by 3d points",
        x_measurement_volume: "Volume",
        x_cut_fill: "Cut & Fill",
        x_export: "Export",
        x_export_shp: "Export as Shapefile",
        x_export_kml: "Export as KML",
        x_export_csv: "Export as CSV",

        x_icon: "Icon",
        x_design_view: "Design&Review",
        x_anno_icon: "ICON",
        x_anno_tag: "TAG",
        x_nav_text: "TEXT",
        x_anno_site_formation: "Formation",
        x_nav_water: "Water",
        x_drawing_circle: "Circle",
        x_drawing_polyline: "PolyLine",
        x_drawing_symbol: "Symbol",
        x_drawing_arrow: "Arrow",
        x_drawing_blank_indicator: "Blank Indicator",
        x_drawing_text_indicator: "Text Indicator",
        x_drawing_text: "Text",
        x_drawing_list: "Draw List",
        x_list: "List",
        x_list_mode: "List Mode",
        x_inventory_mode: "Inventory Mode",
        x_new_draw: "New Draw",
        x_drawings: "Draw",
        x_design_management: "Management",
        x_collaboration: "Collaboration",
        x_new_viewport: "New Viewport",
        // x_new_viewport_group: 'New Viewport Group',
        // x_viewport_group: 'Viewport Group',
        x_new_viewport_group: "New 4D tour",
        x_viewport_group: "4D tour",
        x_new_viewport_category: "New Category",
        x_new_status: "New Status",
        x_tracking: "Tracking",
        x_table_of_content: "Table of content",
        x_select_date: "Select Date",

        x_resource_mgmt: "Resources Management",
        x_security_mgmt: "Security Management",
        x_use_statistics: "Usage Statistics",
        x_user_pref: "User Preferences",
        x_civil_tools: "Civil Tools",
        x_planning: "Planning",
        x_planning_design: "Planning&Design",
        x_sketch_design: "Sketch Design",
        x_design_scheme: "Design Scheme",
        x_rdms_panel: "RDMS",
        x_3dms_panel: "3DMS",
        x_mobile_app: " Mobile",
        x_fms_panel: "FMS",
        x_novade: "Novade",
        x_bim_plus: "3DMS+",
        x_timeline_panel: "Timeline",
        x_timeline_4d: "Play 4D",
        x_bim360: "BIM360",
        x_mms_panel: "MMS",
        x_orbit: "Orbit",

        x_stat_daily_view: "Daily view statistics on",
        x_stat_proj_view_title: "Project Views",
        x_stat_wks_view_real_title: "Workspace Views by Named Users",
        x_stat_wks_view_anon_title: "Workspace Views by Anonymous Users",
        x_stat_views_time: "views",
        x_stat_views_time_real: "views by named user",
        x_stat_views_time_anon: "views by anonymous user",

        x_processing_tasks: "Tasks",
        x_msg_list: "Messages",
        x_menu_my_catalog: "My Catalog",
        x_menu_storage: "Storage",
        x_menu_share: "Share",
        x_menu_resources: "Resources",
        x_menu_workspaces: "Workspaces",
        x_menu_viewports: "Viewports",
        x_menu_company_catalog: "Company Catalog",
        x_menu_users_teams: "Users & Teams",
        x_menu_account: "Account",
        x_menu_my_profile: "My Profile",
        x_menu_my_settings: "My Settings",
        x_menu_my_massage: "My Massage",
        x_menu_company_profile: "Company Profile",
        x_menu_plan_profile: "Plan Profile",
        x_menu_statistics_dashboard: "Statistics Dashboard",
        x_menu_more: "More",
        x_menu_contact: "Contact",
        x_menu_pricing: "Pricing",
        x_menu_help_center: "Help Center",
        x_home: "Home",
        x_product_features: "Product Features",
        x_function_introduction: "Function Introduction",
        x_classic_case: "classic Case",
        x_customer: "Our Customers",
        x_control_console: "Control Console",
        x_free_try: "Try For Free",

        x_features_slogan_1: "Dynamic Optimization and Cache Acceleration for Browser Memory",
        x_features_slogan_2: "BIM & GIS Spatial Large Model Lightweight Algorithm",
        x_features_slogan_3: "Safer and smoother computable vector tile technology",
        x_features_slogan_4: "WebGPU-based parallel AI computation, spatial analysis, and lighting rendering capabilities",
        x_features_introduce_1: "Improved browser performance. Our techniques intelligently manage memory resources and optimize caching mechanisms to provide a smoother and faster web browsing experience. ",
        x_features_introduce_2: "Designed to compress and optimize large-scale building and geospatial data models, enhancing processing efficiency and speed. These algorithms simplify models while preserving critical attributes, facilitating efficient project design and data sharing.",
        x_features_introduce_3: "Calculable vector tile technology offers a more secure and seamless map display method. It is based on vector data, calculating and rendering maps in real-time, delivering improved interactivity and performance. ",
        x_features_introduce_4: "Based on WebGPU, the parallel capabilities of AI computation, spatial analysis, and ray-tracing rendering combine efficient web environments with advanced algorithms, providing powerful computing and rendering capabilities.",
        x_function_nav_1: "Visualization",
        x_function_nav_2: "Annotation",
        x_function_nav_3: "Interaction",
        x_function_nav_4: "Analysis",
        x_function_nav_5: "Effects",
        x_classic_ases_name_1: "Smart Project and Urban Planning or Renewal by Reality Capture and CIM",
        x_classic_ases_name_2: "Smart Site Monitoring Dashboard",
        x_classic_ases_name_3: "Completion of building works checking",
        x_classic_ases_name_4: "Inspection the external condition",
        x_classic_ases_name_5: "Village House UBWs management",
        x_classic_ases_name_6: "Heritage Management and Inspection",
        x_Contact: "Call me",
        x_contact_phone_number: "(852) 3188 9008",
        x_contact_email: "info@geosys.org",
        x_contact_location: "Unit 313, 3/F, Wireless Centre, Phase One Hong Kong Science Park Pak Shek Kok, New Territories, Hong Kong",

        x_address: "Address",
        x_website: "Website",
        x_phone: "Phone",
        x_mobile: "Mobile",
        x_fax: "Fax",
        x_setup: "Setup",
        x_theme_blue: "Blue",
        x_theme_green: "Green",
        x_theme_red: "Red",
        x_theme_purple: "Purple",
        x_theme_dark_pro: "Dark Pro",
        x_theme_dark_blue: "294C",
        x_theme_dark: "Dark",
        x_theme_light: "Light",
        x_theme: "Theme",
        x_my_preferences: "My Preferences",
        x_company_preferences: "Company Preferences",
        x_data_processing: "Data Processing",
        x_refresh_data: "Refresh Data",
        x_refresh_status: "Refresh Status",
        x_change_profile_img: "Change Profile Image",
        x_change_company_logo: "Change Company Logo",
        x_create_new_user: "Create New User",
        x_create_new_team: "Create New Team",
        x_create_new_project: "Create New Project",
        x_create_new_workspace: "Create New Workspace",
        x_create_new_resource: "Create New Resource",
        x_resource_index_map: "Resource Indexing Map",
        x_signup_invite_code: "Invite Code (Optional)",
        x_user_unactivated: "Inactive",
        x_view: "View",
        x_renew: "Renew",
        x_copy: "Copy",
        x_rename: "Rename",
        x_rename_tip: "Please enter English characters or numbers",
        x_moveto: "Move",
        x_duplicate: "Duplicate",
        x_users_in: "Users In",
        x_resources_in: "Resources In",
        x_renew_sharecode: "Renew Sharecode",
        x_opened_modules: "Opened Modules",
        x_in_team: "In Team",
        x_in_project: "In Project",
        x_in_company: "In Company",
        x_in_workspace: "In Workspace",
        x_enter_ws_pwd: "Please enter password for workspace",
        x_file_name: "File Name",
        x_move_to_header: "Move to",
        x_copy_to_header: "Copy to",
        x_unzip_to_header: "Unzip to",
        x_open: "Open",
        x_role_user: "User",
        x_guest: "Guest",
        x_role_dm: "Data Manager",
        x_role_pm: "Project Manager",
        x_role_admin: "Admin",
        x_role_superadmin: "Super Admin",
        x_share_code: "Share Code",
        x_size: "Size",
        x_table_header_progress: "Progress",
        x_table_header_messgae: "Message",
        x_table_header_expire_date: "Expire Date",
        x_table_header_share_with: "Share With",
        x_publish_service_tip:
            "URL Template Imagery Layer",
        x_publish_data_tip:
            "Media;Point Cloud (las);OSGB;3D Tiles......",
        x_please_contact: "Please Contact",
        x_choosed: "Choosed",
        x_private: "Private",
        x_privacy: "Visibility",
        x_public: "Public",
        x_encrypt: "Encrypt",
        x_data_source: "Data Source",
        x_fill_all_fields: "Please fill all fields",
        x_next: "Next",
        x_region: "Region",
        x_product_plan: "Plan",
        x_max_online_users: "Concurrent online user limit",
        x_gen_share_link: "Generate Share Link",
        x_share_link_clipboard: "Share link copied to clipboard",
        x_share_pwd: "Share password copied to clipboard",
        x_qrcode: "QR Code",
        x_loading_qrcode: "Download QR Code",
        x_root_folder_not_operable: "Root folder can not be manipulated",
        x_visit: "Visit",
        x_workspace_share: "Workspace Share",
        x_username_or_teamname_input: "User name or Team name",
        x_layer_property: "Layer Property",
        x_default_open: "Default Open",
        x_structure: "Structure",
        x_preview: "Preview",
        x_add_tag: "Add Tag",
        x_tag: "Tag",
        x_append_tag: "Append Tag",
        x_tag_search: "Tag Search",
        x_remove_tag: "Remove Tag",
        x_specify_scene: "Default Scene",
        x_specify_dashboard: "Default Dashboard",
        x_preview_these_resource: "Preview these resource",
        x_add_layer_group: "Add Group",
        x_close_preview: "Close Preview",
        x_unsupport_notice:
            "This data type does not support automatic publishing. Our support team will contact you later after submitting the resource publishing request.",
        x_layer_preview_tip:
            "Drag and drop to adjust order; Click to update name; Click add button to add layer group",
        x_query_service_url: "Query Data Service URL",
        x_not_selectable: "Not Selectable",
        x_enable_query: "Enable Query",

        x_layer_s3m: "S3M",
        x_open_failed: "failed to open",

        x_attr_exp_num_rec_str:
            "Edit failed. This field only allows numeric values.",
        x_shp_feat_geom_edit: "Edit feature geometry",
        x_shp_feat_geom_delete: "Delete feature",

        x_high: "high",
        x_low: "low",
        x_alert_str: "{0} recorded {1} reading of {2}: {3}",

        x_arcgis_server_map: "ESRI map service",
        x_supermap_3d: "SuperMap 3D service",
        x_url_template: "URL template imagery",
        x_wms_layer: "WMS service",
        x_wmts_layer: "WMTS service",

        x_workspace_template: "Workspace Interface Template",
        x_proj_template: "Project Interface Template",
        // x_sdms: 'Default GeoTwin project',
        x_sdms: "Default workspace template",
        x_lite: "GeoTwin Lite",
        x_plan_template: "Planning & Design Template",
        // x_iot_prototype: 'IoT Control Dashboard (prototype)',
        x_iot_prototype: "IoT Control Dashboard",
        x_t2_portal_prototype: "T2 Portal Prototype",
        x_dms: "DMS",

        x_iot_ui_cust: "Interface Customization",
        x_iot_enter_cust: "Enter customization view",

        // The followings are applied to dynamically build system informations
        x_system: "System",

        // Strings that was supposed to be encrypted from backend but temporarily moved back to frontend since we are not decrypting Chinese characters now. Hopefully migrate these to backend when possible.
        x_pricing_free: "Free",
        x_pricing_std: "Standard",
        x_pricing_adv: "Advanced",
        x_pricing_pro: "Pro",
        x_pricing_ult: "Ultimate",
        x_edi_pms_lite: "PMS Lite",
        x_edi_pms: "PMS",
        x_pms_map_switch: "To map view",
        x_pms_grid_switch: "To grid view",
        x_edi_bim: "BIM & GIS",
        x_media: "Media",
        x_ptc_las: "Point Cloud (las)",
        x_geotiff: "GeoTiff",
        x_panorama: "Panorama images",
        x_photo: "Photo",
        x_3dtiles: "3D Tiles",
        x_3dtiles1: "3D Tiles",
        x_trajectory_csv: "Trajectory (csv)",
        x_kml: "KML",
        x_obj: "OBJ",
        x_shp: "ESRI shapefile",
        x_file: "File",
        x_bentley_dgn: "Bentley DGN",
        x_dxf_dwg: "Autodesk DXF/DWG",
        x_collada_dae: "Collada DAE",
        x_revit: "Autodesk Revit",
        x_gsm_csv: "Points (csv)",
        x_dem_geotiff: "DEM (GeoTiff)",
        x_video: "Video",
        x_plan: "Design Option",
        x_design_scheme_project_list: "Project List",
        x_design_scheme_create_new: "Create New",
        x_design_scheme_close_scheme: "Close Scheme",
        x_design_scheme_project_name: "Project Name",
        x_design_scheme_last_update: "Last Update",
        x_design_scheme_option_name: "Option Name",
        x_design_scheme_step_1: "Step 1.",
        x_design_scheme_step_2: "Step 2.",
        x_design_scheme_step_3: "Step 3.",
        x_design_scheme_step_4: "Step 4.",
        x_design_scheme_step_5: "Step 5.",
        x_design_scheme_step_6: "Step 6.",
        x_scheme_management: "Scheme Management",

        x_design_scheme_site_boundary: "Site Boundary",
        x_design_scheme_height_restriction: "Height Restriction (mPD)",
        x_design_scheme_area: "Area(sq.m)",
        x_design_scheme_max_dom_pr: "Max. Permitted Domestic Plot Ratio",
        x_design_scheme_site_level: "Site level(mPD)",
        x_design_scheme_max_nondom_pr: "Max. Permitted Non-domestic Plot Ratio",
        x_design_scheme_storey_restriction: "Storey Restriction",
        x_design_scheme_site_coverage: "Site Coverage(%)",
        x_design_scheme_dom_pr: "Total Domestic Plot Ratio",
        x_design_scheme_nondom_pr: "Total Non-Domestic Plot Ratio",
        x_design_scheme_composite_formula: "Use Composite Formula",
        x_design_scheme_podium_pr: "Total Podium Plot Ratio",
        x_design_scheme_nondom_tower_pr: "Non-Domestic Tower Plot Ratio",
        x_design_scheme_basement_pr: "Basement Plot Ratio",

        x_outside_site: "Drawing outside site",
        x_collide_nba: "Drawing clash with non-building area",
        x_collide_block: "Drawing clash with existing buildings",

        x_dom_concession: "Domestic concession (%)",
        x_nondom_concession: "Non-domestic concession (%)",
        x_dom_floor_height: "Domestic floor height (m)",
        x_nondom_floor_height: "Non-domestic floor height (m)",

        x_select_site: "Select site",
        x_all: "All",

        x_design_scheme_domestic: "Domestic Tower",
        x_design_scheme_podium: "Podium",
        x_design_scheme_nondom: "Non-Domestic Tower",
        x_design_scheme_floor_height: "Floor to Floor Height",
        x_design_scheme_lobby_height: "Lobby Floor Height",
        x_design_scheme_refuge_height: "Refuge Floor Height",
        x_design_scheme_gfa_concession: "GFA Concession(%)",
        x_design_scheme_tower_group_no: "No. of Tower Type(s)",
        x_design_scheme_use_up_podium: "Use up podium P.R.",
        x_design_scheme_draw_podium: "Draw Podium",

        x_design_scheme_tower_type: "Tower Type",
        x_design_scheme_tower_height_restriction: "Max Building Height (mPD)",
        x_design_scheme_tower_no: "No. of Towers",
        x_design_scheme_flat_size: "Average Flat Size (sq.m)",
        x_design_scheme_flat_no: "No. of Flats per Floor",
        x_design_scheme_footprint: "Footprint Size (sq.m)",
        x_design_scheme_build_on: "Building On",
        x_design_scheme_build_form: "Building Form",
        x_design_scheme_site: "Site",
        x_design_scheme_podium_residual: "Podium Residual",
        x_design_scheme_dom_block_type: "Domestic Block Types",
        x_design_scheme_nondom_block_type: "Non-domestic Block Types",
        x_design_scheme_geom_block_type: "Geometry Blocks",
        x_design_select_footprint: "Select footprint for ",

        x_design_scheme_avail_towers: "Available Towers to Add",
        x_design_scheme_typ_floor: "No. of Typical Floors",
        x_design_scheme_ref_floor_count: "No. of Refuge Floors",
        x_design_scheme_lobby_floor_count: "No. of Lobby Floors",
        x_design_scheme_total_floor_count: "Total No. of Floors",
        x_design_scheme_flat_no_tower: "No. of Flats per Tower",
        x_design_scheme_tower_height: "Building Height (m)",
        x_design_scheme_tower_mpd: "Building Height (mPD)",
        x_design_scheme_floor_no_pod: "No. of Floors",
        x_design_scheme_footprint_pod: "Podium Footprint Size",
        x_design_scheme_gfa_pod: "Podium GFA",
        x_design_scheme_do_name: "Design Option Name:",
        x_project_design_scheme: "Project & Design Option",

        // IoT and IoT template related (device/chart management)
        x_add_chart: "Add new chart",
        x_chart: "Chart",
        x_device: "Device",
        x_form: "Form",
        x_table: "Table",
        x_monitor: "monitor",
        x_device_info: "Device Info",
        x_alert_level: "Alert Level",
        x_device_name: "Device Name",
        x_generic: "Generic",
        x_chart_type: "Chart type",
        x_chart_line: "Line chart",
        x_chart_bar: "Bar chart",
        x_chart_pie: "Pie chart",
        x_chart_gauge: "Gauge chart",
        x_reading_column: "Reading column",
        x_time_column: "Date time column",
        x_data_column: "Data column",
        x_series_column: "Series column",
        x_time_type: "Visulization period",
        x_time_range: "Date range",
        x_time_last: "Recent",
        x_prev_days: "Recent days",
        x_chart_line_color: "Chart color",
        x_max_val: "Max value",
        x_min_val: "Min value",
        x_set_unit: "Set unit",
        x_set_fontsize: "Set font size",
        x_real_time_img: "Real time photo",
        x_is_iot_link_layer: "Link to IoT layer",
        x_link_layer: "Linked layer",

        x_metadata: "Metadata",
        x_attr: "Attributes",
        x_rec: "Record",
        x_warn_rule: "Alert Setting",
        x_alert_source: "Alert Source",
        x_alert_alias: "Alert Alias",
        x_alert_value: "Alert Value",
        x_alert_log: "Alert Log",
        x_base_info: "Basic Info",
        x_flie_info: "Flie Info",
        x_correct_longitude: "Please enter the correct longitude",
        x_correct_latitude: "Please enter the correct latitude",
        x_update_time: "Update time",
        x_update_frequency: "Update Frequency",
        x_update_frequency_tip:
            "The setting of update frequency would be applied for all devices under this category",

        x_text: "Text",
        x_num: "Number",
        x_attachment: "Attachment",
        x_time: "Time",
        x_int: "Integer",
        x_bigint: "Big integer",
        x_float: "Float number",

        x_col_name: "Field name",
        x_col_alias: "Alias",
        x_col_type: "Field type",
        x_value: "Value",

        x_col_condition: "Condition",
        x_col_value: "Alert Value",
        x_col_level: "Alert Level",
        x_col_memo: "Alert Memo",

        x_col_max_val: "Expected Maximum Value",
        x_col_min_val: "Expected Minimum Value",

        x_visible_all: "Visible to all",
        x_admin_check_user_count: "Current system online users",
        x_company_check_user_count: "Current company online users",

        x_download_model: "Download Template",
        x_pilot_management: "Pilot Management",
        x_operation_panel: "Operation Panel",
        x_comfirm_delete: "Are you sure to delete this field",

        x_del_site_confirm: "Are you sure you want to delete this site?",
        x_add_podium: "Add Podium",
        x_add_block: "Add Block",
        x_del_build_confirm: "Are you sure you want to delete this building?",
        x_dev_param: "Development Parameters",
        x_site_area: "Site Area",
        x_non_building_area: "Non-Building Area",
        x_site_no: "No. of Sites",
        x_podiums_no: "No. of Podiums",
        x_blocks_no: "No. of Blocks",
        x_site_coverage: "Site Coverage",
        x_mpd_max_height: "Max Height in mPD",
        x_gfa_sum: "GFA Summary",
        x_total_gfa: "Total GFA",
        x_dom_gfa: "Domestic GFA",
        x_n_dom_gfa: "Non-domestic Tower GFA",
        x_tt_plot_r: "Total Plot Ratio",
        x_dom_plot_r: "Domestic Plot Ratio",
        x_n_dom_plot_r: "Non-Domestic Plot Ratio",
        x_del_dsp_firm: "Are you sure to delete the Design Scheme Project “",
        x_del_dso_firm: "Are you sure to delete the Design Scheme Option “",

        x_design_scheme_draw_block: "Draw Block",
        x_domestic: "Domestic",
        x_non_domestic: "Non-Domestic",
        x_drawing_texture: "Texture",
        x_upload_files: "Upload files",
        x_change_footprint: "Change Footprint",
        x_select_from_library: "Select from Library",

        x_save_as: "Save As",
        x_previous: "Previous",
        x_design_scheme_podium_over_15: "Podium height exceeds 15m.",
        x_footprint: "Footprint",
        x_select: "Select",

        x_design_concession: "Percentage of GFA Concession(%)",
        x_design_basement_gfa: "Accountable GFA of Basement(s)",
        x_design_account_gfa: "Accountable GFA",
        x_design_account_gfa_total:
            "Total Accountable GFA (Including Basement(s))",
        x_my_file: "My File",
        x_all_file: "All File",
        x_file_detail: "File Detail",
        x_folder_content: "Folder Content",
        x_storage_info_tip: "Select file to view details",
        x_storage_info_tip2: "This folder is empty",
        x_go_back: "Go Back",
        x_move_to: "Move To",
        x_folder: "Folder",
        x_unzip: "Unzip",
        x_unzip_to: "Unzip To",
        x_copy_to: "Copy To",
        x_move_tip: "Cannot move file to itself or its subdirectory",
        x_copy_tip: "Cannot copy file to itself or its subdirectory",
        x_upload_list: "File upload list",
        x_upload_list_tip: "Show only this upload task",

        x_my_resources: "My Resources",
        x_my_participation: "My Participation",
        x_view_type: "view type",
        x_creator: "Creator",
        x_date_range: "Date range",
        x_create_date_range: "Create Date range",
        x_capture_date_range: "Capture Date range",
        x_reverse: "Reverse",
        x_detail: "Detail",
        x_detail_view: "Detail View",
        x_visibility: "visibility",
        x_add_component: "Add component",
        x_content_properties: "Content Properties",
        x_text_number: "Text(number)",
        x_text_color: "Text color",
        x_target_url: "target URL",
        x_document: "Document",
        x_advance_setting: "Advanced Setting",
        x_chart_attribute_editor: "Chart Attribute Editor",
        x_border: "Border",
        x_border_style: "Border style",
        x_border_thickness: "Border thickness",
        x_border_color: "Border color",
        x_component_title: "Component Title",
        x_main_color: "Main",
        x_sub_color: "Sub",
        x_border_background: "Border and background",
        x_background_picture: "Background picture",
        x_background_color: "Background color",
        x_background_gaussian_blur: "Background Gaussian blur",
        x_confirm_add: "Confirm add",
        x_text_size: "Text size",
        x_video_stream_properties: "Video stream properties",
        x_video_stream_preview_properties: "Video stream preview properties",
        x_document_select_message: "Please select at least one document",

        x_tags_add_message: "The tags name cannot be empty",

        x_ungroup_document_select_message:
            "Please select at least one document in ungrouped area",
        x_document_alias: "Document Alias",
        x_picture: "Picture",
        x_cancel_option: "Cancel option",
        x_my_star: "My Star",
        x_star: "Star",
        x_cancel_star: "Cancel Star",
        x_login_site_title: "Login to GeoTwin Platform",
        x_pwd_reset: "Password Reset",
        x_login_with: "Login with",
        x_forget_pwd_tip:
            "If you have forgotten your password, please enter your account’s email address below and click \"submit\" button. You will receive an email that contains a new password.",
        x_forget_pwd_tip2:
            "Password Reset, Please check your email for a new password.",
        x_sign_up_title: "Sign up to GeoTwin Platform",
        x_back_to_login: "Back to Login Page",
        x_send_verification_code: "Send Verification Code",
        x_submit_verification_code: "Submit Verification Code",
        x_verification_code: "Verification Code",
        x_send_again: "Send Again",
        x_send_verification_code_tip:
            "Verification Code has been sent to your email!",
        x_invite_user: "Invite users",
        x_link_invitation: "Link invitation",
        x_email_invitation: "Email invitation",
        x_click_copy: "Click Copy",
        x_invite_user_input_tip:
            "Please select the user to receive the invitation (user name or mailbox can be searched)",
        x_invite_email_input_tip:
            "Please select the mailbox to receive the invitation (user name or mailbox can be searched)",
        x_email: "Email",
        x_send_invitation: "Send invitation",
        x_copy_link: "Copy link",
        x_copy_link_tip: "Copy link succeed",
        x_comfirm_remove: "Are you sure to move {0} {1} out of the company?",
        x_generate_link: "Generate link",
        x_validate_tip: "Please select a role first",
        x_public_resource: "Public Resource",
        x_dashboard: "Dashboard",
        x_create_new_template: "Create new template",
        x_projects: "Projects",
        x_projects_overview: "Projects overview",
        x_settings: "Settings",
        x_load_tip: "All loaded, {total} in total",
        x_overview: "Assets Overview",
        x_members: "Members",
        x_company: "Company",
        x_online_help: "Online help",
        x_feedback: "Feedback",
        x_log_out: "Log out",
        x_faq: "FAQ",
        x_account: "Account",
        x_admin: "Admin",
        x_space_domain: "Spatial domain name",
        x_space_capacity: "Space capacity",
        x_workbench: "Workbench",
        x_company_members: "Company members",
        x_freq: "Freq",
        x_delete_confirm: "Are you sure to delete the selected file?",
        x_month: "Month",
        x_add_resource: "Add Resource",
        x_add_layer: "Add Layer",
        x_create_layer: "Create New Layer",
        x_lock: "Lock",
        x_chart_tip: "Date range cannot be the same day",
        x_custom: "Custom",
        x_event: "Events",
        x_daily_visit: "Daily Visit",
        x_workspace_visit: "Workspace Visit",
        x_real_visit: "real visit",
        x_anon_visit: "anon visit",
        x_process: "Process",
        x_defect: "Defect",
        x_defect_type: "Defect Type",
        x_refresh: "Refresh",
        x_save_preview: "Save Preview",
        x_by_type: "By Type",
        x_by_user: "By User",
        x_by_time: "By Time",
        x_downloading: "Downloading...",
        x_downloaded: "Downloaded",
        x_save_preview_success: "Save Preview Success",
        x_project_document: "Project Document",
        x_length_q_s: "Length",
        x_perimeter: "Perimeter",
        x_area_area: "Area of area",
        x_area_sqm_dot: "m²",
        x_angle_angle: "Angle of angle",
        x_angle_angle_angle: "°",
        x_domain_name: "Domain name",
        x_company_name: "Company name",
        x_domain_name_empty_err: "Domain name can not be empty",
        x_domain_name_invlidate: "Incorrect domain name characters contains",
        x_retrieve_info_feature: "Retrieve detail information of a map feature",
        x_start_ident: "Start Identify",
        x_start_ident_point: "By Point",
        x_start_ident_line: "By Line",
        x_start_ident_polygon: "By Polygon",
        x_start_ident_circle: "By Circle",
        x_cancel_ident: "Cancel Identify",
        x_danger_zone: "Danger Zone",
        x_remove_project: "Remove project",
        x_remove_project_tip:
            "Deleting the project is deleted along with all of its associated data.",
        x_remove_project_tip2: "This operation cannot be recovered!",
        x_remove_project_verify_title: "Sensitive operation verification",
        x_remove_project_tip3: "You are deleting project",
        x_project_name_verify_err: "Project name validation error",
        x_project_name: "Project Name",
        x_workspace_name: "Workspace Name",
        x_enter_project_name_verify: "Please enter a project name to verify",
        x_assets_category: "Assets Category",
        x_add_category: "Add Category",
        x_add_device: "Add Device",
        x_cctv: "CCTV",
        x_worker: "Worker",
        x_gender: "Gender",
        x_vehicle: "Vehicle",
        x_personnel: "Personnel",
        x_car_model: "Model",
        x_car_plate: "Plate",
        x_upload_batch_device: "Upload Batch Device",
        x_method: "Method",
        x_step: "Step",
        x_setup_category: "Setup Category",
        x_category_name: "Category Name",
        x_record: "Record",
        x_record_attr: "Record Attributes",
        x_record_Info: "Record Info",
        x_upload_draft: "Upload Draft",
        x_draft_manage: "Draft Manage",
        x_draft_info: "Draft Information",
        x_login_title: "Login title",
        x_login_background: "Background",
        x_validate_invlidate: "The content format is incorrect",
        x_days: "Days",
        x_validity: "Validity",
        x_validity_tip: "Sorry, the sharing link has expired!",
        x_pwd_label: "Please input workspace password",
        x_permanent: "Permanently valid",
        x_report: "Report",
        x_report_name: "Report Name",
        x_company_private: "Visible only to the company",
        x_company_power_tip:
            "Sorry, you do not have permission to access this link!",
        x_draw_entity: "Draw Entity",
        x_add_to_resource: "Add to resource",
        x_upload_icon: "Upload Icon",
        x_geojson: "Geojson",
        x_ifc: "IFC",
        x_add_to_workspace: "Add to workspace",
        x_device_in_Workspace: "Device in workspace",
        x_dashboard_logo: "Dashboard logo",
        x_dashboard_theme: "Dashboard theme",
        x_add_to_project: "Add To Project",
        x_create_new_dashboard: "Create New Dashboard",
        x_template_management: "Template Management",
        x_empty_template: "Empty template",
        x_edit_dashboard: "Edit Dashboard",
        x_change_hub: "Change Hub",
        x_function: "Function",
        x_osgb: "OSGB",
        x_code_tip: "Scan code concerns Geosys official account",
        x_project_preview: "Project preview",
        x_location: "Location",
        x_icon_type: "Icon Type",
        x_monitoring_management: "Monitoring Management",
        x_monitoring_wall: "Monitoring wall",
        x_add_monitoring_wall: "Add Monitoring wall",
        x_map: "Map",
        x_add_to_monitoring_wall: "Add To Monitoring Wall",
        x_cctv_add: "Add",
        x_cctv_remove: "Remove",
        x_return_to_list: "Return to list",
        x_richtext_tip: "Click edit text",
        x_project_menu: "Project Menu",
        x_minimize: "Minimize",
        x_restore: "Restore",
        x_device_detail: "Device Detail",
        x_alert_detail: "Alert Detail",
        x_comparison: "Comparison",
        x_add_marker: "Add Marker",
        x_add_Measurement: "Add Measurement",
        x_marker_name: "Marker Name",
        x_initial: "Initial",
        x_initial_date: "Initial Date",
        x_initial_reading: "Initial Reading",
        x_measure_date: "Measure Date",
        x_level: "Level",
        x_diff_initial: "Diff Initial",
        x_diff_previous: "Diff Previous",
        x_last_reading_date: "Last Reading Date",
        x_last_reading: "Last Reading",
        x_last_diff_initial: "Last Diff Initial",
        x_alarm: "Alarm",
        x_team_name: "Team Name",
        x_team_members: "Team Members",
        x_team_menu: "Team Menu",
        x_add_team_member: "Add Team Member",
        x_username_input: "User name",
        x_iot_category: "IOT category",
        x_cctv_category: "CCTV category",
        x_worker_category: "Worker category",
        x_vehicle_category: "Vehicle category",
        x_generic_alert: "IOT alert",
        x_personnel_alert: "Worker alert",
        x_vehicle_alert: "Vehicle alert",
        x_cctv_alert: "CCTV alert",
        x_generic_list: "IOT list",
        x_personnel_list: "Worker list",
        x_vehicle_list: "Vehicle list",
        x_cctv_list: "CCTV list",
        x_view_monitoring: "View monitoring",
        x_iot_management: "IOT Management",
        x_cctv_management: "CCTV Management",
        x_worker_management: "Worker Management",
        x_vehicle_management: "Vehicle Management",
        x_datepicker: "Date Picker",
        x_dropdown: "Drop Down",
        x_query_result: "Query result",
        x_lower_limit: "Lower Limit",
        x_upper_limit: "Upper Limit",
        x_subscribe: "Subscribe",
        x_unsubscribe: "Unsubscribe",
        x_task_name: "Task Name",
        x_model: "Model",
        x_model_status: "Status",
        x_run: "Run",
        x_subscribe_attribute: "Subscribe Attribute",
        x_upper_clothes: "Upper Clothes",
        x_lower_clothes: "Lower Clothes",
        x_upper_clothes_color: "Upper Clothes Color",
        x_lower_clothes_color: "Lower Clothes Color",
        x_hat: "Hat",
        x_vest: "Vest",
        x_human_detection: "Human Detection",
        x_vehicle_detection: "Motor Vehicle Detection",
        x_cross_region_detection: "Cross Region Detection",
        x_step_size: "Step Size",
        x_zone_name: "Zone Name",
        x_zone_detail: "Zone Detail",
        x_zone: "Zone",
        x_upload_zone: "Upload Zone",
        x_overview_zone_management: "Zone",
        x_zone_list: "Zone List",
        x_zone_category: "Zone category",
        x_ai_event: "Event",
        x_alert_center: "Alert Center",
        x_push_system: "Push System",
        x_push_email: "Push Email",
        x_push_whatsapp: "Push App",
        x_alert_col: "Alert Col",
        x_push_staffing: "Push staffing",
        x_bool: "Bool",
        x_bool_value: "Bool Value",
        x_rule_name: "Rule Name",
        x_rule: "Rule",
        x_add_member: "Add member",
        x_forbidden_zone: "The Forbidden Zone",
        x_label: "Label",
        x_decorate: "Decorate",
        x_decorate_type: "Decorate Type",
        x_select_picture: "Select picture",
        x_select_color: "Select color",
        x_border_radius: "Border radius",
        x_label_text: "Label text",
        x_alignment: "Alignment",
        x_align_left: "Align Left",
        x_align_right: "Align Right",
        x_align_center: "Align Center",
        x_font_weight: "Font weight",
        x_background_size: "Background size",
        x_picture_source: "Picture source",
        x_pic_upload: "Picture Upload",
        x_net_address: "Network address",
        x_interface_data: "Interface Data",
        x_interface_address: "Interface Address",
        x_interface_type: "Interface Type",
        x_interface_calling_error:
            "Please check the calling parameters or contact the administrator",
        x_response_data: "Response Data",
        x_pie_chart_setting: "Pie Chart Setting",
        x_value_setting: "Value setting",
        x_set_ring_type: "Set Ring Type",
        x_set_nightingale_rose: "Set Nightingale Rose",
        x_auto_sort: "Auto Sort",
        x_not_show_zero: "Not Show Zero",
        x_show_value: "Show Value",
        x_border_width: "Border width",
        x_real_time: "Real Time",
        x_time_format: "Time format",
        x_time_format1: "Date",
        x_time_format2: "Date + hour, minute",
        x_time_format3: "Date + hour, minute and second",
        x_time_format4: "Date (No Year)",
        x_time_format5: "Hour, minute",
        x_time_format6: "Hour, minute and second",
        x_time_format7: "Week",
        x_custom_format: "Custom format",
        x_font_spacing: "Font spacing",
        x_show_widget_windowstate: "Show widget windowstate",
        x_widget_name: "Widget name",
        x_weather: "Weather",
        x_current_weather: "Current weather",
        x_weather_forecast: "Weather forecast",
        x_display_type: "Display type",
        x_card: "Card",
        x_show_weather_warning: "Show weather warning",
        x_show_days: "Show days",
        x_chart_title: "Chart title",
        x_chart_title_color: "Chart title color",
        x_inner_ring_size: "Inner ring size",
        x_outer_ring_size: "Outer ring size",
        x_show_legend: "Show legend",
        x_label_position: "Label position",
        x_device_type: "Device type",
        x_generic_iot: "Generic IOT",
        x_date_type: "Date Type",
        x_user_group: "User Group",
        x_editable: "Editable",
        x_in_dashboard: "In Dashboard",
        x_in_album: "In Album",
        x_add_to_dashboard: "Add to dashboard",
        x_add_to_album: "Add to album",
        x_no_exceedence: "No Exceedence",
        x_geotechnical_instrument: "Geotechnical Instrument",
        x_geotechnical_instrument_list: "Geotechnical Instrument List",
        x_offline: "OFFLINE",
        x_online: "ONLINE",
        x_chatroom: "Chatroom",
        x_chatroom_name: "Chatroom Name",
        x_save_and_enter_chatroom: "Save and enter chatroom",
        x_no_chatroom: "No chatroom",
        x_online_people_number: "online people number",
        x_control: "Control",
        x_point_cloud: "Point cloud",
        x_rendering: "Rendering",
        x_chat_enter: "Chat Enter",
        x_chat_leave: "Chat Leave",
        x_currently_online: "Currently Online（ {total} people ）",
        x_define_animation_track: "Define Animation Track",
        x_advanced_search: "Advanced Search",

        x_location_search: "Location Search",
        x_search_placeholder_location:
            "Building,Addresses,Places,Lots(e.g.DD381 Lot 953)",
        x_dmspoint: "DMS Point",
        x_alarmdms: "Alarm Point",
        x_alertdms: "Alert Point",
        x_actiondms: "Action Point",
        x_legend: "Legend",
        Lot: "Lot",
        Address: "Address",
        Building: "Building",
        Places: "Places",
        x_playback: "Trajectory PlayBack",
        x_bangzhu: "Help",
        x_advanced_options: "Advanced Options",
        AI_APP_UPLOAD: "Image upload",
        AI_APP_AI_PROCESS: "AI process",
        New_AI_Processing: "New AI processing",
        AI_APP_AI_RESULT: "AI Result",
        AI_APP_AI_REPORT: "AI Report",
        AI_APP_TASK: "AI Task",
        AI_APP_ACCEPT: "Accept",
        AI_APP_TASK_STATUS_ALL: "All",
        AI_APP_TASK_STATUS_PICTURE: "Processing pictures",
        AI_APP_TASK_STATUS_DATA: "Processing data",
        AI_APP_TASK_STATUS_COMPLETE: "complete",
        AI_APP_TASK_CODE: "Task Code",
        AI_APP_TASK_MODEL: "Model",
        AI_APP_ADD_TAGS: "Add Tags",
        AI_APP_GENERATE_REPORT: "Generate Report",
        x_repair: "Repair",
        x_pending: "Pending",
        x_normal: "Normal",
        x_ignore: "Ignore",
        x_is_the_defect: "Is the defect",
        x_not_sure: "Not sure",
        x_not_the_defect: "Not the defect",
        x_not_confirmed: "Not confirmed",
        x_defect_action: "Defect action",
        x_emergency_repair: "Emergency repair",
        x_pms: "Photo management",
        x_accept: "Accept",
        x_solar_illumination: "Solar illumination",
        x_ambient_occlusion: "Ambient Occlusion",
        x_3d_model_layers: "3D Model",
        x_Basic_Information: "Basic Information",
        x_BIM_Statistics: "BIM Statistics",
        x_BIM_Model: "BIM Model",
        x_menu_notice: "Notice",
        x_mark_as_readed: "Mark as readed",
        x_mark_all_messages_as_readed: "Mark all messages as readed",
        x_view_all: "View all",
        x_extract_password: "Extract password",
        x_have_extract_password: "Have extract password",
        x_share_files: "Share Files",
        x_link_sharing: "Link shareing",
        x_automatically_generates_extracts_passwords: "Automatically generate password",
        x_please_enter_the_extraction_password: "Please enter the password",
        x_create_new_link: "Create link",
        x_successfully_created_private_link: "Successfully created private link",
        x_no_extraction_password_set: "No extraction password set",
        x_link: "Link",
        x_effective: "effective",
        x_day: "day",
        x_lose_efficacy: "lose efficacy",
        x_iot: "IOT",
        x_device_list: "Device List",
        x_devices: "Devices",
        x_products: "Products",
        x_alerts: "Alerts",
        x_alert_list: "Alert List",
        x_files_folders_selected: "files / folders selected",
        x_register: "Register",
        x_late_arrival_the_file_sharing_has_expired: "Late arrival, the file sharing has expired",
        x_after_closing_extraction_code_for_sharing_links: "After closing, there is no need to fill in the extraction code for sharing links.",
        x_users_click_open_the_shared: "Users click on the link to directly open the shared content.",
        x_project_visible: "This project is visible",
        x_company_visible: "Visible to our company",
        x_all_visible: "Full network visibility",
        x_yes: "Yes",
        x_no: "No",
        x_4d_configure: "4D Configure",
        x_add_4d_configure: "Add 4D Configure",
        x_schema_name: "Schema Name",
        x_field_name: "Field Name",
        x_execute_odm: "Execute 3D Reconstruction",
        x_execute_splat: "Execute Splat",
        x_odm_task: "3D Reconstruction Task",
        x_splat_task: "Splat Task",
        x_quality: "Quality",
        x_fail: "Fail",
        x_comfirm_delete2: "Are you sure to delete {0}",
        x_download_sample_file: "Download sample file",
        x_feature_name: "Feature Name",
        x_project_user_tips: "There is no data",
        x_model_info: "Model Information",
        x_scale: "Scale",
        x_pitch: "Pitch",
        x_roll: "Roll",
        x_model_quality: "Model Quality",
        x_setting_no_flatten: "No Flatten",
        x_location_Type: "Location Type",
        x_by_coordinates: "By coordinates",
        x_by_address: "By address",
        x_x_coordinate_field: "X-coordinate field",
        x_y_coordinate_field: "Y-coordinate field",
        x_address_field: "Address field",
        x_new_add: "Add",
        x_menu_management: "Menu Management",
        x_role_management: "Role Management",
        x_department_management: "Dept Management",
        x_department_name: "DeptName",
        x_sort: "sortOrder",
        x_menu_name: "Menu Name",
        x_permission: "Permission",
        x_grant: "Grant",
        x_role_code: "roleCode",
        x_root_department: "Root Department",
        x_department: "Department",
        x_root_menu: "Root Menu",
        x_menu: "Menu",
        x_button: "Button",
        x_parent_menu: "Parent Menu",
        x_menu_cannot_delete: "The menu contains inferiors that cannot be deleted",
        x_dept_cannot_delete: "The department contains inferiors that cannot be deleted",
        x_role_describe: "Role Describe",
        x_features: "Features",
        x_capabilities: "Capabilities",
        x_cases: "Cases",
        x_dont_have_account: "Don't have an account?",
        x_already_member: "Already a member?",
        x_signup_success: "Sign up Success",
        x_signup_success_tip: "If your company has a Hub, please contact the company staff to give you the appropriate permissions, you can log on",
        x_create_hub_tip: "If you have no Hub , please click on the link below to create. Thank you.",
        x_log_management: "Log Management",
        x_you_can_enter_below_hub: "You can enter the following Hub",
        x_submit_success: "Submit Success",
        x_create_hub_introduce: "You don't have a company account yet, please click Enter the following information and confirm submission; or click Contact Us in the lower right corner. We will contact you in time. Thanks!",
        x_create_hub_introduce_cn: "You do not have a company account, please add our enterprise micro-signal, contact our business staff. Thank you",
        x_create_hub_success_tip: "We will arrange personnel to contact you as soon as possible. Thank you.",
        x_return_to_signin: "Return to Sign In Page",
        x_operation: "Operation",
        x_batch_operation: "Batch Operation",
        x_log_method: "method",
        x_remove_user_tip: "Are you sure to remove the user?",
        x_project_time: "Project time",
        x_enter: "Places enter",
        x_project_list: "Project List",
        x_file_count: "File count",
        x_file_type: "File type",
        x_publish_new_resource: "Publish New Resource",
        x_total_resource: "Total resource",
        x_compute_power_use: "Compute power use",
        x_total_visits: "Total visits",
        x_total_storage: "Total storage",
        x_resource_type: "Resource type",
        x_from_small_to_large: "From smallest to largest",
        x_from_large_to_small: "From largest to smallest",
        x_invite_register: "Invite Register",
        x_digital_twins: "Digital Twins",
        x_auth_intro: "Science and technology shape the future, intelligence to create wonderful!",
        x_publish_type: "Publish type",
        x_basic_options: "Basic Options",
        x_product: "Product",
        x_base_layer_setting: "Base Layer Setting",
        x_search_result: "Search result",
        x_descending: "Descending",
        x_ascending: "Ascending",
        x_comfirm_delete_resource: "Are you sure to delete resource {0}",
        x_template: "Template",
        x_template_smart: "Smart Construction",
        x_template_common: "Common",
        x_template_ai: "AI",
        x_fold_menu: "Fold Menu",
        x_template_default: "Defalut Scene",
        x_template_panorama: "Panorama Scene",
        x_template_gis: "GIS Scene",
        x_template_bim: "BIM Scene",
        x_using_wechat_login: "Sign In with WeChat",
        x_share_link_validity_tip: "The effective date of the sharing link cannot be empty",
        x_data_directory: "Data directory",
        x_path_select: "Path Selection",
        x_template_name: "Template Name",
        x_wechat: "WeChat",
        x_already_bound: "Already bound",
        x_unbind: "Unbind",
        x_bind_wechat: "Bind WeChat",
        x_avatar: "Avatar",
        x_app_title: "GeoTwin Geospatial Computing Digital Twin Platform",
        x_showOverview: "Show in overview",
        x_in_progress: "In progress",
        x_asset_mapview: "Assets Map",
        HONGKONG: "Hong Kong, china",
        TAIWAN: "Taiwan, china",
        CHINA: "Chinese mainland",
        x_password_rule_1: "8-16 characters",
        x_password_rule_2: "must contain uppercase letters and numbers and special symbols",
        x_password_rule_3: "not too common",
        x_solution: "Solution",
        x_about_us: "About Us",
        x_select: "Please select",
        x_save_perspective: "Save Perspective",
        x_select_position: "Select Position",
        x_reselect_position: "Reselect Position",
        x_editable_source_publish: "Editabel Source Publish",
        x_blank_layer: "Blank layer",
        x_feature_type: "Feature Type",
        x_point: "Point",
        x_line: "Line",
        x_polygon: "Polygon",
        x_field_name_format_err: "Use only lowercase letters, numbers, and underscores to form snake-shaped names (numbers do not start)",
        x_wechat_login_err_unbind: "You are not bound to wechat, please login or register after automatically bound wechat account",
        x_set_attribute: "Set Attribute",
        x_upload_info: "The same file already exists,please choose whether to overwrite or create a copy",
        x_cover: "Cover",
        x_create_copy: "Create a copy",
        x_signup_pwd_error: "The password does not contain Spaces and must be at least 8 characters long",
    },
    "zh-CN": {
        x_upload_status_success: "上传成功",
        x_upload_status_fail: "上传失败",
        x_upload_status_uploading: "上传中",
        x_upload_status_paused: "暂停",
        x_upload_status_waiting: "等待",
        x_upload_read_file_error: "读取文件失败",
        x_upload_md5_checking: "MD5校验",
        x_upload_file_merging: "合并中",
        x_upload_file_transcoding: "转码中",
        x_common_error: "错误",
        x_upload_choose_file: "选择文件",
        x_upload_file_list: "文件列表",
        x_common_empty: "暂无内容",
        x_upload_file_upload: "文件上传",
        x_bangzhu: "帮助",
        x_advanced_options: "高级选项",
        x_scene: "场景",
        x_create_new_scene: "创建新场景",
        x_publish: "发布",
        x_successful_publication: "已发布",
        x_publishing_failure: "发布失败",
        x_publishing: "正在发布",
        x_project_mgn: "项目管理",
        x_data_mgn: "数据管理",
        x_asset_mgn: "资产管理",
        x_ai_mgn: "AI管理",
        x_ai_model_mgn: "AI模型",
        x_account_mgn: "账户管理",
        x_shared: "我的分享",
        x_shared_man: "分享人",
        x_all_resource: "全部资源",
        x_add_new_company: "新建公司账号",
        x_date_and_size: "创建时间 / 大小",
        x_expire_date: "失效日期",
        x_visits_number: "访问次数",
        x_link_expired: "链接已失效",
        x_unshare: "停止共享",
        x_unzipping: "解压中...",
        x_unzipped: "解压完成",
        x_login_send_again: "再次发送",
        x_select_or_input: "请选择或输入",
        x_hub_hint: "请提交公司logo，公司名称及缩写以下email，我们会及时与您联系。",
        x_enter_project: "进入项目",
        x_enter_dashboard: "进入大屏",
        x_enter_Project_name: "输入项目名称",
        x_enter_workspace_name: "输入工作空间名称",
        x_select_color: "选择颜色",

        x_playback: "轨迹回放",
        x_dmspoint: "DMS点",
        x_alarmdms: "Alarm Point",
        x_alertdms: "Alert Point",
        x_actiondms: "Action Point",
        x_legend: "图例",
        x_data_loading: "数据加载中...",
        x_my_overview: "我的工作台",
        x_all_member: "所有成员",
        x_add_member: "新增成员",
        x_overview_project: "项目",
        x_overview_resource_storage: "存储空间",
        x_overview_workspace: "工作空间",
        x_overview_user: "用户",
        x_overview_resourve: "资源",
        x_overview_vehicle_management: "车辆管理",
        x_management: "管理",
        x_version: "版本",
        x_upload_new_version: "上传新版本",
        x_overview_today: "今日",
        x_overview_weekly: "本周",
        x_overview_monthly: "本月",
        x_overview_sixty_days: "60天",
        x_overview_project_top_ten: "项目访问量 (前10)",
        x_overview_workspace_top_ten: "工作空间访问量 (前10)",
        x_overview_total_visit: "全部访问量",
        x_viewer: "查看器",
        x_general: "通用",
        x_mode: "模式",
        x_of: "的",
        x_or: "或者",
        x_and_bing: "并且",
        x_add_to: "添加到",
        x_and: "然后",
        x_remove: "清除",
        x_hour: "小时",
        x_project: "项目",
        x_workspace: "工作空间",
        x_assets: "资产",
        x_sensors: "传感器",
        x_dms_admin: "DMS管理",
        x_streaming_video: "实时视频",
        x_pms: "照片管理",
        x_accuracy: "准确度",
        x_batch_update: "批量修改",
        x_confidence: "置信度",
        x_pms_download: "下载",
        x_pms_compare: "多图对比",
        x_pms_create_new: "创建新相册",
        x_pms_layers: "关联图层",
        x_pms_layers_select: "请选择关联图层",
        x_pms_view_map: "地图模式",
        x_pms_view_grid: "网格模式",
        x_pms_view_type: "视图模式",
        x_pms_map_switch: "增加地图模式",
        x_pms_grid_switch: "切回网格模式",
        x_layer: "图层",
        x_baes_layer: "底图",
        x_terrain: "地形",
        x_2d_layer: "二维",
        x_3d_layer: "三维",
        x_3d_viewer: "三维查看器",
        x_pointcloud_viewer: "点云查看器",
        site_title: "GeoTwin 平台",
        x_username: "用户名",
        x_username_or_email: "账户邮箱地址",
        x_password: "密码",
        x_confirm_password: "确认密码",
        x_default: "默认",
        x_comfirm_replica: "确认是否复制此 {0} {1}",
        x_captcha_text: "验证码",
        x_login: "登录",
        x_using_google_login: "使用Google登录",
        x_forget_pwd: "忘记密码？",
        x_comfirm: "确定",
        x_username_empty_err: "用户名不能为空",
        x_password_empty_err: "密码不能为空",
        x_comfirm_password_empty_err: "确认密码不能为空",
        x_password_err: "两次输入密码不一致",
        x_change_password: "修改密码",
        x_current_password: "当前密码",
        x_set_password: "设置密码",
        x_remove_password: "移除密码",
        x_new_password: "新密码",
        x_new_password_dup: "确认新密码",
        x_logout: "登出",
        x_search: "搜索",
        x_reset: "重置",
        x_site_mission_corsys: "坐标系",
        x_default_coord_sys: "默认坐标系",
        x_coord_sys_placeholder: "坐标系名称或EPSG码",
        x_coord_sys_default_wgs84: "默认为 WGS84 (4326)",
        x_measurement: "测量",
        x_ms_height: "高程",
        x_ms_coordinate: "坐标",
        x_ms_volume: "体积",
        x_ms_area_projected: "投影面积",
        x_ms_angle: "角度",
        x_change_hub: "切換公司",

        x_information: "信息",
        x_style: "样式",
        x_style_lowercase: "样式",
        x_attribute: "属性",
        x_2d_border: "边线",
        x_2d_cap: "端点类型",
        x_2d_dash: "类型",
        x_2d_width: "宽度",
        x_2d_font: "字体",
        x_2d_size: "大小",
        x_3d_heading: "朝向",

        x_3d_tidal_analysis: "水面效果绘制",
        x_draw_analysis_range: "绘制分析范围",
        x_flood_height: "淹没高度",
        x_3d_line_of_sight: "可视线分析",
        x_3d_visual_analysis: "可视域分析",
        x_3d_site_formation: "Site Formation",
        x_3d_cut_section: "剖面分析",
        x_3d_skyline_analysis: "天际线分析",
        x_3d_shadow_analysis: "光照阴影分析",
        x_3d_skyview: "天空域分析",
        x_3d_skyview_graph: "天空域示意图",
        x_3d_skyview_factor: "天空域因子",
        x_range: "范围",
        x_pointcloud_is_3dtiles: "发布到三维视图",
        x_pointcloud_is_potree: "发布到点云查看器",
        x_at_least_one_chose: "以下需至少勾选一项",

        x_pwd_very_weak: "非常弱",
        x_pwd_weak: "弱",
        x_pwd_moderate: "中",
        x_pwd_strong: "强",
        x_pwd_very_strong: "非常强",
        x_pwd_weak_no_submit: "密码过于简单",

        x_3d_fly_through: "飞行动画",
        x_fly_path_add: "新建飞行动画",
        x_back_to_fly_path: "返回飞行动画管理",
        x_3d_edit_3d_polyline: "三维线",
        x_3d_edit_3d_box: "多边形拉伸体",
        x_3d_edit_cylinder: "圆柱体",
        x_3d_edit_rectangle_2d: "矩形",
        x_3d_edit_ellipse_2d: "椭圆",
        x_close: "关闭",
        x_signup: "注册用户",
        x_signup_name: "用户名",
        x_signup_email: "电邮",

        // weather
        x_temperature: "温度",
        x_humidity: "湿度",
        x_uvindex: "紫外线",

        x_create: "新建",
        x_submit: "提交",
        x_empty_err: "此栏不能空白",
        x_illegal_err: "此栏不能输入非法字符",
        x_edit: "编辑",
        x_edit_api: "编辑API接口",
        x_delete: "删除",
        x_share: "分享",
        x_share_success: "分享成功",
        x_please_create_a_sharing_link_first: "请先创建分享链接",
        x_member_sharing: "成员分享",
        x_please_select_a_member: "请选择成员",
        x_role: "角色",
        x_teams: "团队",
        x_register_success: "注册成功，请登入",
        x_email_invlidate: "电邮格式不正确",
        x_mobile_invlidate: "手机号格式不正确",
        x_internal_server_err: "服务器出错",
        x_annotations: "标注",
        x_save: "保存",
        x_site_management: "项目管理",
        x_nav_mission: "任务",
        x_sample: "样例",
        x_sample_trajectory: "点击下载样例轨迹文件",

        x_saving: "保存中...",
        x_processing: "处理中...",
        x_ok: "确定",
        x_cancel: "取消",
        x_success: "成功",
        x_site_title: "标题",
        x_site_guest_accessible: "允许访客查看",
        x_site_missions: "任务",
        x_add_mission: "新建任务",
        x_split_view: "双视图",
        x_split_mode: "分屏模式",
        x_curtain_mode: "卷帘模式",

        x_clear_operation: "清除",
        x_lang: "语言",
        x_basemap: "底图图层",
        x_3d_baselayer: "基本三维图层",
        x_render_quality: "渲染质量",
        x_render_quality_low: "低",
        x_render_quality_normal: "普通",
        x_render_quality_high: "高",
        x_render_effect: "渲染效果",
        x_sun_shadow: "日照阴影效果",
        x_blooming: "高光效果",
        x_depth_of_field: "景深效果",
        x_bookmark: "书签",
        x_bookmark_name: "书签名",
        x_data_upload: "数据上传",
        x_select_upload_folder: "选择上传目录",
        x_select_data_folder: "选择数据目录",
        x_folder_no_type_data: "文件夹不包含选择的数据类型",
        x_cal_pub_size: "正在计算发布所需空间",
        x_pub_size_req: "需要空间",
        x_pub_size_total: "可用资源发布空间",
        x_remain_pub_size: "剩余空间",
        x_size_resource: "资源空间",

        x_upload: "上传",
        x_upload_lng: "经度",
        x_upload_lat: "纬度",
        x_upload_height: "高度",
        x_upload_lng_X: "经度",
        x_upload_lat_Y: "纬度",
        x_model_editing: "模型编辑",
        x_model_status: "模型状态",
        x_move: "平移",
        x_rotate: "旋转",
        x_resource_search: "搜索资源",

        x_addlayer: "添加图层",
        x_delete_layer: "删除图层",
        x_navigation: "导航",
        x_analytics: "分析",
        x_full_screen: "全屏",
        x_fly_home: "返回起始位置",
        x_set_project_position: "设置项目起始位置",
        x_true_north: "旋转到正北",
        x_2d_view: "二三维模式切换",
        x_screen_cap: "场景截图",
        x_screen_rec: "场景录制",
        x_rec_stop: "停止",
        x_nodata: "暂无数据",
        x_none: "无",
        x_normal: "普通",
        x_loading: "加载中...",

        x_pms_title: "照片名称",
        x_pms_tags: "标签",
        x_pms_copylink: "复制资源链接",
        x_pms_start_date: "起始日期",
        x_pms_end_date: "终止日期",
        x_pms_define_region: "绘制过滤范围",
        x_pms_filter: "过滤",
        x_pms_search_region: "搜索范围",
        x_pms_select_range: "选择范围",
        x_pms_selected: "张已选中",
        x_pms_selectall: "全选",
        x_pms_unselectall: "全部取消选中",
        x_pms_is_trajectory: "生成轨迹",
        // x_pointcloud_is_potree: '发布到点云查看器',
        x_pano_is_face_blur: "隐私处理",
        x_eo_format: "位姿文件格式",
        x_spatial_offset: "坐标偏移",

        x_eo_loading: "正在读取照片文件信息",
        x_img_info_table: "照片文件信息",
        x_upload_eo_csv: "上传位置信息",
        x_download_eo: "下载位置信息",
        x_publish_resource: "发布资源",
        x_eo_ph_name: "照片名",
        x_easting: "x坐标",
        x_northing: "y坐标",
        x_omega: "俯仰角",
        x_phi: "翻滚角",
        x_kappa: "偏航角",
        x_eo_img_total_count: "个照片文件",
        x_eo_img_positioned: "个照片包含地理坐标",
        x_x: "X坐标",
        x_y: "Y坐标",
        x_z: "Z坐标",
        x_r: "旋转度",

        x_nav_position: "位置设置",
        x_nav_fly_to: "飞到鼠标点选位置",
        x_nav_lock_position: "锁定位置",
        x_nav_zoom_to_bound: "矩形缩放",
        x_nav_2D_view: "二维视角",
        x_nav_view_mode: "浏览模式",
        x_nav_default: "默认鼠标操作",
        x_nav_first_person: "键盘操作",

        x_nav_enter_google_street: "进入谷歌街景",
        x_nav_google_transparency: "街景透明度",
        x_nav_back_3d: "返回三维视图",
        x_nav_streetview_not_found: "该位置未找到街景影像",

        x_bookmark_no_bookmark: "无书签",

        x_ms_volume_base_type: "基准面类型",
        x_ms_volume_base_low: "以最低顶点高程为准",
        x_ms_volume_base_high: "以最高顶点高程为准",
        x_ms_volume_base_ave: "以顶点平均高程为准",
        x_ms_volume_base_triang: "三角网构面",
        x_ms_volume_base_man: "自定义基准面高度",
        x_ms_volume_quick_measure: "快速量算",

        x_ms_volume_base_level: "基准高度(米)",
        x_ms_volume_calculate: "计算体积",
        x_ms_volume_bl: "基准高度",
        x_ms_sampling_interval: "采样间隔",
        x_ms_error_prop: "误差估算",
        x_ms_volume_cv: "挖方体积",
        x_ms_volume_fv: "填方体积",
        x_ms_volume_tv: "总体积",

        x_3d_terrain_excavation: "地形开挖",
        x_3d_terrain_flattening: "三维压平",
        x_3d_clip_plane: "裁剪面",
        x_3d_clip_box: "裁剪盒",
        // x_3d_clip_plane_horizontal: '水平剖切面',
        // x_3d_clip_plane_vertical: '垂直剖切面',
        x_flood_level: "水面高度（米）",
        x_flood_level_placeholder: "水面高度（米）",
        x_flood_define_flood_region: "绘制水面范围",

        x_shadow_height: "光照率分析高度",
        x_shadow_start_time: "开始时间",
        x_shadow_end_time: "结束时间",
        x_shadow_animate: "模拟动画",
        x_shadow_calculate_ratio: "计算区域光照率",
        x_sun_exposure: "光照率",

        x_stop_as_current_pos: "更新为当前视角",
        x_fly_speed: "飞行速度",
        x_duration: "时长",
        x_fly_save_video: "播放完成另存为视频文件",
        x_fly_video_name: "视频文件名",
        x_fly_add_stop: "添加站点",
        x_fly_animate: "播放",

        x_3d_edit_rectangle: "长方体",
        x_3d_edit_site: "压平地块",
        x_3d_edit_arrow: "箭头",
        x_3d_edit_annotation: "标注文本",
        x_3d_edit_redline: "红线批注",
        x_3d_edit_file: "文件",
        x_3d_model: "模型",
        x_upload_title: "上传模型",
        x_upload_drag_note: "点击或拖拽上传",
        x_upload_note: "点击或拖拽以上传含obj模型的zip包",

        x_drawing_area: "面积",
        x_drawing_footprint: "面积",
        x_drawing_color: "颜色",
        x_action: "操作",

        x_sure_del_sketch: "是否删除？",
        x_edit_title: "编辑标题",
        x_sketch_add: "新建草案设计",
        x_quit_sketch_design: "退出草案设计",
        x_export_sketch_design: "导出设计",
        x_floor_count: "楼层数",
        x_floor_height: "楼层高(米)",
        x_gfa_efficiency: "实用比",
        x_gfa: "建筑面积",

        x_project_folder_selection: "选择文件夹",
        x_project_folder_selection1: "选择文件",
        x_project_new_folder: "新建文件夹",
        x_project_folder_name: "文件夹名称",
        x_project_upload_step1: "步骤一：选择远程文件夹",
        x_project_upload_step2: "步骤二：选择本地文件",
        x_category: "类别",
        x_project_publish_resource_name: "资源名称",
        x_project_restricted_status: "修改HUB权限",
        x_static_data: "静态数据",
        x_dynamic_data: "动态数据",
        x_refresh_time: "刷新时间（分钟）",
        x_capture_date: "数据采集日期",
        x_share_resources: "分享资源",
        x_restrict_access: "限制访问",
        x_restrict_access_tips1: "不限制域名访问",
        x_restrict_access_tips2: "限制本hub域名访问",
        x_3d_coordinate_system: "三维坐标系",
        x_rotation_angle: "旋转角度",
        x_coordinate3d_input_tips: "输入必须为double类型的数字，请重新输入",
        x_project_publish_layerurl: "资源地址",
        x_project_publish_ogc_layername: "包含服务中图层名称",
        x_ogc_tile_matrix_set: "比例尺集",
        x_enable_supermap_query: "超图数据服务查询",
        x_supermap_query_url: "查询超图数据服务地址",
        x_supermap_datasource: "数据源",
        x_dataset: "数据集",
        x_data_value: "数据值",
        x_url_inaccessible: "服务地址无法访问",
        x_url_format_invalid: "无法解析服务",
        x_upload_4d_script: "上传时间线脚本",
        x_read_4d_complete: "时间线脚本上传完成",
        x_sm_dataset_default_merge:
            "如果服务为超图合并数据集则无需提供数据集名称",
        x_upload_supermap_workspace: "上传超图工作空间文件",

        x_project_msg_uploading: "正在上传文件...",
        x_project_msg_add_layer_success: "新建图层完成",
        x_login_msg_login_incorrect: "用户名或密码不正确",
        x_index_msg_add_bookmark: "添加书签成功",
        x_index_msg_delete_bookmark: "删除书签成功",
        x_index_msg_bookmark_share: "书签分享地址已复制到剪切板",

        x_index_msg_zip_file: "正在打包文件",
        x_index_msg_zip_photo: "正在打包照片",
        x_index_msg_to_enter_google: "请在三维视角中点选进入谷歌街景的位置",
        x_index_msg_save_init_position: "正在保存项目起始位置",
        x_index_msg_save_init_position_success: "保存项目起始位置成功",
        x_index_msg_generate_video: "正在生成视频",
        x_index_msg_model_share: "模型分享地址已复制到剪切板",
        x_index_msg_model_upload: "正在上传模型",
        x_index_msg_model_process: "正在处理模型",
        x_index_msg_model_success: "模型加载完成",
        x_terrain_transparency: "地形透明",

        x_upload_md5_cal: "正在读取文件...",
        x_upload_md5_fail: "读取文件失败",
        x_upload_sm_no_scene: "工作空间没有三维场景",

        x_3d_edit_pipe: "管线",
        x_volume_management: "土方管理",
        x_vm_title: "土方名称",
        x_vm_base_height: "基准面高（米）",
        x_vm_create_new: "新建体积量算",
        x_vm_to_draw_region: "鼠标左键在三维场景中框选土方范围，右键结束",
        x_vm_no_result: "未找到体积量算结果。请先进行土方体积计算。",
        x_vm_stockpile: "土方名称",
        x_vm_date: "上次测量日期",
        x_vm_left_viewer: "左视图",
        x_vm_right_viewer: "右视图",
        x_vm_report_title: "体积量算报告",
        x_vm_usage: "在三维界面中绘制量算范围或上传基准面信息的csv文件",

        x_add_record_service_api_url: "记录添加服务API地址",

        x_vm_fly_to: "飞到土方所在位置",
        x_vm_visible: "显示/隐藏土方范围",
        x_vm_calculate: "量算",
        x_vm_report: "显示报告",

        x_vm_site_mode: "上传范围类型",
        x_vm_region_ring: "多边形范围",
        x_vm_spot_tin: "高程点",

        x_pipe_edit_title: "编辑管线",
        x_pipe_edit_x: "x（米）",
        x_pipe_edit_y: "y（米）",
        x_pipe_edit_z: "z（米）",
        x_pipe_edit_width: "管直径（米）",
        x_edit_upload_file: "选择上传文件",

        x_console: "系统管理",
        x_dept_name: "组别名称",
        x_add: "增加",
        x_update: "更新",
        x_revise: "修改",
        x_feature: "要素",

        x_poi: "兴趣点",
        x_poi_name: "名称",
        x_poi_add: "添加兴趣点",

        x_nav_navigate: "导航",
        x_nav_view: "视图",
        x_nav_streetview: "街景视图",
        x_nav_routing: "最短路线查询",
        x_nav_selection: "选择",
        x_nav_select: "选择",
        x_nav_locate: "定位",
        x_nav_search_poi: "兴趣点搜索",
        x_nav_search_buffer: "缓冲区查询",
        x_nav_search_bound: "画框查询",
        x_nav_generate_plan: "生成图纸",
        x_nav_helper: "帮助",
        x_nav_save_as_profile: "保存封面",
        x_nav_tool: "工具",
        x_nav_bim: "BIM",
        x_nav_measure: "测量",
        x_nav_analysis: "分析",
        x_nav_drawings: "标记",
        x_layer_panel: "图层面板",
        x_bookmark_panel: "书签面板",
        x_volume_panel: "体积管理面板",
        x_model_panel: "模型面板",
        x_drawing_panel: "标注面板",
        x_pms_panel: "PMS面板",
        x_nav_setting: "设置",
        x_nav_screen: "屏幕",
        x_compress_screen: "正常",
        x_nav_transparency: "透明度",
        x_timeline_play_4d: "播放",
        x_timeline_pause_4d: "暂停",
        x_timeline_loading: "正在加载时间线",
        x_timeline_no: "当前项目图层未找到时间线",
        x_pt_styling: "点云上色",
        x_pt_point_size: "点大小",
        x_pt_class_filter: "按分类显示",

        x_query_buffer_define_size: "设置缓冲区半径",

        x_media_info: "多媒体信息",
        x_properties: "属性",
        x_layer_setting: "图层设置",
        x_zoom_to: "缩放至",
        x_nav_review: "审核",
        x_nav_output: "输出",
        x_nav_effects: "效果",
        x_nav_animation: "动画",
        x_add_in_module: "插件",
        x_nav_2d_drawings: "2维 画笔",
        x_nav_3d_drawings: "3维 画笔",
        x_nav_terrain_analysis: "地形分析",
        x_nav_line_of_site: "视觉分析",
        x_nav_sectional_analysis: "剖面分析",
        x_nav_shadow_analysis: "阴影分析",

        x_nav_3d_particle: "粒子效果",
        x_nav_3d_particle_fire: "火焰",

        x_batch_delete: "批量删除",
        x_selected_resource: "选中资源",
        x_signup_repwd: "再次输入密码",
        x_signup_repwd_error: "再次输入密码与之前密码不一致",
        x_signup_phone_error: "请输入正确的手机号",
        x_status: "状态",
        x_invoke_number: "调用次数",
        x_user_disable: "禁用",
        x_user_normal: "正常",
        x_create_date: "创建时间",
        x_role_name: "角色名称",
        x_remark: "备注",
        x_role_access: "访问权限",
        x_layer_name: "图层名称",
        x_layer_url: "图层地址",
        x_layer_type: "图层类型",
        x_layer_category: "图层类别",
        x_service_publish: "服务图层发布",
        x_data_publish: "本地数据发布",
        x_publish_datasource_tip: "Excel, Esri shapefile, GeoJSON......",
        x_datasource_publish: "数据源发布",
        x_clone_layer_publish: "发布现有图层",
        x_title: "名称",
        x_name: "名称",
        x_date: "日期",
        x_owner: "所有者",
        x_desc: "描述",
        x_type: "类型",
        x_parent_dept: "上级部门",
        x_comfirm_deletion: "确认是否删除此 {0} {1}",
        x_user: "用户",
        x_existing_layer: "已有图层",

        x_init_gis_publish: "新建GIS资源",
        x_init_gis_tip: "二/三维 点/线/面",
        x_geometry_type: "类型",
        x_shp_point_2d: "二维点",
        x_shp_linestring_2d: "二维线",
        x_shp_polygono_2d: "二维面",
        x_shp_circle_2d: "二维圆",
        x_shp_point_3d: "三维点",
        x_shp_linestring_3d: "三维线",
        x_shp_polygon_3d: "三维面",

        x_init_iot_publish: "新建IoT设备图层资源",
        x_init_ai_photogram: "AI Photogrammetry",

        x_image_loading: "正在加载相片",
        x_viewer_loading: "正在加载三维视图",

        x_share_bkmk_copied: "分享书签地址已复制到剪切板",
        x_home_success: "设置起始位置成功",
        x_kml_invalid: "请上传正确的KML文件",
        x_csv_invalid: "请上传正确的CSV文件",
        x_no_delete_root_folder: "不可以删除根目录",

        x_viewport: "视点",
        x_viewport_category: "视点分类",
        x_viewport_mgmt: "视点管理",
        x_viewport_goto: "进入视点",
        x_viewport_detailed_info: "视点信息",
        x_viewport_info: "信息",
        x_alert: "警告",
        x_alert_message: "收到警告消息",
        x_alert_info: "警告信息",
        x_viewport_scheduled: "安排处理",
        x_viewport_completed: "完成",
        x_viewport_category_add: "添加视点分类",
        x_viewport_add: "添加视点",
        x_viewport_category_name: "视点名称",
        x_viewport_state: "状态",
        x_viewport_play_ctrl: "播放控制",
        x_viewport_comment_pane: "评论区",

        x_generate_share_link: "正在生成分享链接",
        x_back: "返回",
        x_share_link: "分享链接",
        x_viewport_type: "视点类型",
        x_viewport_animated: "录制",
        x_viewport_recording: "系统正在录制视点，点击右侧‘停止’按钮结束",
        x_copy_to_clipboard: "复制到剪贴板",

        x_home_management: "管理",
        x_home_query: "查询",
        x_temporary_layer: "临时图层",
        x_upload_obj: "上传 OBJ",
        x_upload_kml: "上传 KML",
        x_upload_geojson: "上传 Geojson",
        x_upload_jpg: "上传 JPG",
        x_upload_gltf: "上传 GLTF",
        x_upload_csv_point: "上传 CSV点文件",
        x_upload_csv_path: "上传 CSV路径文件",
        x_upload_bim_osgb: "上传 BIM (osgb)",
        x_upload_batch: "批量上传",
        x_measurement_point: "点测量",
        x_ms_distance: "距离测量",
        x_ms_perimeter: "周长测量",
        x_free_polyline: "线长度",
        x_dvh: "水平垂直距离",
        x_vertical_ground: "高度差",
        x_distance_xyz: "XYZ坐标差",
        x_perp_reference_line: "点线距离",
        x_arc_3_digit: "弧长",
        x_measurement_area: "面积测量",
        x_free_area: "手动三维面",
        x_vert_rect_diagonal: "对角线成垂直矩形",
        x_vert_rect_3pts: "三点成垂直矩形",
        x_vert_rect_intersect: "拉伸成垂直矩形",
        x_horiz_rect: "水平矩形",
        x_horiz_sqrt_area_ground: "矩形投影",
        x_free_area_ground: "多边形投影",
        x_horiz_circle_ctr_radius: "圆心半径成水平圆",
        x_horiz_circle_3d: "三点成水平圆",
        x_measurement_volume: "体积测量",
        // x_ms_volume: 'Volume',
        x_cut_fill: "填挖方",
        x_export: "导出",
        x_export_shp: "导出为 Shapefile",
        x_export_kml: "导出为 KML",
        x_export_csv: "导出为 CSV",

        x_icon: "图标",
        x_design_view: "绘制设计",
        x_anno_icon: "图标",
        x_anno_tag: "注释标签",
        x_nav_text: "文字",
        x_anno_site_formation: "压平地块",
        x_nav_water: "水面",
        x_drawing_circle: "圆形",
        x_drawing_polyline: "线",
        x_drawing_symbol: "标识",
        x_drawing_arrow: "箭头",
        x_drawing_blank_indicator: "空指示牌",
        x_drawing_text_indicator: "文字指示牌",
        x_drawing_text: "文本",
        x_drawing_list: "绘制列表",
        x_list: "列表",
        x_list_mode: "列表模式",
        x_inventory_mode: "清单模式",
        x_new_draw: "新建绘制",
        x_drawings: "绘制",
        x_design_management: "绘图管理",
        x_collaboration: "协作",
        x_new_viewport: "新建视点",
        // x_new_viewport_group: '新建视点组',
        // x_viewport_group: '视点组',
        x_new_viewport_group: "新建4D视点",
        x_viewport_group: "4D视点",
        x_new_viewport_category: "新建视点分类",
        x_new_status: "新增视点状态",
        x_tracking: "跟踪",
        x_table_of_content: "目录",
        x_select_date: "选择日期",

        x_resource_mgmt: "资源管理",
        x_security_mgmt: "安全管理",
        x_use_statistics: "使用统计",
        x_user_pref: "用户设置",
        x_civil_tools: "土木工具",
        x_planning: "规划",
        x_planning_design: "规划设计",
        x_sketch_design: "草案设计",
        x_design_scheme: "设计方案",
        x_rdms_panel: "RDMS",
        x_3dms_panel: "3DMS",
        x_mobile_app: "移动端",
        x_fms_panel: "FMS",
        x_novade: "Novade",
        x_bim_plus: "3DMS+",
        x_timeline_panel: "时间线",
        x_timeline_4d: "播放4D模拟",
        x_bim360: "BIM360",
        x_mms_panel: "MMS",
        x_orbit: "Orbit",

        x_stat_daily_view: "日访问统计",
        x_stat_proj_view_title: "项目访问",
        x_stat_wks_view_real_title: "工作空间访问（注册用户）",
        x_stat_wks_view_anon_title: "工作空间访问（匿名用户）",
        x_stat_views_time: "次访问",
        x_stat_views_time_real: "次注册用户访问",
        x_stat_views_time_anon: "次匿名访问",

        x_processing_tasks: "任务",
        x_msg_list: "消息列表",
        x_menu_my_catalog: "我的目录",
        x_menu_storage: "存储空间",
        x_menu_share: "分享",
        x_menu_resources: "资源",
        x_menu_workspaces: "工作空间",
        x_menu_viewports: "视点",
        x_menu_company_catalog: "公司目录",
        x_menu_users_teams: "用户与团队",
        x_menu_account: "账户",
        x_menu_my_profile: "我的信息",
        x_menu_my_settings: "我的设置",
        x_menu_my_massage: "我的消息",
        x_menu_company_profile: "公司信息",
        x_menu_plan_profile: "套餐信息",
        x_menu_statistics_dashboard: "统计表",
        x_menu_more: "更多",
        x_menu_contact: "联系我们",
        x_menu_pricing: "价格",
        x_menu_help_center: "帮助中心",
        x_home: "首页",
        x_product_features: "产品特色",
        x_function_introduction: "功能介绍",
        x_classic_case: "经典案例",
        x_customer: "我们的客户",
        x_control_console: "控制台",
        x_free_try: "免费试用",

        x_features_slogan_1: "浏览器内存动态优化及缓存加速技术",
        x_features_slogan_2: "BIM & GIS空间大模型轻量化算法",
        x_features_slogan_3: "更安全更流畅的可计算矢量瓦片技术",
        x_features_slogan_4: "基于WebGPU的并行AI计算，空间分析和光影渲染能力",
        x_features_introduce_1: "提升浏览器性能的关键技术之一。 这些技术通过智能管理内存资源和优化缓存机制，提供更顺畅、更快速的网页浏览体验。",
        x_features_introduce_2: "旨在压缩和优化大型建筑和地理数据模型，提高处理效率和速度。 这些算法简化模型并保持关键属性，促进高效的项目设计和数据共享。",
        x_features_introduce_3: "可计算矢量切片技术提供了一种更安全、更无缝的地图显示方法。它基于矢量数据，实时计算和渲染地图，提供改进的交互性和性能。",
        x_features_introduce_4: "基于WebGPU的并行AI计算、空间分析和光影渲染能力结合了高效的网页环境和先进的算法，提供了强大的计算和渲染功能。",
        x_function_nav_1: "三维空间可视化",
        x_function_nav_2: "数据标绘和管理",
        x_function_nav_3: "场景交互",
        x_function_nav_4: "空间分析",
        x_function_nav_5: "场景特效",
        x_classic_ases_name_1: "通过现实捕捉和CIM进行智能项目和城市规划或更新",
        x_classic_ases_name_2: "智能站点监控仪表板",
        x_classic_ases_name_3: "完成建筑工程检查",
        x_classic_ases_name_4: "检查外部状况",
        x_classic_ases_name_5: "村屋 UBWs 管理",
        x_classic_ases_name_6: "遗产管理和检查",
        x_Contact: "联络我们",
        x_contact_phone_number: "(0755) 2690 6260",
        x_contact_email: "geotwin@geotwin.cn",
        x_contact_location: "广东省深圳市福田保税区市花路32号香港科学园深圳分园A栋1楼235室",

        x_address: "地址",
        x_website: "网站",
        x_phone: "电话",
        x_mobile: "手机",
        x_fax: "传真",
        x_setup: "设置",
        x_theme_blue: "蓝",
        x_theme_green: "绿",
        x_theme_red: "红",
        x_theme_purple: "紫",
        x_theme_dark_blue: "294C",
        x_theme_dark_pro: "深黑",
        x_theme_dark: "暗色",
        x_theme_light: "明亮",
        x_theme: "主题",
        x_my_preferences: "我的设置偏好",
        x_company_preferences: "公司设置偏好",
        x_data_processing: "数据处理",
        x_refresh_data: "刷新数据",
        x_refresh_status: "刷新状态",
        x_change_profile_img: "更换用户头像",
        x_change_company_logo: "更换公司Logo",
        x_create_new_user: "创建新用户",
        x_create_new_team: "创建新团队",
        x_create_new_project: "创建新项目",
        x_create_new_workspace: "创建新工作空间",
        x_create_new_resource: "创建新资源",
        x_resource_index_map: "资源索引地图",
        x_signup_invite_code: "邀请码(选填)",
        x_user_unactivated: "未激活",
        x_view: "查看",
        x_renew: "重置",
        x_copy: "复制",
        x_rename: "重命名",
        x_rename_tip: "请输入英文字符或数字",
        x_moveto: "移动",
        x_duplicate: "复制",
        x_users_in: "用户在",
        x_resources_in: "资源在",
        x_renew_sharecode: "重置分享码",
        x_opened_modules: "开放功能",
        x_in_team: "在团队中",
        x_in_project: "在项目中",
        x_in_company: "在公司中",
        x_in_workspace: "在工作空间中",
        x_enter_ws_pwd: "请输入工作空间密码",
        x_file_name: "文件名",
        x_move_to_header: "移动到",
        x_copy_to_header: "复制到",
        x_unzip_to_header: "解压到",
        x_open: "打开",
        x_role_user: "普通用户",
        x_guest: "访客",
        x_role_dm: "数据管理员",
        x_role_pm: "项目管理员",
        x_role_admin: "系统管理员",
        x_role_superadmin: "超级管理员",
        x_share_code: "分享码",
        x_size: "大小",
        x_table_header_progress: "进度",
        x_table_header_messgae: "消息",
        x_table_header_expire_date: "过期时间",
        x_table_header_share_with: "分享与",
        x_publish_service_tip:
            "URL Template Imagery Layer",
        x_publish_data_tip:
            "Media;Point Cloud (las);OSGB;3D Tiles......",
        x_please_contact: "请联络",
        x_choosed: "已选择",
        x_private: "仅自己可见",
        x_encrypt: "加密",
        x_privacy: "可见设置",
        x_public: "公开",
        x_data_source: "数据源",
        x_fill_all_fields: "请填写所有栏",
        x_next: "下一步",
        x_region: "地区",
        x_product_plan: "套餐",
        x_max_online_users: "同时在线用户限制",
        x_gen_share_link: "生成分享链接",
        x_share_link_clipboard: "分享链接已复制",
        x_share_pwd: "分享密码已复制",
        x_qrcode: "二维码",
        x_loading_qrcode: "下载二维码",
        x_root_folder_not_operable: "根目录不可操作",
        x_visit: "访问",
        x_workspace_share: "工作空间分享",
        x_username_or_teamname_input: "用户或团队名",
        x_layer_property: "图层属性",
        x_default_open: "默认打开",
        x_structure: "结构",
        x_preview: "预览",
        x_add_tag: "添加标签",
        x_tag: "标签",
        x_append_tag: "追加标签",
        x_tag_search: "标签搜索",
        x_remove_tag: "移除标签",
        x_specify_scene: "默认场景",
        x_specify_dashboard: "默认仪表盘",
        x_preview_these_resource: "预览这些资源",
        x_add_layer_group: "加图层组",
        x_close_preview: "关闭预览",
        x_unsupport_notice:
            "此类型数据暂不支持自动发布。提交发布请求之后支持人员将与您联系。",
        x_layer_preview_tip:
            "拖动图层修改顺序; 点击图层修改图层名; 点击+号添加图层组",
        x_query_service_url: "数据服务查询语句",
        x_not_selectable: "不可选择",
        x_enable_query: "开启查询",

        x_layer_s3m: "S3M",
        x_open_failed: "打开失败",

        x_attr_exp_num_rec_str: "编辑失败。该字段只支持数字数值",
        x_shp_feat_geom_edit: "编辑形状",
        x_shp_feat_geom_delete: "删除要素",

        x_high: "高",
        x_low: "低",
        x_alert_str: "{0}录得{1}{2}读数： {3}",

        /*
          x_media: '媒体',
          x_ptc_las: '点云（las文件）',
          x_geotiff: 'GeoTiff 栅格',
          x_panorama: '全景照片',
          x_3dtiles: '3D Tiles 文件',
          x_trajectory_csv: '轨迹（csv文件）',
          x_kml: 'KML文件',
          x_obj: 'OBJ三维模型',
          x_shp: 'ESRI shapefile',
          x_file: '文件管理',
          x_bentley_dgn: 'DGN文件',
          x_dxf_dwg: 'DXF/DWG文件',
          x_collada_dae: 'DAE三维模型',
          x_revit: 'RVT文件',
          x_gsm_csv: '点文件(csv)',
        */
        x_arcgis_server_map: "ESRI地图服务",
        x_supermap_3d: "超图三维服务",
        x_url_template: "URL模板影像",
        x_wms_layer: "WMS服务",
        x_wmts_layer: "WMTS服务",

        x_workspace_template: "工作空间界面模板",
        x_proj_template: "项目界面模板",
        x_sdms: "SDMS默认项目",
        x_lite: "SDMS精简界面",
        x_plan_template: "规划设计模板",
        // x_iot_prototype: 'IoT控制大屏(演示)',
        x_iot_prototype: "IoT控制大屏",
        x_t2_portal_prototype: "T2大平台",
        x_dms: "DMS",

        x_iot_ui_cust: "界面定制",
        x_iot_enter_cust: "进入定制视图",

        x_system: "系统",

        x_pricing_free: "免费版",
        x_pricing_std: "标准版",
        x_pricing_adv: "高级版",
        x_pricing_pro: "专业版",
        x_pricing_ult: "旗舰版",
        x_edi_pms_lite: "轻量影像管理",
        x_edi_pms: "影像管理",
        x_edi_bim: "BIM & GIS",
        x_media: "媒体",
        x_ptc_las: "点云（las文件）",
        x_geotiff: "GeoTiff 栅格",
        x_geojson: "Geojson",
        x_ifc: "IFC",
        x_panorama: "全景照片",
        x_photo: "照片",
        x_3dtiles: "3D Tiles 文件",
        x_3dtiles1: "3D Tiles",
        x_trajectory_csv: "轨迹（csv文件）",
        x_kml: "KML文件",
        x_obj: "OBJ三维模型",
        x_shp: "ESRI shapefile",
        x_file: "文件管理",
        x_bentley_dgn: "DGN文件",
        x_dxf_dwg: "DXF/DWG文件",
        x_collada_dae: "DAE三维模型",
        x_revit: "RVT文件",
        x_gsm_csv: "点文件(csv)",
        x_dem_geotiff: "地形 (GeoTiff)",
        x_video: "视频",
        x_plan: "规划",
        x_design_scheme_project_list: "项目列表",
        x_design_scheme_create_new: "创建设计",
        x_design_scheme_project_name: "项目名称",
        x_design_scheme_last_update: "最后更新",
        x_design_scheme_option_name: "设计名称",
        x_design_scheme_step_1: "第一步",
        x_design_scheme_step_2: "第二步",
        x_design_scheme_step_3: "第三步",
        x_design_scheme_step_4: "第四步",
        x_design_scheme_step_5: "第五步",
        x_design_scheme_step_6: "第六步",
        x_scheme_management: "设计方案管理",

        x_design_scheme_site_boundary: "设计地块范围",
        x_design_scheme_height_restriction: "限高（海拔米）",
        x_design_scheme_area: "地块面积（平方米）",
        x_design_scheme_max_dom_pr: "最大允许住宅容积率",
        x_design_scheme_site_level: "地块高度（海拔米）",
        x_design_scheme_max_nondom_pr: "最大允许非住宅容积率",
        x_design_scheme_storey_restriction: "楼层限制",
        x_design_scheme_site_coverage: "最大占地率（百分比）",
        x_design_scheme_dom_pr: "住宅总容积率",
        x_design_scheme_nondom_pr: "非住宅总容积率",
        x_design_scheme_composite_formula: "使用混合地块容积率计算公式",
        x_design_scheme_podium_pr: "平台层容积率",
        x_design_scheme_nondom_tower_pr: "非住宅楼容积率",

        x_outside_site: "绘制超出地块边界",
        x_collide_nba: "绘制与无建筑区冲突",
        x_collide_block: "绘制与现有设计建筑冲突",

        x_dom_concession: "Domestic concession (%)",
        x_nondom_concession: "Non-domestic concession (%)",
        x_dom_floor_height: "住宅楼层高(m)",
        x_nondom_floor_height: "非住宅楼层高(m)",

        x_select_site: "选择地块",
        x_all: "全部",

        x_design_scheme_domestic: "住宅楼",
        x_design_scheme_podium: "平台层",
        x_design_scheme_nondom: "非住宅楼",
        x_design_scheme_floor_height: "楼层高度",
        x_design_scheme_lobby_height: "大堂楼层高度",
        x_design_scheme_refuge_height: "防火层高度",
        x_design_scheme_gfa_concession: "GFA Concession(%)",
        x_design_scheme_tower_group_no: "设计分组数",
        x_design_scheme_use_up_podium: "用尽平台层容积率",
        x_design_scheme_draw_podium: "绘制平台层",

        x_design_scheme_tower_type: "楼型种类",
        x_design_scheme_tower_height_restriction: "建筑限高（海拔米）",
        x_design_scheme_tower_no: "建筑数",
        x_design_scheme_flat_size: "平均住宅面积（平方米）",
        x_design_scheme_flat_no: "每层住宅数",
        x_design_scheme_footprint: "层建筑面积(平方米)",
        x_design_scheme_build_on: "建筑位置",
        x_design_scheme_build_form: "建筑形状",
        x_design_scheme_site: "地块地面",
        x_design_scheme_podium_residual: "额外平台层",
        x_design_scheme_dom_block_type: "住宅楼类",
        x_design_scheme_nondom_block_type: "非住宅楼类",
        x_design_scheme_geom_block_type: "基本形状类",
        x_design_select_footprint: "选择建筑形状：",

        x_design_scheme_avail_towers: "剩余建筑物",
        x_design_scheme_typ_floor: "普通楼层数",
        x_design_scheme_ref_floor_count: "防火楼层数",
        x_design_scheme_lobby_floor_count: "大堂楼层数",
        x_design_scheme_total_floor_count: "总楼层数",
        x_design_scheme_flat_no_tower: "每栋楼单位数",
        x_design_scheme_tower_height: "楼高",
        x_design_scheme_tower_mpd: "海拔高",
        x_design_scheme_floor_no_pod: "楼层数",
        x_design_scheme_footprint_pod: "平台层建筑面积",
        x_design_scheme_gfa_pod: "总建筑面积",
        x_design_scheme_do_name: "方案名称:",
        x_project_design_scheme: "设计方案绘制",
        x_save_as: "另存为",
        x_previous: "上一步",

        // IoT and IoT template related (device/chart management)
        x_add_chart: "新建图表",
        x_chart: "图表",
        x_device: "设备",
        x_form: "表格",
        x_table: "表格",
        x_monitor: "监视器",
        x_device_info: "设备信息",
        x_alert_level: "预警级别",
        x_device_name: "设备名称",
        x_generic: "通用",
        x_chart_type: "图表类型",
        x_chart_line: "折线图",
        x_chart_bar: "柱状图",
        x_chart_pie: "饼状图",
        x_chart_gauge: "仪表盘",
        x_reading_column: "读数字段",
        x_time_column: "时间字段",
        x_data_column: "数据字段",
        x_series_column: "系列字段",
        x_time_type: "显示时间范围",
        x_time_range: "指定日期范围",
        x_time_last: "近期",
        x_prev_days: "最近天数",
        x_chart_line_color: "图表颜色",
        x_max_val: "最大值",
        x_min_val: "最小值",
        x_set_unit: "设置单位",
        x_set_fontsize: "设置字体大小",
        x_real_time_img: "实时影像",
        x_is_iot_link_layer: "与IoT图层绑定",
        x_link_layer: "绑定图层",
        x_metadata: "元数据",
        x_attr: "属性",
        x_rec: "记录",
        x_warn_rule: "预警规则",
        x_alert_source: "预警来源",
        x_alert_alias: "预警别名",
        x_alert_value: "预警数值",
        x_alert_log: "预警日志",
        x_base_info: "基本信息",
        x_flie_info: "文件信息",
        x_correct_longitude: "请输入正确的经度",
        x_correct_latitude: "请输入正确的纬度",
        x_update_time: "更新时间",
        x_update_frequency: "更新频率",
        x_update_frequency_tip:
            "在此设置更新频率时，会将该类别下所有设备的更新频率批量设置",

        x_text: "文本",
        x_num: "数字",
        x_attachment: "附件",
        x_time: "时间",
        x_int: "整数",
        x_bigint: "大整数",
        x_float: "浮点数",

        x_col_name: "字段物理名",
        x_col_alias: "字段显示名",
        x_col_type: "字段类型",
        x_value: "值",

        x_col_condition: "报警条件",
        x_col_value: "报警值",
        x_col_level: "报警等级",
        x_col_memo: "备注",

        x_col_max_val: "最大值",
        x_col_min_val: "最小值",

        x_visible_all: "所有人可见",
        x_admin_check_user_count: "当前系统在线人数",
        x_company_check_user_count: "当前公司在线人数",

        x_download_model: "下载模板",
        x_pilot_management: "试点管理",
        x_operation_panel: "操作面板",
        x_comfirm_delete: "是否确认删除该字段",
        x_my_file: "我的文件",
        x_all_file: "全部文件",
        x_file_detail: "文件详情",
        x_folder_content: "文件夹内容",
        x_storage_info_tip: "选择文件，查看详情",
        x_storage_info_tip2: "此文件夹为空",
        x_go_back: "返回上一级",
        x_move_to: "移动到",
        x_folder: "文件夹",
        x_unzip: "解压",
        x_unzip_to: "解压到",
        x_copy_to: "复制到",
        x_move_tip: "不能将文件移动到自身或其子目录下",
        x_copy_tip: "不能将文件复制到自身或其子目录下",
        x_upload_list: "文件上传列表",
        x_upload_list_tip: "仅展示本次上传任务",

        x_my_resources: "我的资源",
        x_my_participation: "我参与的",
        x_view_type: "视图类型",
        x_creator: "创建人",
        x_date_range: "日期范围",
        x_create_date_range: "创建数据日期范围",
        x_capture_date_range: "采集数据日期范围",
        x_reverse: "颠倒",
        x_detail: "详情",
        x_detail_view: "查看详情",
        x_visibility: "权限",
        x_add_component: "添加组件",
        x_content_properties: "内容属性",
        x_text_number: "文字(数字)",
        x_text_color: "文字颜色",
        x_target_url: "目标url",
        x_document: "文档",
        x_advance_setting: "高级设置",
        x_chart_attribute_editor: "图标属性编辑器",
        x_border: "边框",
        x_border_style: "边框样式",
        x_border_thickness: "边框粗细",
        x_border_color: "边框颜色",
        x_main_color: "主色",
        x_sub_color: "辅色",
        x_border_background: "边框与背景",
        x_background_picture: "背景图片",
        x_background_color: "背景颜色",
        x_background_gaussian_blur: "背景高斯模糊",
        x_confirm_add: "确认添加",
        x_text_size: "文字大小",
        x_video_stream_properties: "视频流属性",
        x_video_stream_preview_properties: "视频流预览属性",
        x_document_select_message: "请至少选择一个文档",

        x_tags_add_message: "标签名称不能为空",

        x_ungroup_document_select_message: "请从未分组区至少选择一个文档",
        x_document_alias: "文档别名",
        x_picture: "图片",
        x_cancel_option: "取消选项",
        x_my_star: "我的收藏",
        x_star: "收藏",
        x_cancel_star: "取消收藏",
        x_login_site_title: "登录 GeoTwin 平台",
        x_pwd_reset: "密码重置",
        x_login_with: "登录方式",
        x_forget_pwd_tip:
            "如果您忘记了密码，请在下面输入您帐户的电子邮件地址，然后单击“提交”按钮。您将收到一封包含新密码的电子邮件。",
        x_forget_pwd_tip2: "密码重置，请检查您的电子邮件以获取新密码。",
        x_sign_up_title: "注册 GeoTwin 平台",
        x_back_to_login: "返回登录界面",
        x_send_verification_code: "发送验证码",
        x_submit_verification_code: "提交验证码",
        x_verification_code: "验证码",
        x_send_again: "重新发送",
        x_send_verification_code_tip: "验证码已经发送至您的邮箱！",
        x_invite_user: "邀请用户",
        x_link_invitation: "链接邀请",
        x_email_invitation: "邮件邀请",
        x_click_copy: "点击复制",
        x_invite_user_input_tip:
            "请选择用于接收邀请的用户 (用户名或邮箱均可搜索)",
        x_invite_email_input_tip:
            "请选择用于接收邀请的邮箱 (用户名或邮箱均可搜索)",
        x_email: "邮箱",
        x_send_invitation: "发送邀请",
        x_copy_link: "复制链接",
        x_copy_link_tip: "复制链接成功",
        x_comfirm_remove: "确认是否将 {0} {1} 移出本公司？",
        x_generate_link: "生成链接",
        x_validate_tip: "请先选择角色",
        x_public_resource: "公开资源",
        x_dashboard: "仪表盘",
        x_create_new_template: "创建新模板",
        x_projects: "项目",
        x_projects_overview: "项目概览",
        x_settings: "设置",
        x_load_tip: "已加载全部，共 {total} 个",
        x_overview: "资产概览",
        x_members: "成员",
        x_company: "公司",
        x_online_help: "在线帮助",
        x_feedback: "反馈",
        x_log_out: "注销",
        x_faq: "常见问题",
        x_account: "个人账户",
        x_admin: "系統管理",
        x_space_domain: "空间域名",
        x_space_capacity: "空间容量",
        x_workbench: "工作台",
        x_company_members: "公司成员",
        x_freq: "次",
        x_delete_confirm: "确认删除所选的文件么？",
        x_month: "月份",
        x_add_resource: "添加资源",
        x_add_layer: "添加图层",
        x_create_layer: "创建新图层",
        x_lock: "锁定",
        x_chart_tip: "日期范围不可以是同一天",
        x_custom: "自定义",
        x_event: "事件",
        x_daily_visit: "每日访问",
        x_workspace_visit: "工作空间访问",
        x_real_visit: "实名访问",
        x_anon_visit: "匿名访问",
        x_process: "处理",
        x_defect: "缺陷",
        x_defect_type: "缺陷类型",
        x_refresh: "刷新",
        x_save_preview: "保存封面",
        x_by_type: "按类型",
        x_by_user: "按用户",
        x_by_time: "按时间",
        x_downloading: "下载中...",
        x_downloaded: "已下载",
        x_save_preview_success: "保存封面成功",
        x_project_document: "项目文档",
        x_length_q_s: "长度",
        x_perimeter: "周长",
        x_area_area: "区域面积",
        x_area_sqm_dot: "m²",
        x_angle_angle: "角的角度",
        x_angle_angle_angle: "°",
        x_domain_name: "域名",
        x_company_name: "公司名称",
        x_domain_name_empty_err: "域名不能为空",
        x_domain_name_invlidate: "域名所含字符不正确",
        x_retrieve_info_feature: "检索地图要素的详细信息",
        x_start_ident: "开始点选",
        x_start_ident_point: "按点",
        x_start_ident_line: "按线",
        x_start_ident_polygon: "按多边形",
        x_start_ident_circle: "按圆形",
        x_cancel_ident: "取消点选",
        x_danger_zone: "危险操作",
        x_remove_project: "删除项目",
        x_remove_project_tip: "删除项目将会连同其相关的所有数据一起删除。",
        x_remove_project_tip2: "此操作无法恢复！",
        x_remove_project_verify_title: "敏感操作验证",
        x_remove_project_tip3: "你正在删除项目",
        x_project_name_verify_err: "项目名称验证错误",
        x_enter_project_name_verify: "请输入项目名称验证",
        x_assets_category: "资产类别",
        x_add_category: "新增资产",
        x_add_asset_category: "新增资产类别",
        x_add_device: "新增设备",
        x_iot: "物联监控",
        x_cctv: "视频监控",
        x_worker: "人员",
        x_gender: "性别",
        x_vehicle: "车辆",
        x_personnel: "人员",
        x_car_model: "型号",
        x_car_plate: "牌照",
        x_upload_batch_device: "批量上传设备",
        x_method: "方式",
        x_step: "步骤",
        x_setup_category: "设置类别",
        x_category_name: "类别名称",
        x_record: "记录",
        x_record_attr: "记录属性",
        x_record_Info: "记录信息",
        x_upload_draft: "上传图纸",
        x_draft_manage: "图纸管理",
        x_draft_info: "图纸信息",
        x_login_title: "登录标题",
        x_login_background: "登录背景",
        x_validate_invlidate: "内容格式不正确",
        x_days: "天",
        x_validity: "有效期",
        x_validity_tip: "诶呀，来晚了，该分享链接已失效！",
        x_pwd_label: "请输入工作空间密码",
        x_permanent: "永久有效",
        x_report: "报告",
        x_report_name: "报告名称",
        x_company_private: "仅本公司可见",
        x_company_power_tip: "抱歉，无权限访问该链接！",
        x_draw_entity: "绘制元素",
        x_add_to_resource: "添加至资源",
        x_upload_icon: "上传图标",
        x_geojson: "Geojson",
        x_add_to_workspace: "添加至工作空间",
        x_device_in_Workspace: "设备所属工作空间",
        x_device_in_project: "设备所属项目",
        x_device_in_scene: "设备所属场景",
        x_dashboard_logo: "大屏标志",
        x_dashboard_theme: "大屏主题",
        x_add_to_project: "添加至项目",
        x_remove_from_project: "移除出项目",
        x_add_to_scene: "添加至场景",
        x_create_new_dashboard: "创建大屏",
        x_template_management: "模板管理",
        x_empty_template: "空白模板",
        x_edit_dashboard: "配置大屏",
        x_change_hub: "切换公司",
        x_function: "功能",
        x_osgb: "OSGB",
        x_code_tip: "扫码关注 Geosys 公众号",
        x_project_preview: "项目封面",
        x_location: "位置",
        x_icon_type: "图标类型",
        x_monitoring_management: "监控管理",
        x_monitoring_wall: "监控墙",
        x_add_monitoring_wall: "新增监控墙",
        x_map: "地图",
        x_add_to_monitoring_wall: "添加至监控墙",
        x_cctv_add: "添加",
        x_cctv_remove: "移除",
        x_return_to_list: "返回列表",
        x_richtext_tip: "点击编辑文本",
        x_project_menu: "项目菜单",
        x_minimize: "最小化",
        x_restore: "还原",
        x_device_detail: "设备详情",
        x_alert_detail: "警告详情",
        x_comparison: "比较",
        x_add_marker: "新增标识",
        x_add_Measurement: "新增测量",
        x_marker_name: "标识名称",
        x_initial: "初始信息",
        x_initial_date: "初始日期",
        x_initial_reading: "初始读数",
        x_measure_date: "测量日期",
        x_level: "高度",
        x_diff_initial: "初始值差异",
        x_diff_previous: "上一个差异",
        x_last_reading_date: "最新读取日期",
        x_last_reading: "最新读取",
        x_last_diff_initial: "最新初始值差异",
        x_alarm: "警告",
        x_team_name: "团队名称",
        x_team_members: "团队成员",
        x_team_menu: "团队菜单",
        x_add_team_member: "添加团队成员",
        x_username_input: "用户",
        x_iot_category: "物联监控类别",
        x_cctv_category: "CCTV 类别",
        x_worker_category: "工人类别",
        x_vehicle_category: "车辆类别",
        x_generic_alert: "物联监控预警",
        x_personnel_alert: "工人预警",
        x_vehicle_alert: "车辆预警",
        x_cctv_alert: "CCTV预警",
        x_generic_list: "物联监控列表",
        x_personnel_list: "工人列表",
        x_vehicle_list: "车辆列表",
        x_cctv_list: "CCTV列表",
        x_view_monitoring: "查看监控",
        x_iot_management: "物联监控管理",
        x_cctv_management: "视频监控管理",
        x_worker_management: "工人管理",
        x_vehicle_management: "车辆管理",
        x_datepicker: "日期选择器",
        x_button: "按钮",
        x_dropdown: "下拉列表",
        x_query_result: "查询结果",
        x_lower_limit: "下限值",
        x_upper_limit: "上限值",
        x_subscribe: "订阅",
        x_unsubscribe: "取消订阅",
        x_task_name: "任务名称",
        x_model: "模型",
        x_run: "执行",
        x_subscribe_attribute: "订阅属性",
        x_upper_clothes: "上装",
        x_lower_clothes: "下装",
        x_upper_clothes_color: "上装颜色",
        x_lower_clothes_color: "下装颜色",
        x_hat: "帽子",
        x_vest: "背心",
        x_human_detection: "人体检测",
        x_vehicle_detection: "车辆检测",
        x_cross_region_detection: "跨区域检测",
        x_man_number_detection: "区域人数检测",
        x_step_size: "步长",
        x_zone_name: "区域名称",
        x_zone_detail: "区域详情",
        x_zone: "区域",
        x_upload_zone: "上传区域",
        x_overview_zone_management: "区域管理",
        x_zone_list: "区域列表",
        x_zone_category: "区域类别",
        x_ai_event: "事件",
        x_alert_center: "预警中心",
        x_push_system: "推送系统",
        x_push_email: "推送邮件",
        x_push_whatsapp: "推送 WhatsApp",
        x_push_time: "推送时间",
        x_alert_col: "預警列",
        x_push_staffing: "推送人员配置",
        x_push_personnel: "推送人员",
        x_staffing: "配置",
        x_push_template: "推送模板",
        x_push_template_setting: "推送模板设置",
        x_editor_tip: "请设置推送模板...",
        x_bool: "布尔值",
        x_bool_value: "布尔值",
        x_rule_name: "规则名称",
        x_rule_message_type: "规则信息类型",
        x_rule_message: "规则信息",
        x_rule_message2: "预警消息",
        x_rule: "规则",
        x_add_member: "添加成员",
        x_forbidden_zone: "禁区",
        x_label: "标签",
        x_decorate: "装饰",
        x_decorate_type: "装饰类型",
        x_select_picture: "选择图片",
        x_select_color: "选择颜色",
        x_border_radius: "边框圆角",
        x_label_text: "标签文字",
        x_alignment: "对齐方式",
        x_align_left: "左对齐",
        x_align_right: "右对齐",
        x_align_center: "居中对齐",
        x_font_weight: "字体粗细",
        x_background_size: "背景图大小",
        x_picture_source: "图片来源",
        x_pic_upload: "图片上传",
        x_net_address: "网络地址",
        x_interface_data: "接口数据",
        x_interface_address: "接口地址",
        x_interface_type: "请求方式",
        x_interface_calling_error: "请检查调用参数或联系管理员",
        x_response_data: "响应数据",
        x_pie_chart_setting: "饼图设置",
        x_value_setting: "数值设置",
        x_set_ring_type: "设置为环型",
        x_set_nightingale_rose: "南丁格尔玫瑰",
        x_auto_sort: "自动排序",
        x_not_show_zero: "不展示零",
        x_show_value: "显示数值",
        x_border_width: "边框宽度",
        x_real_time: "实时时间",
        x_time_format: "时间格式",
        x_time_format1: "日期",
        x_time_format2: "日期+时分",
        x_time_format3: "日期+时分秒",
        x_time_format4: "日期(无年)",
        x_time_format5: "时分",
        x_time_format6: "时分秒",
        x_time_format7: "星期",
        x_custom_format: "自定义格式",
        x_font_spacing: "字体间距",
        x_show_widget_windowstate: "显示组件窗口状态",
        x_widget_name: "组件名称",
        x_weather: "天气",
        x_current_weather: "当前天气",
        x_weather_forecast: "天气预报",
        x_display_type: "展示方式",
        x_card: "卡片",
        x_show_weather_warning: "显示天气预警",
        x_show_days: "显示天数",
        x_chart_title: "图表标题",
        x_chart_title_color: "图表标题颜色",
        x_inner_ring_size: "内环大小",
        x_outer_ring_size: "外环大小",
        x_show_legend: "显示图例",
        x_label_position: "标签位置",
        x_device_type: "设备类型",
        x_generic_iot: "通用IOT",
        x_date_type: "日期类型",
        x_user_group: "用户组",
        x_editable: "可编辑",
        x_in_dashboard: "在大屏中",
        x_in_album: "在相册中",
        x_add_to_dashboard: "添加至大屏",
        x_add_to_album: "添加至相册",
        x_no_exceedence: "暂无超出",
        x_geotechnical_instrument: "岩土工程仪器",
        x_geotechnical_instrument_list: "岩土工程仪器列表",
        x_offline: "离线",
        x_online: "在线",
        x_chatroom: "聊天室",
        x_chatroom_name: "聊天室名称",
        x_save_and_enter_chatroom: "保存并进入聊天室",
        x_no_chatroom: "暂无聊天室",
        x_online_people_number: "在线人数",
        x_control: "控制",
        x_point_cloud: "点云",
        x_rendering: "渲染",
        x_chat_enter: "进入聊天室",
        x_chat_leave: "离开聊天室",
        x_currently_online: "当前在线（ {total} 人 ）",
        x_define_animation_track: "定义动画轨迹",
        x_serach_device_name: " 查询设备名称",
        x_select_project: "选择设备所属项目",
        x_device_list: "设备列表",
        x_record_list: "记录列表",
        x_alert_list: "警告列表",
        x_record_data: "记录数据",
        x_last_hour: "近一小时",
        x_last_week: "近一星期",
        x_last_month: "近一个月",
        x_last_three_months: "近三个月",
        x_advanced_search: "高级搜索",
        x_unit: "单位",
        x_with_gps_function: "带有 GPS 功能",
        x_trajectory: "轨迹",
        x_locate: "定位",
        x_add_position: "手动定位",
        x_positioning: "定位中",
        x_smart_solution: "智能方案",
        x_record_field: "记录属性",
        x_record_value: "记录值",
        x_alert_field: "预警字段",
        x_alert_val: "预警值",
        x_download_codemap: "下载设备API码对照表",
        x_selected_project: "已选项目",
        x_add_metadata: "新增元数据",
        x_add_record_attr: "新增记录属性",
        x_add_static_attr: "新增静态属性",
        x_add_dynamic_attr: "新增动态属性",
        x_show_or_not: "是否显示",
        x_media_type: "多媒体类型",
        x_media_normal: "无",
        x_image: "图片",
        x_yes: "是",
        x_no: "否",
        x_cat_generic: "通用设备",
        x_cat_cctv: "监控摄像头",
        x_set_alert_rule: "设置预警规则",
        x_add_alert_rule: "新增预警规则",
        x_alert_rule_for_device: "预警规则生效设备",
        x_trajectory_run: "轨迹运行",
        x_trajectory_tip1: "此日期范围暂无轨迹数据，",
        x_trajectory_tip2: "请选择正确的时间范围。",
        x_speed: "速度",
        x_code: "编码",
        x_api_reference_document: "API 参考文档",
        x_request: "请求",
        x_response: "响应",
        x_request_data_structure: "请求数据结构",
        x_request_parameter_example: "请求参数示例",
        x_response_data_structure: "响应数据结构",
        x_response_example: "响应示例",
        x_request_name: "名称",
        x_request_location: "位置",
        x_request_type: "类型",
        x_request_required: "必选",
        x_request_illustrate: "说明",
        x_request_token_desc: "通过 login 获取",
        x_request_body_desc: "详见 body 参数",
        x_copy_json_text: "请求参数示例已复制",
        x_device_in_use: "使用中设备",
        x_last_1hour: "近1小时",
        x_last_12hour: "近12小时",
        x_last_1day: "近1天",
        x_last_7day: "近7天",
        x_last_1month: "近1月",
        x_last_3months: "近3月",
        x_num_project_device: "项目设备数量",
        x_num_device: "设备数量",
        x_epsg: "坐标系",
        x_offset: "偏移量",
        x_hyperlink: "超链接",
        x_operation_log: "操作日志",
        x_push_telegram: "推送 Telegram",
        x_project_name: "项目名称",
        x_workspace_name: "工作空间名称",
        x_location_search: "位置搜索",
        x_search_placeholder_location:
            "建筑物、地址、地点、地段(e.g.DD 381 Lot 953)",
        Lot: "地段",
        Address: "地址",
        Building: "建筑物",
        Places: "地点",
        x_advanced_search: "高级搜索",
        x_add_to_project: "添加至项目",
        AI_APP_UPLOAD: "图片上传",
        AI_APP_AI_PROCESS: "执行AI",
        New_AI_Processing: "新的人工智能处理",
        AI_APP_AI_RESULT: "AI 结果",
        AI_APP_AI_REPORT: "AI 报告",
        AI_APP_TASK: "AI 任务",
        AI_APP_ACCEPT: "接受",
        AI_APP_TASK_STATUS_ALL: "全部",
        AI_APP_TASK_STATUS_PICTURE: "正在处理图片",
        AI_APP_TASK_STATUS_DATA: "正在处理数据",
        AI_APP_TASK_STATUS_COMPLETE: "完成",
        AI_APP_TASK_CODE: "任务编码",
        AI_APP_TASK_MODEL: "模型",
        AI_APP_ADD_TAGS: "添加标签",
        AI_APP_GENERATE_REPORT: "生成报告",
        x_repair: "维修",
        x_pending: "待定",
        x_normal: "正常",
        x_ignore: "忽视",
        x_is_the_defect: "确是缺陷",
        x_not_sure: "不确定",
        x_not_the_defect: "不是缺陷",
        x_not_confirmed: "待确认",
        x_defect_action: "缺陷行动",
        x_emergency_repair: "紧急维修",
        AI_APP_TASK_MODEL: "模型",
        x_accept: "接受",
        x_solar_illumination: "太阳光照",
        x_ambient_occlusion: "环境光遮蔽",
        x_3d_model_layers: "图层列表",
        x_Basic_Information: "基本信息",
        x_BIM_Statistics: "BIM 统计",
        x_BIM_Model: "BIM 模型",
        x_menu_notice: "消息",
        x_mark_as_readed: "标记为已读",
        x_mark_all_messages_as_readed: "标记全部消息为已读",
        x_view_all: "查看全部",
        x_extract_password: "提取密码",
        x_have_extract_password: "有提取密码",
        x_share_files: "分享文件",
        x_link_sharing: "链接分享",
        x_automatically_generates_extracts_passwords: "系统自动生成密码",
        x_please_enter_the_extraction_password: "请输入提取密码",
        x_create_new_link: "创建链接",
        x_successfully_created_private_link: "成功创建私密链接",
        x_no_extraction_password_set: "未设置提取密码",
        x_link: "链接",
        x_permanent: "永久",
        x_effective: "有效",
        x_day: "天",
        x_lose_efficacy: "失效",
        x_iot: "物联监控",
        x_device_list: "设备列表",
        x_devices: "设备",
        x_products: "产品",
        x_alerts: "警告",
        x_alert_list: "警告列表",
        x_alert_center: "预警中心",
        x_files_folders_selected: " 个文件/文件夹被选中",
        x_register: "注册",
        x_late_arrival_the_file_sharing_has_expired: "来晚了,该文件分享已过期",
        x_after_closing_extraction_code_for_sharing_links: "关闭后分享链接无需填充提取码",
        x_users_click_open_the_shared: "用户点击链接直接打开分享内容",
        x_project_visible: "本项目可见",
        x_company_visible: "本公司可见",
        x_all_visible: "全网可见",
        x_editable: "可编辑",
        x_in_dashboard: "在大屏中",
        x_in_album: "在相册中",
        x_add_to_dashboard: "添加至大屏",
        x_add_to_album: "添加至相册",
        x_yes: "是",
        x_no: "否",
        x_4d_configure: "4D 配置",
        x_add_4d_configure: "新增 4D 配置",
        x_schema_name: "方案名称",
        x_field_name: "字段名称",
        x_execute_odm: "执行 3D 重建",
        x_execute_splat: "执行贴图",
        x_odm_task: "3D 重建任务",
        x_splat_task: "贴图任务",
        x_quality: "质量",
        x_fail: "失败",
        x_comfirm_delete2: "确认删除 {0} 吗？",
        x_download_sample_file: "下载示例文件",
        x_feature_name: "功能名称",
        x_project_user_tips: "没有数据",
        x_model_info: "模型信息",
        x_scale: "比例尺",
        x_pitch: "俯仰",
        x_roll: "滚转",
        x_model_quality: "建模质量",
        x_setting_no_flatten: "不允许压平",
        x_location_Type: "位置类型",
        x_by_coordinates: "按坐标",
        x_by_address: "按地址",
        x_x_coordinate_field: "X坐标字段",
        x_y_coordinate_field: "Y坐标字段",
        x_address_field: "地址字段",
        x_new_add: "新增",
        x_user_management: "用户管理",
        x_menu_management: "菜单管理",
        x_role_management: "角色管理",
        x_department_management: "部门管理",
        x_department_name: "部门名称",
        x_sort: "排序",
        x_menu_name: "菜单名称",
        x_permission: "权限标识",
        x_grant: "授权",
        x_role_code: "角色标识",
        x_root_department: "根部门",
        x_department: "部门",
        x_root_menu: "根菜单",
        x_menu: "菜单",
        x_button: "按钮",
        x_parent_menu: "上级菜单",
        x_menu_cannot_delete: "菜单包含下级不能删除",
        x_dept_cannot_delete: "部门包含下级不能删除",
        x_role_describe: "角色描述",
        x_features: "功能",
        x_capabilities: "能力",
        x_cases: "案例",
        x_dont_have_account: "还没有账号？",
        x_already_member: "已有账号？",
        x_signup_success: "注册成功",
        x_signup_success_tip: "如您所在公司已有 Hub,请联系公司人员给您开通相应权限,即可登录",
        x_create_hub_tip: "如您未有 Hub，请点击下方链接创建，谢谢",
        x_log_management: "日志管理",
        x_you_can_enter_below_hub: "您可以进入以下 Hub",
        x_submit_success: "提交成功",
        x_create_hub_introduce: "您还未有公司帐户，请点击输入以下资料并确认提交；或点击右下角联系我们。我们会及时与你联络。谢谢!",
        x_create_hub_introduce_cn: "您还未有公司帐户，请添加我司企业微信号，联系我司业务人员。谢谢！",
        x_create_hub_success_tip: "我们会尽快与您联系，谢谢！",
        x_return_to_signin: "返回登录",
        x_operation: "操作",
        x_batch_operation: "批量操作",
        x_log_method: "方法",
        x_remove_user_tip: "是否确认删除此用户？",
        x_project_time: "项目时间",
        x_enter: "请输入",
        x_project_list: "项目列表",
        x_file_count: "文件数量",
        x_file_type: "文件类型",
        x_publish_new_resource: "发布新资源",
        x_total_resource: "资源总数",
        x_compute_power_use: "算力消耗",
        x_total_visits: "访问总次",
        x_total_storage: "总存储量",
        x_resource_type: "资源类型",
        x_from_small_to_large: "由小至大",
        x_from_large_to_small: "由大至小",
        x_invite_register: "邀请注册",
        x_digital_twins: "数字孪生",
        x_auth_intro: "科技塑造未来，智能创造精彩",
        x_publish_type: "发布类型",
        x_basic_options: "基础选项",
        x_publish_new_resource: "发布新资源",
        x_product: "产品",
        x_base_layer_setting: "底图设置",
        x_search_result: "搜索结果",
        x_descending: "由大至小",
        x_ascending: "由小至大",
        x_comfirm_delete_resource: "确认删除资源 {0} 吗？",
        x_template: "模板",
        x_template_smart: "智慧工地",
        x_template_common: "通用",
        x_template_ai: "AI",
        x_fold_menu: "收起菜单",
        x_template_default: "默认模板",
        x_template_panorama: "全景场景",
        x_template_gis: "GIS 场景",
        x_template_bim: "BIM 场景",
        x_using_wechat_login: "使用微信登录",
        x_share_link_validity_tip: "分享链接有效日期不能为空",
        x_data_directory: "数据目录",
        x_path_select: "路径选择",
        x_template_name: "模板名称",
        x_wechat: "微信",
        x_already_bound: "已绑定",
        x_unbind: "解绑",
        x_bind_wechat: "绑定微信",
        x_avatar: "头像",
        x_app_title: "GeoTwin空间孪生计算平台",
        x_showOverview: "是否显示在概览",
        x_in_progress: "进行中",
        x_asset_mapview: "资产地图",
        HONGKONG: "中国香港",
        TAIWAN: "中国台湾",
        CHINA: "中国大陆",
        x_password_rule_1: "必须包含6-20个字符",
        x_password_rule_2: "必须同时包含大小写字母和数字和特殊符号",
        x_password_rule_3: "不能过于常见",
        x_solution: "解决方案",
        x_about_us: "关于我们",
        x_select: "请选择",
        x_save_perspective: "保存视角",
        x_select_position: "选择位置",
        x_reselect_position: "重新选择位置",
        x_editable_source_publish: "可编辑图层发布",
        x_blank_layer: "空白图层",
        x_feature_type: "要素类型",
        x_point: "点",
        x_line: "线",
        x_polygon: "面",
        x_field_name_format_err: "只能使用小写字母、数字和下划线构成蛇形命名（数字不能在开头）",
        x_wechat_login_err_unbind: "您未绑定微信，请先登录或注册后自动为您绑定",
        x_set_attribute: "设置属性",
        x_upload_info: "已存在相同文件，请选择覆盖还是创建副本",
        x_cover: "覆盖",
        x_create_copy: "创建副本",
        x_signup_pwd_error: "密码不包含空格，并且长度至少为8个字符",
    },
    "zh-TW": {
        x_upload_status_success: "上傳成功",
        x_upload_status_fail: "上傳失敗",
        x_upload_status_uploading: "上傳中",
        x_upload_status_paused: "暫停",
        x_upload_status_waiting: "等待",
        x_upload_read_file_error: "讀取文件失敗",
        x_upload_md5_checking: "MD5校驗",
        x_upload_file_merging: "合並中",
        x_upload_file_transcoding: "轉碼中",
        x_common_error: "錯誤",
        x_upload_choose_file: "選擇文件",
        x_upload_file_list: "文件列表",
        x_common_empty: "暫無內容",
        x_upload_file_upload: "文件上傳",
        x_bangzhu: "説明",
        x_advanced_options: "高級選項",
        x_scene: "場景",
        x_create_new_scene: "創建新場景",
        x_publish: "發佈",
        x_successful_publication: "已發布",
        x_publishing_failure: "發布失敗",
        x_publishing: "正在發布",
        x_project_mgn: "項目管理",
        x_data_mgn: "數據管理",
        x_ai_mgn: "AI管理",
        x_ai_model_mgn: "AI模型",
        x_asset_mgn: "資產管理",
        x_account_mgn: "賬戶管理",
        x_shared: "我的分享",
        x_shared_man: "分享人",
        x_all_resource: "全部資源",
        x_add_new_company: "新建公司賬號",
        x_date_and_size: "創建時間 / 大小",
        x_expire_date: "失效日期",
        x_visits_number: "訪問次數",
        x_link_expired: "鏈接已失效",
        x_unshare: "停止共享",
        x_unzipping: "解壓中...",
        x_unzipped: "解壓完成",
        x_login_send_again: "再次發送",
        x_select_or_input: "請選擇或輸入",
        x_hub_hint: "請提交公司logo，公司名稱及縮寫以下email，我們會及時與您聯繫",
        x_enter_project: "進入項目",
        x_enter_dashboard: "進入Dashboard",
        x_enter_Project_name: "輸入項目名稱",
        x_enter_workspace_name: "輸入工作空間名稱",

        x_playback: "軌跡回放",
        x_dmspoint: "DMS點",
        x_alarmdms: "Alarm Point",
        x_alertdms: "Alert Point",
        x_actiondms: "Action Point",
        x_legend: "圖例",
        x_data_loading: "數據加載中...",
        x_my_overview: "我的工作台",
        x_all_member: "所有成員",
        x_overview_project: "項目",
        x_overview_resource_storage: "存儲",
        x_overview_workspace: "工作空間",
        x_overview_user: "用戶",
        x_overview_resourve: "資源",
        x_overview_vehicle_management: "車輛管理",
        x_management: "管理",
        x_version: "版本",
        x_upload_new_version: "上傳新版本",
        x_overview_today: "今日",
        x_overview_weekly: "本週",
        x_overview_monthly: "本月",
        x_overview_sixty_days: "60天",
        x_overview_project_top_ten: "項目訪問量 (前10)",
        x_overview_workspace_top_ten: "工作空間訪問量 (前10)",
        x_overview_total_visit: "全部訪問量",
        x_viewer: "查看器",
        x_general: "通用",
        x_mode: "模式",
        x_of: "的",
        x_or: "或者",
        x_and_bing: "並且",
        x_add_to: "添加到",
        x_and: "然後",
        x_remove: "清除",
        x_hour: "小時",
        x_project: "項目",
        x_workspace: "工作空間",
        x_dms_admin: "DMS 管理",
        x_streaming_video: "實時視頻",
        x_pms: "照片管理",
        x_accuracy: "準確度",
        x_confidence: "置信度",
        x_batch_update: "批量修改",
        x_pms_download: "下載",
        x_pms_compare: "多圖對比",
        x_pms_create_new: "創建新相冊",
        x_pms_layers: "關聯圖層",
        x_pms_layers_select: "請選擇關聯圖層",
        x_pms_view_map: "地圖模式",
        x_pms_view_grid: "網格模式",
        x_pms_view_type: "視圖模式",
        x_pms_map_switch: "增加地圖模式",
        x_pms_grid_switch: "切回視圖模式",
        x_layer: "圖層",
        x_baes_layer: "底圖",
        x_terrain: "地形",
        x_2d_layer: "二維",
        x_3d_layer: "三維",
        x_3d_viewer: "三維查看器",
        x_pointcloud_viewer: "點雲查看器",
        site_title: "GeoTwin 平台",
        x_username: "用戶名",
        x_username_or_email: "賬戶郵箱地址",
        x_password: "密碼",
        x_confirm_password: "確認密碼",
        x_default: "默認",
        x_comfirm_replica: "確認是否複製此 {0} {1}",
        x_captcha_text: "驗證碼",
        x_login: "登錄",
        x_using_google_login: "使用Google登入",
        x_forget_pwd: "忘記密碼？",
        x_comfirm: "確認",
        x_username_empty_err: "用戶名不能為空",
        x_password_empty_err: "密碼不能為空",
        x_comfirm_password_empty_err: "確認密碼不能為空",
        x_password_err: "兩次輸入密碼不一致",
        x_change_password: "修改密碼",
        x_current_password: "當前密碼",
        x_set_password: "設置密碼",
        x_remove_password: "移除密碼",
        x_new_password: "新密碼",
        x_new_password_dup: "確認新密碼",
        x_logout: "登出",
        x_search: "搜索",
        x_reset: "重置",
        x_site_mission_corsys: "坐標系",
        x_default_coord_sys: "默認坐標系",
        x_coord_sys_placeholder: "坐標系名稱或EPSG碼",
        x_coord_sys_default_wgs84: "默認為 WGS84 (4326)",
        x_measurement: "測量",
        x_ms_height: "高程",
        x_ms_coordinate: "坐標",
        x_ms_volume: "體積",
        x_ms_area_projected: "投影面積",
        x_ms_angle: "角度",

        x_information: "信息",
        x_style: "樣式",
        x_style_lowercase: "樣式",
        x_attribute: "屬性",
        x_2d_border: "邊線",
        x_2d_cap: "端點類型",
        x_2d_dash: "類型",
        x_2d_width: "寬度",
        x_2d_font: "字體",
        x_2d_size: "大小",
        x_3d_heading: "朝向",

        x_3d_tidal_analysis: "水面效果繪制",
        x_draw_analysis_range: "繪製分析範圍",
        x_flood_height: "淹沒高度",
        x_3d_line_of_sight: "可視線分析",
        x_3d_visual_analysis: "可視域分析",
        x_3d_site_formation: "Site Formation",
        x_3d_cut_section: "剖面分析",
        x_3d_skyline_analysis: "天際線分析",
        x_3d_shadow_analysis: "光照陰影分析",
        x_3d_skyview: "天空域分析",
        x_3d_skyview_graph: "天空域示意圖",
        x_3d_skyview_factor: "天空域因子",
        x_range: "範圍",
        x_pointcloud_is_3dtiles: "發佈到三維視圖",
        x_pointcloud_is_potree: "發佈到點雲查看器",
        x_at_least_one_chose: "以下需至少勾選一項",

        x_pwd_very_weak: "非常弱",
        x_pwd_weak: "弱",
        x_pwd_moderate: "中",
        x_pwd_strong: "強",
        x_pwd_very_strong: "非常強",
        x_pwd_weak_no_submit: "密碼過於簡單",

        x_3d_fly_through: "飛行動畫",
        x_fly_path_add: "新建飛行動畫",
        x_back_to_fly_path: "返回飛行動畫管理",

        x_3d_edit_3d_polyline: "三維線",
        x_3d_edit_3d_box: "多邊形拉伸體",
        x_3d_edit_cylinder: "圓柱體",
        x_3d_edit_rectangle_2d: "矩形",
        x_3d_edit_ellipse_2d: "橢圓",
        x_close: "關閉",
        x_signup: "註冊用戶",
        x_signup_name: "用戶名",
        x_signup_email: "郵件",

        x_create: "新建",
        x_submit: "提交",
        x_empty_err: "此欄不能為空",
        x_illegal_err: "此欄不能輸入非法字符",
        x_edit: "編輯",
        x_edit_api: "編輯API接口",
        x_delete: "刪除",
        x_share: "分享",
        x_share_success: "分享成功",
        x_please_create_a_sharing_link_first: "請先創建分享連結",
        x_member_sharing: "成員分享",
        x_please_select_a_member: "請選擇成員",
        x_role_admin: "系統管理員",
        x_role: "角色",
        x_teams: "團隊",
        x_register_success: "註冊成功，請登入",
        x_email_invlidate: "郵件格式不正確",
        x_mobile_invlidate: "手機號格式不正確",
        x_internal_server_err: "服務器出錯",
        x_annotations: "標註",
        x_save: "保存",
        x_site_management: "項目管理",
        x_nav_mission: "任務",
        x_sample: "樣例",
        x_sample_trajectory: "點擊下載樣例軌跡文件",

        x_saving: "保存中...",
        x_processing: "處理中...",
        x_ok: "確定",
        x_cancel: "取消",
        x_success: "成功",
        x_site_title: "標題",
        x_site_guest_accessible: "允許訪客查看",
        x_site_missions: "任務",
        x_add_mission: "新建任務",
        x_split_view: "雙視圖",
        x_split_mode: "分屏模式",
        x_curtain_mode: "捲簾模式",

        x_clear_operation: "清除",
        x_lang: "語言",
        x_basemap: "底圖圖層",
        x_3d_baselayer: "基本三維圖層",
        x_render_quality: "渲染質量",
        x_render_quality_low: "低",
        x_render_quality_normal: "普通",
        x_render_quality_high: "高",
        x_render_effect: "渲染效果",
        x_sun_shadow: "日照陰影效果",
        x_blooming: "高光效果",
        x_depth_of_field: "景深效果",
        x_bookmark: "書籤",
        x_bookmark_name: "書籤名",
        x_data_upload: "數據上傳",
        x_select_upload_folder: "選擇上傳目錄",
        x_select_data_folder: "選擇數據目錄",
        x_folder_no_type_data: "文件夾不包含選擇的數據類型",
        x_cal_pub_size: "正在計算發布所需空間",
        x_pub_size_req: "需要空間",
        x_pub_size_total: "可用資源發布空間",
        x_remain_pub_size: "剩餘空間",
        x_size_resource: "資源空間",

        x_upload: "上傳",
        x_upload_lng: "經度",
        x_upload_lat: "緯度",
        x_upload_height: "高度",
        x_upload_lng_X: "經度",
        x_upload_lat_Y: "緯度",
        x_model_editing: "模型編輯",
        x_move: "平移",
        x_rotate: "旋轉",
        x_resource_search: "搜索資源",

        x_addlayer: "添加圖層",
        x_delete_layer: "刪除圖層",
        x_navigation: "導航",
        x_analytics: "分析",
        x_full_screen: "全屏",
        x_fly_home: "返回起始位置",
        x_set_project_position: "設置項目起始位置",
        x_true_north: "旋轉到正北",
        x_2d_view: "二三維模式切換",
        x_screen_cap: "場景截圖",
        x_screen_rec: "場景錄制",
        x_rec_stop: "停止",
        x_nodata: "暫無數據",
        x_none: "無",
        x_normal: "普通",
        x_loading: "加載中...",

        x_pms_title: "照片名稱",
        x_pms_tags: "標簽",
        x_pms_copylink: "複製資源連結",
        x_pms_start_date: "起始日期",
        x_pms_end_date: "終止日期",
        x_pms_define_region: "繪制過濾範圍",
        x_pms_filter: "過濾",
        x_pms_search_region: "搜索範圍",
        x_pms_select_range: "選擇範圍",
        x_pms_selected: "張已選中",
        x_pms_selectall: "全選",
        x_pms_unselectall: "全部取消選中",
        x_pms_is_trajectory: "生成軌跡",
        // x_pointcloud_is_potree: '發佈到點雲查看器',
        x_pano_is_face_blur: "隱私處理",
        x_eo_format: "位姿文件格式",
        x_spatial_offset: "坐標偏移",

        x_eo_loading: "正在讀取照片文件信息",
        x_img_info_table: "照片文件信息",
        x_upload_eo_csv: "上傳位置信息",
        x_download_eo: "下載位置信息",
        x_publish_resource: "發布資源",
        x_eo_ph_name: "照片名",
        x_easting: "x坐標",
        x_northing: "y坐標",
        x_omega: "俯仰角",
        x_phi: "翻滾角",
        x_kappa: "偏航角",
        x_eo_img_total_count: "個照片文件",
        x_eo_img_positioned: "個照片包含地理坐標",
        x_x: "X坐標",
        x_y: "Y坐標",
        x_z: "Z坐標",
        x_r: "旋轉度",

        x_nav_position: "位置設置",
        x_nav_fly_to: "飛到鼠標點選位置",
        x_nav_lock_position: "鎖定位置",
        x_nav_zoom_to_bound: "矩形縮放",
        x_nav_2D_view: "二維視角",
        x_nav_view_mode: "瀏覽模式",
        x_nav_default: "默認鼠標操作",
        x_nav_first_person: "鍵盤操作",

        x_nav_enter_google_street: "進入谷歌街景",
        x_nav_google_transparency: "街景透明度",
        x_nav_back_3d: "返回三維視圖",
        x_nav_streetview_not_found: "該位置未找到街景影像",

        x_bookmark_no_bookmark: "無書簽",

        x_ms_volume_base_type: "基準面類型",
        x_ms_volume_base_low: "以最低頂點高程為準",
        x_ms_volume_base_high: "以最高頂點高程為準",
        x_ms_volume_base_ave: "以頂點平均高程為準",
        x_ms_volume_base_triang: "三角網構面",
        x_ms_volume_base_man: "自定基準面高度",
        x_ms_volume_quick_measure: "快速量算",

        x_ms_volume_base_level: "基準高度(米)",
        x_ms_volume_calculate: "計算體積",
        x_ms_volume_bl: "基準高度",
        x_ms_sampling_interval: "採樣間隔",
        x_ms_error_prop: "誤差估算",
        x_ms_volume_cv: "挖方體積",
        x_ms_volume_fv: "填方體積",
        x_ms_volume_tv: "總體積",

        x_3d_terrain_excavation: "地形開挖",
        x_3d_terrain_flattening: "三維壓平",
        x_3d_clip_plane: "裁剪面",
        x_3d_clip_box: "裁剪盒",
        // x_3d_clip_plane_horizontal: '水平剖切面',
        // x_3d_clip_plane_vertical: '垂直剖切面',
        x_flood_level: "水面高度（米）",
        x_flood_level_placeholder: "水面高度（米）",
        x_flood_define_flood_region: "繪制水面範圍",

        x_shadow_height: "光照率分析高度",
        x_shadow_start_time: "開始時間",
        x_shadow_end_time: "結束時間",
        x_shadow_animate: "模擬動畫",
        x_shadow_calculate_ratio: "計算區域光照率",
        x_sun_exposure: "光照率",

        x_stop_as_current_pos: "更新為當前視角",
        x_fly_speed: "飛行速度",
        x_duration: "時長",
        x_fly_save_video: "播放完成另存為視頻文件",
        x_fly_video_name: "視頻文件名",
        x_fly_add_stop: "添加站點",
        x_fly_animate: "播放",

        x_3d_edit_rectangle: "長方體",
        x_3d_edit_site: "壓平地塊",
        x_3d_edit_arrow: "箭頭",
        x_3d_edit_annotation: "標註文本",
        x_3d_edit_redline: "紅線批註",
        x_3d_edit_file: "文檔",
        x_3d_model: "模型",
        x_upload_title: "上傳模型",
        x_upload_drag_note: "點擊或拖拽上傳",
        x_upload_note: "點擊或拖拽以上傳含obj模型的zip包",

        x_drawing_area: "面積",
        x_drawing_footprint: "面積",
        x_drawing_color: "顏色",
        x_action: "操作",

        x_sure_del_sketch: "是否刪除？",
        x_edit_title: "編輯名稱",
        x_sketch_add: "新建草案設計",
        x_quit_sketch_design: "退出草案設計",
        x_export_sketch_design: "導出設計",
        x_floor_count: "樓層數",
        x_floor_height: "樓層高(米)",
        x_gfa_efficiency: "實用比",
        x_gfa: "建築面積",

        // weather
        x_temperature: "溫度",
        x_humidity: "濕度",
        x_uvindex: "紫外線",

        x_project_folder_selection: "選擇文件夾",
        x_project_folder_selection1: "選擇文件",
        x_project_new_folder: "新建文件夾",
        x_project_folder_name: "文件夾名稱",
        x_project_upload_step1: "步驟一：選擇遠程文件夾",
        x_project_upload_step2: "步驟二：選擇本地文件",
        x_category: "類別",
        x_project_publish_resource_name: "資源名稱",
        x_project_restricted_status: "修改HUB許可權",
        x_static_data: "靜態數據",
        x_dynamic_data: "動態數據",
        x_refresh_time: "刷新時間（分鐘）",
        x_capture_date: "數據採集日期",
        x_share_resources: "分享資源",
        x_restrict_access: "限制訪問",
        x_3d_coordinate_system: "三維坐標系",
        x_rotation_angle: "旋轉角度",
        x_coordinate3d_input_tips: "輸入必須為double類型的數位，請重新輸入",
        x_restrict_access_tips1: "不限制域名訪問",
        x_restrict_access_tips2: "限制本hub域名訪問",
        x_project_publish_layerurl: "資源地址",
        x_project_publish_ogc_layername: "包含服務中圖層名稱",
        x_ogc_tile_matrix_set: "比例尺集",
        x_enable_supermap_query: "超圖數據服務查詢",
        x_supermap_query_url: "查詢超圖數據服務地址",
        x_supermap_datasource: "數據源",
        x_dataset: "數據集",
        x_data_value: "數據值",
        x_url_inaccessible: "服務地址無法訪問",
        x_url_format_invalid: "無法解析服務",
        x_upload_4d_script: "上傳時間線腳本",
        x_read_4d_complete: "時間線腳本上傳完成",
        x_sm_dataset_default_merge:
            "如果服務為超圖合並數據集則無需提供數據集名稱",
        x_upload_supermap_workspace: "上傳超圖工作空間檔案",

        x_project_msg_uploading: "正在上傳文件...",
        x_project_msg_add_layer_success: "新建圖層完成",
        x_login_msg_login_incorrect: "用戶名或密碼不正確",
        x_index_msg_add_bookmark: "添加書簽成功",
        x_index_msg_delete_bookmark: "刪除書簽成功",
        x_index_msg_bookmark_share: "書簽分享地址已覆制到剪切板",

        x_index_msg_zip_file: "正在打包文件",
        x_index_msg_zip_photo: "正在打包照片",
        x_index_msg_to_enter_google: "請在三維視角中點選進入谷歌街景的位置",
        x_index_msg_save_init_position: "正在保存項目起始位置",
        x_index_msg_save_init_position_success: "保存項目起始位置成功",
        x_index_msg_generate_video: "正在生成視頻",
        x_index_msg_model_share: "模型分享地址已覆制到剪切板",
        x_index_msg_model_upload: "正在上傳模型",
        x_index_msg_model_process: "正在處理模型",
        x_index_msg_model_success: "模型加載完成",
        x_terrain_transparency: "地形透明",

        x_upload_md5_cal: "正在读取文件...",
        x_upload_md5_fail: "讀取文件失敗",
        x_upload_sm_no_scene: "工作空間沒有三維場景",

        x_3d_edit_pipe: "管線",
        x_volume_management: "土方管理",
        x_vm_title: "土方名稱",
        x_vm_base_height: "基準面高（米）",
        x_vm_create_new: "新建體積量算",
        x_vm_to_draw_region: "滑鼠左鍵在三維場景中框選土方範圍，右鍵結束",
        x_vm_no_result: "未找到體積量算結果。請先進行土方體積計算。",
        x_vm_stockpile: "土方名稱",
        x_vm_date: "上次測量日期",
        x_vm_left_viewer: "左視圖",
        x_vm_right_viewer: "右視圖",
        x_vm_report_title: "體積量算報告",
        x_vm_usage: "在三維界面中繪製量算範圍或上傳基準面信息的csv文件",

        x_service_api_url: "記錄添加服務API地址",

        x_vm_fly_to: "飛到土方所在位置",
        x_vm_visible: "顯示/隱藏土方範圍",
        x_vm_calculate: "量算",
        x_vm_report: "顯示報告",

        x_vm_site_mode: "上传范围类型",
        x_vm_region_ring: "多边形范围",
        x_vm_spot_tin: "高程点",

        x_pipe_edit_title: "編輯管線",
        x_pipe_edit_x: "x（米）",
        x_pipe_edit_y: "y（米）",
        x_pipe_edit_z: "z（米）",
        x_pipe_edit_width: "管直徑（米）",
        x_edit_upload_file: "選擇上傳文檔",

        x_console: "系統管理",
        x_dept_name: "组别名稱",
        x_add: "增加",
        x_update: "更新",
        x_revise: "修改",
        x_feature: "要素",

        x_poi: "興趣點",
        x_poi_name: "名稱",
        x_poi_add: "添加興趣點",

        x_nav_navigate: "導航",
        x_nav_view: "視圖",
        x_nav_streetview: "街景視圖",
        x_nav_routing: "最短路線查詢",
        x_nav_selection: "選取",
        x_nav_select: "選取",
        x_nav_locate: "定位",
        x_nav_search_poi: "興趣點搜索",
        x_nav_search_buffer: "緩沖區查詢",
        x_nav_search_bound: "畫框查詢",
        x_nav_generate_plan: "生成圖紙",
        x_nav_helper: "幫助",
        x_nav_save_as_profile: "保存封面",
        x_nav_tool: "工具",
        x_nav_bim: "BIM",
        x_nav_measure: "測量",
        x_nav_analysis: "分析",
        x_nav_drawings: "標註",
        x_nav_text: "文字",
        x_layer_panel: "圖層面板",
        x_bookmark_panel: "書籤面板",
        x_volume_panel: "體積管理面板",
        x_model_panel: "模型面板",
        x_drawing_panel: "標註面板",
        x_pms_panel: "PMS面板",
        x_nav_setting: "設置",
        x_nav_screen: "屏幕縮放",
        x_compress_screen: "正常",
        x_nav_transparency: "透明度",
        x_timeline_play_4d: "播放",
        x_timeline_pause_4d: "暫停",
        x_timeline_loading: "正在加載時間線",
        x_timeline_no: "當前項目圖層未找到時間線",
        x_pt_styling: "點雲上色",
        x_pt_point_size: "點大小",
        x_pt_class_filter: "按分類顯示",

        x_query_buffer_define_size: "設置緩衝區半徑",

        x_media_info: "多媒體信息",
        x_properties: "屬性",
        x_layer_setting: "圖層設置",
        x_zoom_to: "縮放至",
        x_nav_review: "審核",
        x_nav_output: "輸出",
        x_nav_effects: "效果",
        x_nav_animation: "動畫",
        x_add_in_module: "插件",
        x_nav_2d_drawings: "2維 畫筆",
        x_nav_3d_drawings: "3維 畫筆",
        x_nav_terrain_analysis: "地形分析",
        x_nav_line_of_site: "視覺分析",
        x_nav_sectional_analysis: "剖面分析",
        x_nav_shadow_analysis: "陰影分析",

        x_nav_3d_particle: "粒子效果",
        x_nav_3d_particle_fire: "火焰",

        x_batch_delete: "批量刪除",
        x_selected_resource: "選中資源",
        x_signup_repwd: "再次輸入密碼",
        x_signup_repwd_error: "再次輸入密碼與之前輸入不一致",
        x_signup_phone_error: "請輸入正確的手機號",
        x_status: "狀態",
        x_invoke_number: "調用次數",
        x_user_disable: "禁用",
        x_user_normal: "正常",
        x_create_date: "創建時間",
        x_role_name: "角色名稱",
        x_remark: "備註",
        x_role_access: "訪問權限",
        x_layer_name: "圖層名稱",
        x_layer_url: "圖層位址",
        x_layer_type: "圖層類型",
        x_layer_category: "圖層類别",
        x_service_publish: "服務圖層發佈",
        x_data_publish: "本地數據發佈",
        x_datasource_publish: "數據源發佈",
        x_publish_datasource_tip: "Excel, Esri shapefile, GeoJSON......",
        x_clone_layer_publish: "發佈已有圖層",
        x_title: "名稱",
        x_name: "名稱",
        x_date: "日期",
        x_owner: "所有者",
        x_desc: "描述",
        x_type: "類型",
        x_parent_dept: "上級部門",
        x_comfirm_deletion: "確認是否刪除此 {0} {1}",
        x_user: "用户",
        x_existing_layer: "已有圖層",

        x_init_gis_publish: "新建GIS資源",
        x_init_gis_tip: "二/三維 點/線/面",
        x_geometry_type: "類型",
        x_shp_point_2d: "二維點",
        x_shp_linestring_2d: "二維線",
        x_shp_polygono_2d: "二維面",
        x_shp_circle_2d: "二維圓",
        x_shp_point_3d: "三維點",
        x_shp_linestring_3d: "三維線",
        x_shp_polygon_3d: "三維面",

        x_init_iot_publish: "新建IoT設備圖層資源",
        x_init_ai_photogram: "AI Photogrammetry",

        x_image_loading: "正在加載相片",
        x_viewer_loading: "正在加載三維視圖",

        x_share_bkmk_copied: "分享書簽地址已覆制到剪切板",
        x_home_success: "設置起始位置成功",
        x_kml_invalid: "請上傳正確的KML文件",
        x_csv_invalid: "請上傳正確的CSV文件",
        x_no_delete_root_folder: "不可以刪除根目錄",

        x_viewport: "視點",
        x_viewport_category: "視點分類",
        x_viewport_mgmt: "視點管理",
        x_viewport_goto: "進入視點",
        x_viewport_detailed_info: "視點信息",
        x_viewport_info: "信息",
        x_alert: "警告",
        x_alert_message: "收到警告消息",
        x_alert_info: "警告信息",
        x_viewport_scheduled: "安排處理",
        x_viewport_completed: "完成",
        x_viewport_category_add: "添加視點分類",
        x_viewport_add: "添加視點",
        x_viewport_category_name: "視點名稱",
        x_viewport_state: "狀態",
        x_viewport_play_ctrl: "播放控制",
        x_viewport_comment_pane: "评论",

        x_generate_share_link: "正在生成分享鏈接",
        x_back: "返回",
        x_share_link: "分享鏈接",
        x_viewport_type: "視點類型",
        x_viewport_animated: "錄制",
        x_viewport_recording: "系統正在錄制視點，點擊右側‘停止’按鈕結束",
        x_copy_to_clipboard: "拷貝到剪貼板",

        x_home_management: "管理",
        x_home_query: "查詢",
        x_temporary_layer: "臨時圖層",
        x_upload_obj: "上傳 OBJ",
        x_upload_kml: "上傳 KML",
        x_upload_geojson: "上傳 Geojson",
        x_upload_jpg: "上傳 JPG",
        x_upload_gltf: "上傳 GLTF",
        x_upload_csv_point: "上傳 CSV點文件",
        x_upload_csv_path: "上傳 CSV路徑文件",
        x_upload_bim_osgb: "上傳 BIM (osgb)",
        x_upload_batch: "批量上傳",
        x_measurement_point: "點測量",
        x_ms_distance: "距離測量",
        x_ms_perimeter: "周长測量",
        x_free_polyline: "線長度",
        x_dvh: "水平垂直距離",
        x_vertical_ground: "高度差",
        x_distance_xyz: "XYZ坐標差",
        x_perp_reference_line: "點線距離",
        x_arc_3_digit: "弧長",
        x_measurement_area: "面積測量",
        x_free_area: "手動三維面",
        x_vert_rect_diagonal: "對角線成垂直矩形",
        x_vert_rect_3pts: "三點成垂直矩形",
        x_vert_rect_intersect: "拉伸成垂直矩形",
        x_horiz_rect: "水平矩形",
        x_horiz_sqrt_area_ground: "矩形投影",
        x_free_area_ground: "多邊形投影",
        x_horiz_circle_ctr_radius: "圓心半徑成水平圓",
        x_horiz_circle_3d: "三點成水平圓",
        x_measurement_volume: "體積測量",
        // x_ms_volume: 'Volume',
        x_cut_fill: "填挖方",
        x_export: "導出",
        x_export_shp: "導出為 Shapefile",
        x_export_kml: "導出為 KML",
        x_export_csv: "導出為 CSV",

        x_icon: "圖標",
        x_design_view: "繪制設計",
        x_anno_icon: "圖標",
        x_anno_tag: "註釋標簽",
        x_anno_site_formation: "壓平地塊",
        x_nav_water: "水面",
        x_drawing_circle: "圓形",
        x_drawing_polyline: "線",
        x_drawing_symbol: "標識",
        x_drawing_arrow: "箭頭",
        x_drawing_blank_indicator: "空指示牌",
        x_drawing_text_indicator: "文字指示牌",
        x_drawing_text: "文字",
        x_drawing_list: "繪制列表",
        x_list: "列表",
        x_list_mode: "列表模式",
        x_inventory_mode: "清單模式",
        x_new_draw: "新建绘制",
        x_drawings: "繪制",
        x_design_management: "繪圖管理",
        x_collaboration: "協作",
        x_new_viewport: "新建視點",
        // x_new_viewport_group: '新建視點組',
        // x_viewport_group: '視點組',
        x_new_viewport_group: "新建4D視點",
        x_viewport_group: "4D視點",
        x_new_viewport_category: "新建視點分類",
        x_new_status: "新增視點狀態",
        x_tracking: "跟蹤",
        x_table_of_content: "目錄",
        x_select_date: "選擇日期",

        x_resource_mgmt: "資源管理",
        x_security_mgmt: "安全管理",
        x_use_statistics: "使用統計",
        x_user_pref: "用戶設置",
        x_civil_tools: "土木工具",
        x_planning: "規劃",
        x_planning_design: "規劃設計",
        x_sketch_design: "草案設計",
        x_design_scheme: "設計方案",
        x_rdms_panel: "RDMS",
        x_3dms_panel: "3DMS",
        x_mobile_app: "移動端",
        x_fms_panel: "FMS",
        x_novade: "Novade",
        x_bim_plus: "3DMS+",
        x_timeline_panel: "時間線",
        x_timeline_4d: "播放4D模擬",
        x_bim360: "BIM360",
        x_mms_panel: "MMS",
        x_orbit: "Orbit",

        x_stat_daily_view: "日訪問統計",
        x_stat_proj_view_title: "項目訪問",
        x_stat_wks_view_real_title: "工作空間訪問（註冊用戶）",
        x_stat_wks_view_anon_title: "工作空間訪問（匿名用戶）",
        x_stat_views_time: "次訪問",
        x_stat_views_time_real: "次註冊用戶訪問",
        x_stat_views_time_anon: "次匿名訪問",

        x_processing_tasks: "任務",
        x_msg_list: "消息列表",
        x_menu_my_catalog: "我的目錄",
        x_menu_storage: "存儲空間",
        x_menu_share: "分享",
        x_menu_resources: "資源",
        x_menu_workspaces: "工作空間",
        x_menu_viewports: "視點",
        x_menu_company_catalog: "公司目錄",
        x_menu_users_teams: "用戶與團隊",
        x_menu_account: "賬戶",
        x_menu_my_profile: "我的信息",
        x_menu_my_settings: "我的設置",
        x_menu_my_massage: "我的消息",
        x_menu_company_profile: "公司信息",
        x_menu_plan_profile: "套餐信息",
        x_menu_statistics_dashboard: "統計表",
        x_menu_more: "更多",
        x_menu_contact: "聯絡我們",
        x_menu_pricing: "價格",
        x_menu_help_center: "幫助中心",
        x_home: "首頁",
        x_product_features: "產品特色",
        x_function_introduction: "功能介紹",
        x_classic_case: "經典案例",
        x_customer: "我們的客戶",
        x_control_console: "主制台",
        x_free_try: "免費試用",

        x_features_slogan_1: "瀏覽器記憶體動態優化及緩存加速技術",
        x_features_slogan_2: "BIM & GIS空間大模型輕量化演算法",
        x_features_slogan_3: "更安全更流暢的可計算向量瓦片技術",
        x_features_slogan_4: "基於WebGPU的並行AI計算，空間分析和光影渲染能力",
        x_features_introduce_1: "提升瀏覽器性能的關鍵技術之一。 這些技術通過智慧管理記憶體資源和優化緩存機制，提供更順暢、更快速的網頁瀏覽體驗。",
        x_features_introduce_2: "旨在壓縮和優化大型建築和地理數據模型，提高處理效率和速度。 這些演算法簡化模型並保持關鍵屬性，促進高效的專案設計和數據共用。",
        x_features_introduce_3: "可計算向量瓦片技術提供更安全、更流暢的地圖顯示方式。 它基於向量數據，實時計算和渲染地圖，提供更好的互動性和性能。",
        x_features_introduce_4: "基於WebGPU的並行AI計算、空間分析和光影渲染能力結合了高效的網頁環境和先進的演算法，提供了強大的計算和渲染功能。",
        x_function_nav_1: "三維空間可視化",
        x_function_nav_2: "數據標繪和管理",
        x_function_nav_3: "場景交互",
        x_function_nav_4: "空間分析",
        x_function_nav_5: "場景特效",
        x_classic_ases_name_1: "通過現實捕捉和CIM進行智能專案和城市規劃或更新",
        x_classic_ases_name_2: "智慧網站監控儀錶板",
        x_classic_ases_name_3: "完成建築工程檢查",
        x_classic_ases_name_4: "檢查外部狀況",
        x_classic_ases_name_5: "村屋 UBWs 管理",
        x_classic_ases_name_6: "遺產管理和檢查",
        x_Contact: "聯絡我們",
        x_contact_phone_number: "(852) 3188 9008",
        x_contact_email: "info@geosys.org",
        x_contact_location: "香港新界北石角北石角飛思一期無線中心3樓313室",

        x_address: "地址",
        x_website: "網站",
        x_phone: "電話",
        x_mobile: "手機",
        x_fax: "傳真",
        x_setup: "設置",
        x_theme_blue: "藍",
        x_theme_green: "綠",
        x_theme_red: "紅",
        x_theme_purple: "紫",
        x_theme_dark_blue: "294C",
        x_theme_dark_pro: "深黑",
        x_theme_dark: "暗色",
        x_theme_light: "明亮",
        x_theme: "主題",
        x_my_preferences: "我的設置偏好",
        x_company_preferences: "公司設置偏好",
        x_data_processing: "數據處理",
        x_refresh_data: "刷新數據",
        x_refresh_status: "刷新狀態",
        x_change_profile_img: "更換頭像",
        x_change_company_logo: "更換Logo",
        x_create_new_user: "創建新用戶",
        x_create_new_team: "創建新團隊",
        x_create_new_project: "創建新項目",
        x_create_new_workspace: "創建工作空間",
        x_create_new_resource: "創建新資源",
        x_resource_index_map: "資源索引地圖",
        x_signup_invite_code: "邀請碼(選填)",
        x_user_unactivated: "未激活",
        x_view: "查看",
        x_renew: "重置",
        x_copy: "複製",
        x_rename: "重命名",
        x_rename_tip: "請輸入英文字符或數字",
        x_moveto: "移動",
        x_duplicate: "複製",
        x_users_in: "用戶在",
        x_resources_in: "資源在",
        x_renew_sharecode: "重置分享碼",
        x_opened_modules: "開放模塊",
        x_in_team: "在團隊中",
        x_in_project: "在項目中",
        x_in_company: "在公司中",
        x_in_workspace: "在工作空間中",
        x_enter_ws_pwd: "請輸入工作空間密碼",
        x_file_name: "文件名",
        x_move_to_header: "移動到",
        x_copy_to_header: "複製到",
        x_unzip_to_header: "解壓到",
        x_open: "打開",
        x_role_user: "普通用戶",
        x_guest: "訪客",
        x_role_dm: "數據管理員",
        x_role_pm: "項目管理員",
        x_role_superadmin: "超級管理員",
        x_share_code: "分享碼",
        x_size: "大小",
        x_table_header_progress: "進度",
        x_table_header_messgae: "消息",
        x_table_header_expire_date: "過期時間",
        x_table_header_share_with: "分享與",
        x_publish_service_tip:
            "URL Template Imagery Layer",
        x_publish_data_tip:
            "Media;Point Cloud (las);OSGB;3D Tiles......",
        x_please_contact: "請聯絡",
        x_choosed: "已選擇",
        x_private: "僅自己可見",
        x_encrypt: "加密",
        x_privacy: "可見設置",
        x_public: "公開",
        x_data_source: "數據源",
        x_fill_all_fields: "請填寫所有欄目",
        x_next: "下一步",
        x_region: "地區",
        x_product_plan: "套餐",
        x_max_online_users: "同時在線用戶限制",
        x_gen_share_link: "生成分享鏈接",
        x_share_link_clipboard: "分享鏈接已復制",
        x_share_pwd: "分享密碼已複製",
        x_qrcode: "二維碼",
        x_loading_qrcode: "下載二維碼",
        x_root_folder_not_operable: "根目錄不可操作",
        x_visit: "訪問",
        x_workspace_share: "工作空間分享",
        x_username_or_teamname_input: "用戶或團隊名",
        x_layer_property: "圖層屬性",
        x_default_open: "默認開啟",
        x_structure: "結構",
        x_preview: "預覽",
        x_add_tag: "添加標簽",
        x_tag: "標簽",
        x_append_tag: "追加標簽",
        x_tag_search: "標簽搜索",
        x_remove_tag: "移除標簽",
        x_specify_scene: "默認場景",
        x_specify_dashboard: "默認儀表盤",
        x_preview_these_resource: "預覽這些資源",
        x_add_layer_group: "添加圖層組",
        x_close_preview: "關閉預覽",
        x_unsupport_notice:
            "此類型數據暫不支持自動發布。提交發布請求之後支持人員將與您聯系。",
        x_layer_preview_tip:
            "拖動圖層修改順序; 點擊圖層修改圖層名; 點擊+號添加圖層組",
        x_query_service_url: "數據服務查詢語句",
        x_not_selectable: "不可選擇",
        x_enable_query: "開啟查詢",

        x_layer_s3m: "S3M",
        x_open_failed: "打開失敗",

        x_attr_exp_num_rec_str: "編輯失敗。該字段只支持數字數值",
        x_shp_feat_geom_edit: "編輯形狀",
        x_shp_feat_geom_delete: "刪除要素",

        x_high: "高",
        x_low: "低",
        x_alert_str: "{0}錄得{1}{2}讀數： {3}",

        x_arcgis_server_map: "ESRI地圖服務",
        x_supermap_3d: "超圖三維服務",
        x_url_template: "URL模板影像",
        x_wms_layer: "WMS服務",
        x_wmts_layer: "WMTS服務",

        x_workspace_template: "工作空間界面模板",
        x_proj_template: "項目界面模板",
        x_sdms: "SDMS默認項目",
        x_lite: "SDMS精簡界面",
        x_plan_template: "規劃設計模板",
        // x_iot_prototype: 'IoT控制大屏(演示)',
        x_iot_prototype: "IoT控制大屏",
        x_t2_portal_prototype: "T2大平台",
        x_dms: "DMS",

        x_iot_ui_cust: "界面定制",
        x_iot_enter_cust: "進入定制視圖",

        x_system: "系統",

        x_pricing_free: "免費版",
        x_pricing_std: "標準版",
        x_pricing_adv: "高級版",
        x_pricing_pro: "專業版",
        x_pricing_ult: "旗艦版",
        x_edi_pms_lite: "輕量影像管理",
        x_edi_pms: "影像管理",
        x_edi_bim: "BIM & GIS",
        x_media: "媒體",
        x_ptc_las: "點雲（las文件）",
        x_geotiff: "GeoTiff 柵格",
        x_panorama: "全景照片",
        x_photo: "照片",
        x_3dtiles: "3D Tiles 文件",
        x_3dtiles1: "3D Tiles",
        x_trajectory_csv: "軌跡（csv文件）",
        x_kml: "KML文件",
        x_obj: "OBJ三維模型",
        x_shp: "ESRI shapefile",
        x_file: "文件管理",
        x_bentley_dgn: "DGN文件",
        x_dxf_dwg: "DXF/DWG文件",
        x_collada_dae: "DAE三維模型",
        x_revit: "RVT文件",
        x_gsm_csv: "點文件(csv)",
        x_dem_geotiff: "地形 (GeoTiff)",
        x_video: "視頻",
        x_plan: "規劃",
        x_design_scheme_project_list: "項目列表",
        x_design_scheme_create_new: "創建設計",
        x_design_scheme_project_name: "項目名稱",
        x_design_scheme_last_update: "最後更新",
        x_design_scheme_option_name: "設計名稱",
        x_design_scheme_step_1: "第一步",
        x_design_scheme_step_2: "第二步",
        x_design_scheme_step_3: "第三步",
        x_design_scheme_step_4: "第四步",
        x_design_scheme_step_5: "第五步",
        x_design_scheme_step_6: "第六步",
        x_scheme_management: "設計方案管理",

        x_design_scheme_site_boundary: "設計地塊範圍",
        x_design_scheme_height_restriction: "限高（海拔米）",
        x_design_scheme_area: "地塊面積（平方米）",
        x_design_scheme_max_dom_pr: "最大允許住宅容積率",
        x_design_scheme_site_level: "地塊高度（海拔米）",
        x_design_scheme_max_nondom_pr: "最大允許非住宅容積率",
        x_design_scheme_storey_restriction: "樓層限制",
        x_design_scheme_site_coverage: "最大佔地率（百分比）",
        x_design_scheme_dom_pr: "住宅總容積率",
        x_design_scheme_nondom_pr: "非住宅總容積率",
        x_design_scheme_composite_formula: "使用混合地塊容積率計算公式",
        x_design_scheme_podium_pr: "平台層容積率",
        x_design_scheme_nondom_tower_pr: "非住宅樓容積率",

        x_outside_site: "繪製超出地塊邊界",
        x_collide_nba: "繪製與無建築區衝突",
        x_collide_block: "繪製與現有設計建築衝突",

        x_dom_concession: "Domestic concession (%)",
        x_nondom_concession: "Non-domestic concession (%)",
        x_dom_floor_height: "住宅樓層高(m)",
        x_nondom_floor_height: "非住宅樓層高(m)",

        x_select_site: "選擇地塊",
        x_all: "全部",

        x_design_scheme_domestic: "住宅樓",
        x_design_scheme_podium: "平台層",
        x_design_scheme_nondom: "非住宅樓",
        x_design_scheme_floor_height: "樓層高度",
        x_design_scheme_lobby_height: "大堂樓層高度",
        x_design_scheme_refuge_height: "防火層高度",
        x_design_scheme_gfa_concession: "GFA Concession(%)",
        x_design_scheme_tower_group_no: "設計分組數",
        x_design_scheme_use_up_podium: "用盡平台層容積率",
        x_design_scheme_draw_podium: "繪製平台層",

        x_design_scheme_tower_type: "樓型種類",
        x_design_scheme_tower_height_restriction: "建築限高（海拔米）",
        x_design_scheme_tower_no: "建築數",
        x_design_scheme_flat_size: "平均住宅面積（平方米）",
        x_design_scheme_flat_no: "每層住宅數",
        x_design_scheme_footprint: "層建築面積(平方米)",
        x_design_scheme_build_on: "建築位置",
        x_design_scheme_build_form: "建築形狀",
        x_design_scheme_site: "地塊地面",
        x_design_scheme_podium_residual: "額外平台層",
        x_design_scheme_dom_block_type: "住宅樓類",
        x_design_scheme_nondom_block_type: "非住宅樓類",
        x_design_scheme_geom_block_type: "基本形狀類",
        x_design_select_footprint: "選擇建築形狀：",

        x_design_scheme_avail_towers: "剩余建築物",
        x_design_scheme_typ_floor: "普通樓層數",
        x_design_scheme_ref_floor_count: "防火樓層數",
        x_design_scheme_lobby_floor_count: "大堂樓層數",
        x_design_scheme_total_floor_count: "總樓層數",
        x_design_scheme_flat_no_tower: "每棟樓單位數",
        x_design_scheme_tower_height: "樓高",
        x_design_scheme_tower_mpd: "海拔高",
        x_design_scheme_floor_no_pod: "樓層數",
        x_design_scheme_footprint_pod: "平台層建築面積",
        x_design_scheme_gfa_pod: "總建築面積",
        x_design_scheme_do_name: "方案名稱:",
        x_project_design_scheme: "設計方案繪製",
        x_save_as: "另存為",
        x_previous: "上一步",

        // IoT and IoT template related (device/chart management)
        x_add_chart: "新建圖表",
        x_chart: "圖表",
        x_device: "設備",
        x_form: "表格",
        x_table: "表格",
        x_monitor: "監視器",
        x_device_info: "設備信息",
        x_alert_level: "預警級別",
        x_device_name: "設備名稱",
        x_generic: "通用",
        x_chart_type: "圖表類型",
        x_chart_line: "折線圖",
        x_chart_bar: "柱狀圖",
        x_chart_pie: "餅狀圖",
        x_chart_gauge: "儀錶盤",
        x_reading_column: "讀數字段",
        x_time_column: "時間字段",
        x_data_column: "數據字段",
        x_series_column: "系列字段",
        x_time_type: "顯示時間範圍",
        x_time_range: "指定日期範圍",
        x_time_last: "近期",
        x_prev_days: "最近天數",
        x_chart_line_color: "圖表顏色",
        x_max_val: "最大值",
        x_min_val: "最小值",
        x_set_unit: "設置單位",
        x_set_fontsize: "設置字體大小",
        x_real_time_img: "實時影像",
        x_is_iot_link_layer: "與IoT圖層綁定",
        x_link_layer: "綁定圖層",
        x_metadata: "元數據",
        x_attr: "屬性",
        x_rec: "記錄",
        x_warn_rule: "預警規則",
        x_alert_source: "預警來源",
        x_alert_alias: "預警別名",
        x_alert_value: "預警數值",
        x_alert_log: "預警日志",
        x_base_info: "基本信息",
        x_flie_info: "文件信息",
        x_correct_longitude: "請輸入正確的經度",
        x_correct_latitude: "請輸入正確的緯度",
        x_update_time: "更新時間",
        x_update_frequency: "更新頻率",
        x_update_frequency_tip:
            "在此設定更新頻率時，會將該類別下所有設備的更新頻率批量設定",

        x_text: "文本",
        x_num: "數字",
        x_attachment: "附件",
        x_time: "時間",
        x_int: "整數",
        x_bigint: "大整數",
        x_float: "浮點數",

        x_col_name: "字段物理名",
        x_col_alias: "字段顯示名",
        x_col_type: "字段類型",
        x_value: "值",

        x_col_condition: "報警條件",
        x_col_value: "報警值",
        x_col_level: "報警等級",
        x_col_memo: "備注",

        x_col_max_val: "最大值",
        x_col_min_val: "最小值",

        x_visible_all: "所有人可見",
        x_admin_check_user_count: "當前系統在線人數",
        x_company_check_user_count: "當前公司在線人數",

        x_download_model: "下載模板",
        x_pilot_management: "試點管理",
        x_operation_panel: "操作面板",
        x_comfirm_delete: "是否確認刪除該字段",
        x_my_file: "我的文件",
        x_all_file: "全部文件",
        x_file_detail: "文件詳情",
        x_folder_content: "文件夾內容",
        x_storage_info_tip: "選擇文件，查看詳情",
        x_storage_info_tip2: "此文件夾為空",
        x_go_back: "返回上一級",
        x_move_to: "移動到",
        x_folder: "文件夾",
        x_unzip: "解壓",
        x_unzip_to: "解壓到",
        x_copy_to: "複製到",
        x_move_tip: "不能將文件移動到自身或其子目錄下",
        x_copy_tip: "不能將文件複製到自身或其子目錄下",
        x_upload_list: "文件上傳列錶",
        x_upload_list_tip: "僅展示本次上傳任務",

        x_my_resources: "我的資源",
        x_my_participation: "我參與的",
        x_view_type: "視圖類型",
        x_creator: "創建人",
        x_date_range: "日期範圍",
        x_create_date_range: "創建資料日期範圍",
        x_capture_date_range: "採集數據日期範圍",

        x_reverse: "顛倒",
        x_detail: "詳情",
        x_detail_view: "查看詳情",
        x_visibility: "權限",
        x_add_component: "添加組件",
        x_content_properties: "内容屬性",
        x_text_number: "文字(數字)",
        x_text_color: "文字顔色",
        x_target_url: "目標URL",
        x_document: "文檔",
        x_advance_setting: "高級設置",
        x_chart_attribute_editor: "圖表屬性編輯器",
        x_border: "邊框",
        x_border_style: "邊框樣式",
        x_border_thickness: "邊框粗細",
        x_border_color: "邊框顔色",
        x_main_color: "主色",
        x_sub_color: "輔色",
        x_border_background: "邊框與背景",
        x_background_picture: "背景圖片",
        x_background_color: "背景顔色",
        x_background_gaussian_blur: "背景高斯模糊",
        x_confirm_add: "確認添加",
        x_text_size: "文字大小",
        x_video_stream_properties: "視頻流屬性",
        x_video_stream_preview_properties: "視頻流預覽屬性",
        x_document_select_message: "請至少選擇一個文檔",

        x_tags_add_message: "標籤名稱不能為空",

        x_ungroup_document_select_message: "請從未分組區至少選擇一個文檔",
        x_document_alias: "文檔別名",
        x_picture: "圖片",
        x_cancel_option: "取消選項",
        x_my_star: "我的收藏",
        x_star: "收藏",
        x_cancel_star: "取消收藏",
        x_login_site_title: "登錄 GeoTwin 平台",
        x_pwd_reset: "密碼重置",
        x_login_with: "登入管道",
        x_forget_pwd_tip:
            "如果您忘記了密碼，請在下麵輸入您帳戶的電子郵寄地址，然後按一下“提交”按鈕。您將收到一封包含新密碼的電子郵件。",
        x_forget_pwd_tip2: "密碼重置，請檢查您的電子郵件以獲取新密碼。",
        x_sign_up_title: "註冊 GeoTwin 平台",
        x_back_to_login: "返回登入界面",
        x_send_verification_code: "發送驗證碼",
        x_submit_verification_code: "提交驗證碼",
        x_verification_code: "驗證碼",
        x_send_again: "重新發送",
        x_send_verification_code_tip: "驗證碼已經發送至您的郵箱！",
        x_invite_user: "邀請用戶",
        x_link_invitation: "連結邀請",
        x_email_invitation: "郵件邀請",
        x_click_copy: "點擊複製",
        x_invite_user_input_tip:
            "請選擇用於接收邀請的用戶 (用戶名或郵箱均可蒐索)",
        x_invite_email_input_tip:
            "請選擇用於接收邀請的郵箱 (用戶名或郵箱均可蒐索)",
        x_email: "郵箱",
        x_send_invitation: "發送邀請",
        x_copy_link: "複製連結",
        x_copy_link_tip: "複製連結成功",
        x_comfirm_remove: "確認是否將 {0} {1} 移出本公司？",
        x_generate_link: "生成連結",
        x_validate_tip: "請先選擇角色",
        x_public_resource: "公開資源",
        x_dashboard: "儀錶盤",
        x_create_new_template: "創建新模板",
        x_projects: "項目",
        x_projects_overview: "項目概覽",
        x_settings: "設定",
        x_load_tip: "已加載全部，共 {total} 個",
        x_overview: "資產概覽",
        x_members: "成員",
        x_company: "公司",
        x_online_help: "線上說明",
        x_feedback: "迴響",
        x_log_out: "註銷",
        x_faq: "常見問題",
        x_account: "個人帳戶",
        x_admin: "系統管理",
        x_space_domain: "空間域名",
        x_space_capacity: "空間容量",
        x_workbench: "工作臺",
        x_company_members: "公司成員",
        x_freq: "次",
        x_delete_confirm: "確認删除所選的檔案麼？",
        x_month: "月份",
        x_add_resource: "添加資源",
        x_add_layer: "添加圖層",
        x_create_layer: "創建新圖層",
        x_lock: "鎖定",
        x_chart_tip: "日期範圍不可以是同一天",
        x_custom: "自定義",
        x_event: "事件",
        x_daily_visit: "每日訪問",
        x_workspace_visit: "工作空間訪問",
        x_real_visit: "實名訪問",
        x_anon_visit: "匿名訪問",
        x_process: "處理",
        x_defect: "缺陷",
        x_defect_type: "缺陷類型",
        x_refresh: "重繪",
        x_save_preview: "保存封面",
        x_by_type: "按類型",
        x_by_user: "按用戶",
        x_by_time: "按時間",
        x_downloading: "下載中...",
        x_downloaded: "已下載",
        x_save_preview_success: "保存封面成功",
        x_project_document: "項目檔案",
        x_length_q_s: "長度",
        x_perimeter: "周長",
        x_area_area: "區域面積",
        x_area_sqm_dot: "m²",
        x_angle_angle: "角的角度",
        x_angle_angle_angle: "°",
        x_domain_name: "功能變數名稱",
        x_company_name: "公司名稱",
        x_domain_name_empty_err: "功能變數名稱不能為空",
        x_domain_name_invlidate: "功能變數名稱所含字元不正確",
        x_retrieve_info_feature: "檢索地圖要素的詳細資訊",
        x_start_ident: "開始點選",
        x_start_ident_point: "按點",
        x_start_ident_line: "按線",
        x_start_ident_polygon: "按多邊形",
        x_start_ident_circle: "按圓形",
        x_cancel_ident: "取消點選",
        x_danger_zone: "危險操作",
        x_remove_project: "删除項目",
        x_remove_project_tip: "删除項目將會連同其相關的所有數據一起删除。",
        x_remove_project_tip2: "此操作無法恢復！",
        x_remove_project_verify_title: "敏感操作驗證",
        x_remove_project_tip3: "你正在删除項目",
        x_project_name_verify_err: "項目名稱驗證錯誤",
        x_project_name: "項目名稱",
        x_workspace_name: "工作空間名稱",
        x_enter_project_name_verify: "請輸入項目名稱驗證",
        x_assets_category: "資產類別",
        x_add_category: "新增資產",
        x_add_device: "新增設備",
        x_cctv: "視頻監控",
        x_worker: "人員",
        x_gender: "性別",
        x_vehicle: "車輛",
        x_personnel: "人員",
        x_car_model: "型號",
        x_car_plate: "牌照",
        x_upload_batch_device: "批量上傳設備",
        x_method: "管道",
        x_step: "步驟",
        x_setup_category: "設定類別",
        x_category_name: "類別名稱",
        x_record: "記錄",
        x_record_attr: "記錄屬性",
        x_record_Info: "記錄信息",
        x_upload_draft: "上傳圖紙",
        x_draft_manage: "圖紙管理",
        x_draft_info: "圖紙資訊",
        x_login_title: "登入標題",
        x_login_background: "登入背景",
        x_validate_invlidate: "內容格式不正確",
        x_days: "天",
        x_validity: "有效期",
        x_validity_tip: "誒呀，來晚了，該分享連結已失效！",
        x_pwd_label: "請輸入工作空間密碼",
        x_report: "報告",
        x_report_name: "報告名稱",
        x_company_private: "僅本公司可見",
        x_company_power_tip: "抱歉，無許可權訪問該連結！",
        x_draw_entity: "繪製元素",
        x_add_to_resource: "添加至資源",
        x_upload_icon: "上傳圖標",
        x_geojson: "Geojson",
        x_ifc: "IFC",
        x_add_to_workspace: "添加至工作空間",
        x_device_in_Workspace: "設備所屬工作空間",
        x_dashboard_logo: "大屏標誌",
        x_dashboard_theme: "大屏主題",
        x_add_to_project: "添加至項目",
        x_create_new_dashboard: "創建大屏",
        x_template_management: "模板管理",
        x_empty_template: "空白模板",
        x_edit_dashboard: "配寘大屏",
        x_change_hub: "切換公司",
        x_function: "功能",
        x_osgb: "OSGB",
        x_code_tip: "掃碼關注 Geosys 公眾號",
        x_project_preview: "項目封面",
        x_location: "位置",
        x_icon_type: "圖標類型",
        x_monitoring_management: "監控管理",
        x_monitoring_wall: "監控牆",
        x_add_monitoring_wall: "新增監控牆",
        x_map: "地圖",
        x_add_to_monitoring_wall: "添加至監控牆",
        x_cctv_add: "添加",
        x_cctv_remove: "移除",
        x_return_to_list: "返回列表",
        x_richtext_tip: "點擊編輯文字",
        x_project_menu: "項目選單",
        x_minimize: "最小化",
        x_restore: "還原",
        x_device_detail: "設備詳情",
        x_alert_detail: "警告詳情",
        x_comparison: "比較",
        x_add_marker: "新增標識",
        x_add_Measurement: "新增量測",
        x_marker_name: "標識名稱",
        x_initial: "初始資訊",
        x_initial_date: "初始日期",
        x_initial_reading: "初始讀數",
        x_measure_date: "量測日期",
        x_level: "高度",
        x_diff_initial: "初始值差异",
        x_diff_previous: "上一個差异",
        x_last_reading_date: "最新讀取日期",
        x_last_reading: "最新讀取",
        x_last_diff_initial: "最新初始值差异",
        x_alarm: "警告",
        x_team_name: "團隊名稱",
        x_team_members: "團隊成員",
        x_team_menu: "團隊選單",
        x_add_team_member: "添加團隊成員",
        x_username_input: "用戶",
        x_iot_category: "物聯監控類別",
        x_cctv_category: "CCTV 類別",
        x_worker_category: "工人類別",
        x_vehicle_category: "車輛類別",
        x_generic_alert: "物聯監控預警",
        x_personnel_alert: "工人預警",
        x_vehicle_alert: "車輛預警",
        x_cctv_alert: "CCTV預警",
        x_generic_list: "物聯監控列表",
        x_personnel_list: "工人列表",
        x_vehicle_list: "車輛列表",
        x_cctv_list: "CCTV列表",
        x_view_monitoring: "查看監控",
        x_iot_management: "物聯監控管理",
        x_cctv_management: "視頻監控管理",
        x_worker_management: "工人管理",
        x_vehicle_management: "車輛管理",
        x_datepicker: "日期選擇器",
        x_button: "按鈕",
        x_dropdown: "下拉列表",
        x_query_result: "查詢結果",
        x_lower_limit: "下限值",
        x_upper_limit: "上限值",
        x_subscribe: "訂閱",
        x_unsubscribe: "取消訂閱",
        x_task_name: "任務名稱",
        x_model: "模型",
        x_model_status: "模型狀態",
        x_run: "執行",
        x_subscribe_attribute: "訂閱内容",
        x_upper_clothes: "上裝",
        x_lower_clothes: "下裝",
        x_upper_clothes_color: "上裝顏色",
        x_lower_clothes_color: "下裝顏色",
        x_hat: "帽子",
        x_vest: "背心",
        x_human_detection: "人體檢測",
        x_vehicle_detection: "車輛檢測",
        x_cross_region_detection: "跨區域檢測",
        x_step_size: "步長",
        x_zone_name: "區域名稱",
        x_zone_detail: "區域詳情",
        x_zone: "區域",
        x_upload_zone: "上傳區域",
        x_overview_zone_management: "區域管理",
        x_zone_list: "區域清單",
        x_zone_category: "區域類別",
        x_ai_event: "事件",
        x_alert_center: "預警中心",
        x_push_system: "推送系統",
        x_push_email: "推送郵件",
        x_push_whatsapp: "推送應用",
        x_alert_col: "預警列",
        x_push_staffing: "推送人員配寘",
        x_bool: "布林值",
        x_bool_value: "布林值",
        x_rule_name: "規則名稱",
        x_rule: "規則",
        x_add_member: "添加成員",
        x_forbidden_zone: "禁區",
        x_label: "標籤",
        x_decorate: "裝潢",
        x_decorate_type: "裝潢類型",
        x_select_picture: "選擇圖片",
        x_select_color: "選擇顏色",
        x_border_radius: "邊框圓角",
        x_label_text: "標籤文字",
        x_alignment: "對齊管道",
        x_align_left: "左對齊",
        x_align_right: "右對齊",
        x_align_center: "居中對齊",
        x_font_weight: "字體粗細",
        x_background_size: "背景圖大小",
        x_picture_source: "圖片來源",
        x_pic_upload: "圖片上傳",
        x_net_address: "網路位址",
        x_interface_data: "接口數據",
        x_interface_address: "接口地址",
        x_interface_type: "請求方式",
        x_interface_calling_error: "請檢查調用參數或聯係管理員",
        x_response_data: "響應數據",
        x_pie_chart_setting: "餅圖設定",
        x_value_setting: "數值設定",
        x_set_ring_type: "設定為環型",
        x_set_nightingale_rose: "南丁格爾玫瑰",
        x_auto_sort: "自動排序",
        x_not_show_zero: "不展示零",
        x_show_value: "顯示數值",
        x_border_width: "邊框寬度",
        x_real_time: "實时時間",
        x_time_format: "時間格式",
        x_time_format1: "日期",
        x_time_format2: "日期+時分",
        x_time_format3: "日期+時分秒",
        x_time_format4: "日期(無年)",
        x_time_format5: "時分",
        x_time_format6: "時分秒",
        x_time_format7: "星期",
        x_custom_format: "自定義格式",
        x_font_spacing: "字體間距",
        x_show_widget_windowstate: "顯示組件視窗狀態",
        x_widget_name: "組件名稱",
        x_weather: "天氣",
        x_current_weather: "當前天氣",
        x_weather_forecast: "天氣預報",
        x_display_type: "展示管道",
        x_card: "卡片",
        x_show_weather_warning: "顯示天氣預警",
        x_show_days: "顯示天數",
        x_chart_title: "圖表標題",
        x_chart_title_color: "圖表標題顏色",
        x_inner_ring_size: "內環大小",
        x_outer_ring_size: "外環大小",
        x_show_legend: "顯示圖例",
        x_label_position: "標籤位置",
        x_device_type: "設備類型",
        x_generic_iot: "通用IOT",
        x_date_type: "日期類型",
        x_user_group: "用戶組",
        x_editable: "可編輯",
        x_in_dashboard: "在大屏中",
        x_in_album: "在相册中",
        x_add_to_dashboard: "添加至大屏",
        x_add_to_album: "添加至相册",
        x_no_exceedence: "暫無超出",
        x_geotechnical_instrument: "岩土工程儀器",
        x_geotechnical_instrument_list: "岩土工程儀器清單",
        x_offline: "離線",
        x_online: "線上",
        x_chatroom: "聊天室",
        x_chatroom_name: "聊天室名稱",
        x_save_and_enter_chatroom: "保存並進入聊天室",
        x_no_chatroom: "暫無聊天室",
        x_online_people_number: "線上人數",
        x_control: "控制",
        x_point_cloud: "點雲",
        x_rendering: "渲染",
        x_chat_enter: "進入聊天室",
        x_chat_leave: "離開聊天室",
        x_currently_online: "當前線上（ {total} 人 ）",
        x_define_animation_track: "定義動畫軌跡",
        x_advanced_search: "高級搜索",

        x_theme_colors: "主題顏色",

        x_theme_orange: "橘色",

        x_location_search: "位置搜索",
        x_search_placeholder_location:
            "建築物, 地址, 地點, 地段(e.g.DD 381 Lot 953)",
        Lot: "地段",
        Address: "地址",
        Building: "建築物",
        Places: "地點",
        AI_APP_UPLOAD: "圖片上傳",
        AI_APP_AI_PROCESS: "執行AI",
        New_AI_Processing: "新的人工智能處理",
        AI_APP_AI_RESULT: "AI 結果",
        AI_APP_AI_REPORT: "AI 報告",
        AI_APP_TASK: "AI 任務",
        AI_APP_ACCEPT: "接受",
        AI_APP_TASK_STATUS_ALL: "全部",
        AI_APP_TASK_STATUS_PICTURE: "正在處理圖片",
        AI_APP_TASK_STATUS_DATA: "正在處理數據",
        AI_APP_TASK_STATUS_COMPLETE: "完成",
        AI_APP_TASK_CODE: "任務編碼",
        AI_APP_TASK_MODEL: "模型",
        AI_APP_ADD_TAGS: "添加標籤",
        AI_APP_GENERATE_REPORT: "生成報告",
        x_repair: "維修",
        x_pending: "待定",
        x_normal: "正常",
        x_ignore: "忽視",
        x_is_the_defect: "確是缺陷",
        x_not_sure: "不確定",
        x_not_the_defect: "不是缺陷",
        x_not_confirmed: "待確認",
        x_defect_action: "缺陷行動",
        x_emergency_repair: "緊急維修",
        x_accept: "接受",
        x_solar_illumination: "太陽光照",
        x_ambient_occlusion: "環境光遮蔽",
        x_3d_model_layers: "圖層列表",
        x_Basic_Information: "基本信息",
        x_BIM_Statistics: "BIM 統計",
        x_BIM_Model: "BIM 模型",
        x_menu_notice: "消息",
        x_mark_as_readed: "標記為已讀",
        x_mark_all_messages_as_readed: "標記全部消息為已讀",
        x_view_all: "查看全部",
        x_extract_password: "選取密碼",
        x_have_extract_password: "有選取密碼",
        x_share_files: "分享檔案",
        x_link_sharing: "連結分享",
        x_automatically_generates_extracts_passwords: "系統自動生成密碼",
        x_please_enter_the_extraction_password: "請輸入選取密碼",
        x_create_new_link: "創建連結",
        x_successfully_created_private_link: "成功創建私密連結",
        x_no_extraction_password_set: "未設定選取密碼",
        x_link: "鏈接",
        x_permanent: "永久",
        x_effective: "有效",
        x_day: "天",
        x_lose_efficacy: "失效",
        x_iot: "物聯監控",
        x_device_list: "設備清單",
        x_alert_list: "警告清單",
        x_files_folders_selected: " 個檔案/資料夾被選中",
        x_register: "注册",
        x_late_arrival_the_file_sharing_has_expired: "來晚了，該檔案分享已過期",
        x_after_closing_extraction_code_for_sharing_links: "關閉後分享連結無需填充提取碼",
        x_users_click_open_the_shared: "用戶點擊連結直接打開分享內容",
        x_project_visible: "本項目可見",
        x_company_visible: "本公司可見",
        x_all_visible: "全網可見",
        x_yes: "是",
        x_no: "否",
        x_4d_configure: "4D 配寘",
        x_add_4d_configure: "新增 4D 配寘",
        x_schema_name: "方案名稱",
        x_field_name: "欄位名稱",
        x_execute_odm: "執行 3D 重建",
        x_execute_splat: "執行貼圖",
        x_odm_task: "3D 重建任務",
        x_splat_task: "貼圖任務",
        x_quality: "質量",
        x_fail: "失敗",
        x_comfirm_delete2: "確認删除 {0} 嗎？",
        x_download_sample_file: "下載示例檔案",
        x_feature_name: "功能名稱",
        x_project_user_tips: "沒有數據",
        x_model_info: "模型資訊",
        x_scale: "比例尺",
        x_pitch: "俯仰",
        x_roll: "滾轉",
        x_model_quality: "建模質量",
        x_setting_no_flatten: "不允许压平",
        x_location_Type: "位置類型",
        x_by_coordinates: "按座標",
        x_by_address: "按地址",
        x_x_coordinate_field: "X座標欄位",
        x_y_coordinate_field: "Y座標欄位",
        x_address_field: "地址欄位",
        x_new_add: "新增",
        x_user_management: "用戶管理",
        x_menu_management: "菜單管理",
        x_role_management: "角色管理",
        x_department_management: "部門管理",
        x_department_name: "部門名稱",
        x_sort: "排序",
        x_menu_name: "菜單名稱",
        x_permission: "權限標識",
        x_grant: "授權",
        x_role_code: "角色標識",
        x_root_department: "根部門",
        x_department: "部門",
        x_root_menu: "根菜單",
        x_menu: "菜單",
        x_parent_menu: "上級菜單",
        x_menu_cannot_delete: "菜單包含下級不能刪除",
        x_dept_cannot_delete: "部門包含下級不能刪除",
        x_role_describe: "角色描述",
        x_features: "功能",
        x_capabilities: "能力",
        x_cases: "案例",
        x_dont_have_account: "还沒有帳戶？",
        x_already_member: "已有帳戶？",
        x_signup_success: "註冊成功",
        x_signup_success_tip: "如您所在的公司已有 Hub，請聯係公司人員給您開通相應的權限，即可登錄",
        x_create_hub_tip: "如您未有 Hub，請點擊下方鏈接創建，謝謝",
        x_log_management: "日誌管理",
        x_you_can_enter_below_hub: "您可以進入以下 Hub",
        x_submit_success: "提交成功",
        x_create_hub_introduce: "您尚未有公司帳戶，請點擊輸入以下資料並確認提交；或點擊右下角聯絡我們。我們會及時與你聯絡。謝謝!",
        x_create_hub_introduce_cn: "目前發現您沒有一個公司賬戶，請添加我司企業微信號，聯係我司業務人員，謝謝",
        x_create_hub_success_tip: "我們會盡快與您聯絡，謝謝",
        x_return_to_signin: "返回登入",
        x_operation: "操作",
        x_batch_operation: "批量操作",
        x_log_method: "方法",
        x_remove_user_tip: "是否確認刪除此用戶？",
        x_project_time: "項目時間",
        x_enter: "請輸入",
        x_project_list: "項目列表",
        x_file_count: "文件數量",
        x_file_type: "文件類型",
        x_publish_new_resource: "發佈新資源",
        x_total_resource: "資源總數",
        x_compute_power_use: "算力消耗",
        x_total_visits: "訪問總次",
        x_total_storage: "總存儲量",
        x_resource_type: "資源類型",
        x_from_small_to_large: "由小至大",
        x_from_large_to_small: "由大至小",
        x_invite_register: "邀請註冊",
        x_digital_twins: "數字孿生",
        x_auth_intro: "科技塑造未來，智能創造精彩",
        x_publish_type: "發佈類型",
        x_basic_options: "基礎選項",
        x_product: "產品",
        x_base_layer_setting: "底圖設定",
        x_search_result: "搜索結果",
        x_descending: "由大至小",
        x_ascending: "由小至大",
        x_comfirm_delete_resource: "確認删除資源 {0} 嗎？",
        x_template: "模闆",
        x_template_smart: "智慧工地",
        x_template_common: "通用",
        x_template_ai: "AI",
        x_fold_menu: "折疊菜單",
        x_template_default: "默認模板",
        x_template_panorama: "全景場景",
        x_template_gis: "GIS 場景",
        x_template_bim: "BIM 場景",
        x_using_wechat_login: "使用微信登入",
        x_share_link_validity_tip: "分享連結有效日期不能為空",
        x_data_directory: "數據目錄",
        x_path_select: "路徑選擇",
        x_template_name: "模板名稱",
        x_wechat: "微信",
        x_already_bound: "已綁定",
        x_unbind: "解綁",
        x_bind_wechat: "綁定微信",
        x_avatar: "頭像",
        x_app_title: "GeoTwin空間孿生計算平台",
        x_showOverview: "是否顯示在概覽",
        x_in_progress: "進行中",
        x_asset_mapview: "資產地圖",
        HONGKONG: "中国香港",
        TAIWAN: "中国台湾",
        CHINA: "中国大陆",
        x_password_rule_1: "必須包含8-20個字符",
        x_password_rule_2: "必須同時包含字母、數字、特殊符號",
        x_password_rule_3: "不能過於常見",
        x_solution: "解決方案",
        x_about_us: "關於我們",
        x_select: "請選擇",
        x_save_perspective: "保存視角",
        x_select_position: "選擇位置",
        x_reselect_position: "重新選擇位置",
        x_editable_source_publish: "可編輯圖層發佈",
        x_blank_layer: "空白圖層",
        x_feature_type: "要素類型",
        x_point: "點",
        x_line: "線",
        x_polygon: "多邊形",
        x_field_name_format_err: "只能使用小寫字母、數字和下劃綫構成蛇形命名(數字不能在開頭)",
        x_wechat_login_err_unbind: "您未綁定微信，請先登入或註冊後自動為您綁定",
        x_set_attribute: "設定屬性",
        x_upload_info: "已存在相同文件，請選擇覆蓋還是創建副本",
        x_cover: "覆蓋",
        x_create_copy: "創建副本",
        x_signup_pwd_error: "密碼不包含空格，並且長度至少為8個字符",
    },
};
